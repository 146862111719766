import React from 'react';
import { GOOGLE_TAG_URL } from 'constants/external-links';
import googleTag from '../images/google-tag.svg';
import Analytics from '../components/Analytics';

export default function GtagManager() {
  const gtagManagerProps = {
    altKey: 'ob.gtag.manager.title',
    FIELD_PLACEHOLDER: 'UA-XXXXX-Y',
    imgSrc: googleTag,
    description1: 'ob.gtag.manager.description.1',
    description2: 'ob.gtag.manager.description.2',
    fieldName: 'gtagTrackerId',
    fieldLabel: 'ob.analytics.google.trackingId',
    fieldTooltip: 'ob.analytics.google.tooltip',
    href: GOOGLE_TAG_URL,
    mobile1: 'ob.analytics.google.account.mobile.1',
    mobile2: 'ob.analytics.google.account.mobile.2',
    mobile3: 'ob.analytics.google.account.mobile.3',
    mobile4: 'ob.analytics.google.account.mobile.4',
  };

  return <Analytics {...gtagManagerProps} />;
}
