import React from 'react';
import { withModal } from '@plandok/core';
import { GOOGLE_TAG_URL } from 'constants/external-links';
import googleTag from '../images/google-tag.svg';
import { AnalyticsModalProps } from '../types/types';
import AnalyticsModal from './AnalyticsModal';

function GoogleTagModal({ modalProps, onClose }: AnalyticsModalProps) {
  const googleTagModalProps = {
    altKey: 'ob.gtag.manager.title',
    FIELD_PLACEHOLDER: '39029385',
    imgSrc: googleTag,
    description1: 'ob.gtag.manager.description.1',
    description2: 'ob.gtag.manager.description.2',
    onClose,
    modalProps,
    fieldName: 'gtagTrackerId',
    fieldLabel: 'ob.analytics.google.trackingId',
    fieldTooltip: 'ob.analytics.google.tooltip',
    accountLabel: 'ob.gtag.manager.account',
    href: GOOGLE_TAG_URL,
  };

  return <AnalyticsModal {...googleTagModalProps} />;
}

export default withModal('ob.gtag.manager.title', { contentClassName: 'online-booking-analytics-modal' })(
  GoogleTagModal
);
