import styled, { css } from 'styled-components';
import { Radio as AntRadio } from 'antd';
import { mediaMdDown, mediaMobileDown, Text } from '@plandok/core';

export const Card = styled.div`
  display: flex;
  flex-direction: column;

  background: white;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 0.16);
  margin-bottom: 30px;

  .ant-form-explain,
  .ant-form-extra {
    min-height: 11px;
  }

  ${mediaMobileDown(css`
    background: #f8f9fc;
    box-shadow: none;
    padding: 0;
  `)}
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    min-width: 180px;
  }

  ${mediaMobileDown(css`
    button {
      min-width: 130px;
    }
  `)}
`;

export const RadioTitle = styled(Text)`
  ${mediaMobileDown(css`
    text-transform: uppercase;
  `)}
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 40px;
  color: ${({ theme }) => theme.primaryColor};
  h2 {
    color: ${({ theme }) => theme.primaryColor};
  }
  p {
    color: rgba(10, 28, 67, 0.5);
  }
  form {
    width: 100%;
    max-width: 600px;
  }

  .notification-emails {
    div:nth-child(2) .ant-row div:first-child.ant-form-item-label {
      display: none;
    }
    div:last-child .ant-row {
      margin-top: 1.5rem;
    }
  }

  ${mediaMobileDown(css`
    margin-top: 20px;
    padding: 0 15px 0 15px;

    h2 {
      font-weight: bold;
      font-size: 16px;
    }
    p {
      font-size: 14px;
    }
  `)}
`;

export const FormInfoContainer = styled.div`
  margin-bottom: 16px;
`;

export const WarningWrapper = styled.div`
  border: 1px solid #fda29b;
  background: #fffbfa;
  margin-bottom: 16px;
  border-radius: 12px;
  display: flex;
  padding: 16px 12px;
  justify-content: space-between;

  svg {
    width: 20px;
    height: 20px;
    transform: rotate(180deg);
    path {
      fill: #d92d20;
    }
  }

  div {
    div {
      line-height: 20px;
      color: #b42318;
      :first-child {
        margin-bottom: 4px;
      }
    }
  }

  button {
    svg {
      width: 10px;
      height: 10px;
      path {
        stroke-width: 4;
        stroke: #f04438;
      }
    }
  }
`;

export const RadioRow = styled.div`
  display: grid;
  grid-template-columns: 8rem repeat(2, 1fr);
  align-items: center;
  gap: 30px;

  .ant-form-item-label {
    display: none;
  }

  .ant-form-item-control {
    display: flex;
  }

  .ant-radio-group {
    display: flex;
    gap: 10px;
  }

  .ant-radio-inner {
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 2px solid #13316d;
  }

  .ant-radio-inner::after {
    top: 3px;
    left: 3px;
    width: 10px;
    height: 10px;
  }

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: none;
    &:before {
      content: '';
      position: absolute;
      background-color: #13316d;
      border-radius: 50%;
    }
  }

  .ant-radio-wrapper {
    display: flex;
    align-items: center;
    line-height: 0;
  }

  :not(:last-of-type) {
    margin-bottom: 35px;
  }

  ${mediaMobileDown(css`
    grid-template-columns: 1fr;
    justify-items: start;
    gap: 5px;
  `)};
`;

export const Radio = styled(AntRadio)`
  margin-left: 1.75rem;

  .ant-radio-inner {
    width: 20px;
    height: 20px;
    background-color: #fff;
    margin-right: 5px;
    border: 2px solid #13316d;
  }
`;

export const NotesPlaceholderWrapper = styled.div`
  margin-top: -19px;

  ${mediaMdDown(css`
    margin-top: -10px;
  `)}
`;
