import styled, { css } from 'styled-components';
import { Card, Button, mediaSmDown } from '@plandok/core';

export const Container = styled.div`
  margin: 40px auto 30px;
  max-width: 700px;
  text-align: center;
`;

export const OnBoardContainer = styled.div`
  margin-top: 24px;
`;

export const PaySiteCard = styled(Card)`
  display: flex;
  display: -webkit-box;
  border: none;
  box-shadow: 4px 5px 11px rgba(38, 60, 122, 0.2);
  border-radius: 10px;
  padding: 32px 26px;
  margin-bottom: 38px;
  width: 100%;

  ${mediaSmDown(css`
    max-width: calc(100vw - 32px);
    margin: 0 auto 24px;
    border-radius: 20px;
    padding: 24px;
    svg {
      height: 48px;
      width: 48px;
    }
  `)}
`;

export const PayCardCard = styled(Card)`
  border: none;
  box-shadow: 4px 5px 11px rgba(38, 60, 122, 0.2);
  border-radius: 10px;
  padding: 34px 49px 32px 26px;
  text-align: start;
  margin-bottom: 38px;
  width: 100%;

  ${mediaSmDown(css`
    border-radius: 20px;
    max-width: calc(100vw - 32px);
    margin: 0 auto;
    padding: 24px 24px 33px;
  `)}
`;

export const ContainerDraggable = styled.div`
  display: flex;
`;

export const PaySiteInfo = styled.div`
  margin-left: 33px;
  text-align: start;

  ${mediaSmDown(css`
    margin-left: 24px;
    max-width: 219px;
  `)}
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;

  .ant-switch.ant-switch-checked {
    background: #009329;
  }

  ${mediaSmDown(css`
    flex-direction: ${({ theme }) => theme.payCard && 'column'};
  `)}
`;

export const PayCardContainer = styled.div`
  display: flex;
  display: -webkit-box;

  ${mediaSmDown(css`
    svg {
      height: 42px;
      width: 46px;
    }
  `)}
`;

export const PayCardWrapper = styled.div`
  margin-left: 27px;

  ${mediaSmDown(css`
    margin-left: 24px;
    max-width: 219px;
  `)}
`;

export const Commission = styled.div`
  background: #e0f3fd;
  border: 1px solid #95d2f2;
  border-radius: 5px;
  padding: 12px 16px;
  margin-bottom: 24px;
  max-width: 430px;

  a {
    font-weight: 500;
    text-decoration: underline;
    color: #13316d;
  }

  ${mediaSmDown(css`
    padding: 12px 1px 12px 16px;
  `)}
`;

export const Link = styled.span`
  color: #136ef6;
  cursor: pointer;
  font-weight: 500;
`;

export const ConnectButton = styled(Button)`
  padding: 10px 24px;
  background: #136ef6 !important;
  border: none;
  margin-top: 24px;
  display: block;
  max-width: max-content;

  ${mediaSmDown(css`
    margin: 24px auto 0;
  `)}
`;

export const WaitingAuthorization = styled.div`
  display: flex;
  padding: 48px 0 52px;
  div:last-child {
    margin-left: 13px;
  }

  ${mediaSmDown(css`
    flex-direction: column;
    text-align: center;
    max-width: 219px;
    margin: 0 auto;
    padding: 16px 0 8px;
    div:last-child {
      margin-left: 0;
    }
  `)}
`;

export const StripeDropdownContainer = styled.div`
  margin-bottom: 16px;
  max-width: 350px;

  .ant-select-selection.ant-select-selection:focus {
    border-color: #136ef6;
  }

  ${mediaSmDown(css`
    max-width: 295px;
  `)}
`;

export const StripeDashboardContainer = styled.div`
  max-width: 376px;

  a {
    color: inherit;
    text-decoration: underline;
  }
`;
