/* eslint-disable */
/*
  Generated file from translation.csv
  to update it - make change in google sheet and run 'yarn generate:file:labels' see more docs in readme.md
*/

const labels = {
  'login.title': 'Hesabınıza giriş yapın',
  'label.email': 'E-posta adresi',
  'placeholder.email': 'örn. alialkis@gmail.com',
  'input.password.label': 'Şifre',
  'input.password.placeholder': 'Şifrenizi girin',
  'login.btn': "Plandok'a giriş yapın",
  'link.sign.in': 'Giriş',
  'label.password': 'Şifrenizi girin',
  'login.forgot.password': 'Şifremi unuttum?',
  'login.no.account': 'Hesabım yok?',
  'login.description': 'Hesabınıza giriş sorunu yaşıyorsanız, şifrenizi sıfırlayabilirsiniz',
  'register.title': 'Hesap oluşturun',
  'register.description': 'Plandok randevu planlama ve rezervasyon yazılımında ücretsiz bir hesap oluşturun',
  'register.btn': 'Hesabımı oluştur',
  'register.agreement.agree': 'Şunları kabul ediyorum',
  'register.agreement.terms': 'Site Şartları',
  'register.agreement.policy': 'Gizlilik Politikası',
  'register.agreement.and': 've',
  'register.agreement.partner': 'İş Ortağı Şartları',
  'register.have.account': 'Zaten bir hesabınız mı var?',
  'register.validation.confirm': 'İlerlemeden önce, lütfen yukarıdaki şartları inceleyip onaylayın.',
  'link.sign.up': 'Kayıt olun',
  'text.alreadyHaveAccount': 'Zaten bir hesabınız mı var?',
  'input.company.label': 'Şirket',
  'input.company.placeholder': 'Şirket',
  'input.country.label': 'Ülke',
  'input.country.placeholder': 'Ülke adı',
  'input.timeZone.label': 'Saat dilimi',
  'input.timeZone.placeholder': 'GMT +02.00',
  'input.currency.label': 'Para birimi',
  'input.language.label': 'Dil (Language)',
  'input.language.placeholder': 'Türkçe',
  'input.currency.placeholder': 'Euro - EUR',
  'reset.title': 'Şifreyi sıfırla',
  'reset.btn.text': 'Şifre Sıfırlamayı Gönder',
  'reset.pwd.btn': 'Şifreyi sıfırla',
  'reset.no.password': 'Şifre sıfırlamayı istemiyor musunuz?',
  'reset.description':
    'Lütfen e-posta adresinizi girin ve şifrenizi nasıl değiştireceğinize dair size talimatları göndereceğiz.',
  'sidebar.calendar': 'Takvim',
  'sidebar.client': 'Müşteriler',
  'sidebar.staff': 'Personel',
  'sidebar.service': 'Hizmetler',
  'sidebar.notification': 'Mesajlar',
  'sidebar.settings': 'Ayarlar',
  'sidebar.menu': 'Menü',
  'sidebar.analytics': 'Analitik',
  'sidebar.language': 'Dil (Language)',
  'sidebar.logout': 'Çıkış',
  'user.menu.settings': 'Ayarlarım',
  'user.menu.language': 'Dili değiştir',
  'user.menu.logout': 'Çıkış yapın',
  'input.globalSearch.placeholder': 'Ne arıyorsunuz?',
  'input.globalSearch.description': 'Müşteri adı, telefonu, e-postası veya rezervasyon referansıyla arama yapın',
  'title.upcomingAppointments': 'Yaklaşan Randevular',
  'calendar.day': 'Gün',
  'calendar.week': 'Hafta',
  'calendar.monday': 'Pazartesi',
  'calendar.tuesday': 'Salı',
  'calendar.wednesday': 'Çarşamba',
  'calendar.thursday': 'Perşembe',
  'calendar.friday': 'Cuma',
  'calendar.saturday': 'Cumartesi',
  'calendar.sunday': 'Pazar',
  'calendar.mon': 'Pt',
  'calendar.tue': 'Sa',
  'calendar.wed': 'Ça',
  'calendar.thu': 'Pe',
  'calendar.fri': 'Cu',
  'calendar.sat': 'Ct',
  'calendar.sun': 'Pz',
  'calendar.today': 'Bugün',
  'calendar.january': 'Ocak',
  'calendar.february': 'Şubat',
  'calendar.march': 'Mart',
  'calendar.april': 'Nisan',
  'calendar.may': 'Mayıs',
  'calendar.june': 'Haziran',
  'calendar.july': 'Temmuz',
  'calendar.august': 'Ağustos',
  'calendar.september': 'Eylül',
  'calendar.october': 'Ekim',
  'calendar.november': 'Kasım',
  'calendar.december': 'Aralık',
  'calendar.jan': 'Oca',
  'calendar.feb': 'Şub',
  'calendar.mar': 'Mar',
  'calendar.apr': 'Nis',
  'calendar.may.short': 'May',
  'calendar.jun': 'Haz',
  'calendar.jul': 'Tem',
  'calendar.aug': 'Ağu',
  'calendar.sept': 'Eyl',
  'calendar.oct': 'Eki',
  'calendar.nov': 'Kas',
  'calendar.dec': 'Ara',
  'calendar.allStaff': 'Tüm',
  'calendar.workingStaff': 'Çalışan',
  'calendar.tooltip.time': 'Zaman',
  'calendar.tooltip.category': 'Kategori',
  'calendar.tooltip.service': 'Hizmet',
  'calendar.tooltip.staff': 'Kadro',
  'calendar.tooltip.price': 'Fiyat',
  'calendar.tooltip.repeating': 'Tekrarlayan',
  'btn.viewDetails': 'Ayrıntıları Görüntüle',
  'calendar.tooltip.emptyCustomer': 'Rezervasyonsuz',
  'calendar.tooltip.bookedOnline': 'Online Rezervasyon',
  'calendar.print.description':
    'Yazdırma işlevini kullanabilmeniz için planınızın yükseltilmesi gerekir. Yükseltmek için ziyaret edin',
  'calendar.print.appointmentsBetween.title': 'Randevular',
  'calendar.print.appointmentsAnd.title': 'Ve',
  'calendar.billing.title': 'Plan ve Faturalandırma',
  'calendar.btn.print.title': 'Yazdır',
  'datepicker.next.week': 'Gelecek hafta',
  'datepicker.in.two.weeks': '2 hafta içinde',
  'datepicker.in.three.weeks': '3 hafta içinde',
  'datepicker.in.four.weeks': '4 hafta içinde',
  'datepicker.in.five.weeks': '5 hafta içinde',
  'datepicker.in.six.weeks': '6 hafta içinde',
  'modal.splitAppointment.title': 'Bölünmüş Randevu',
  'modal.splitAppointment.text':
    'Bu randevu, bir grubun parçası olup başka bir güne taşımak ayrı bir rezervasyon olarak bölünmesine neden olacaktır. Emin misiniz?',
  'blocktime.create.modal.title': 'Süre blokla',
  'blocktime.edit.modal.title': 'Süre bloklamayı düzenleyin',
  'appointment.create.menu': 'Randevu',
  'blocktime.create.menu': 'Süre blokla',
  'appointment.new.title': 'Yeni Randevu',
  'input.startTime.label': 'Başlangıç zamanı',
  'input.endTime.label': 'Bitiş zamanı',
  'input.startDate.label': 'Başlangıç ​​tarihi',
  'input.endDate.label': 'Bitiş tarihi seç',
  'input.startDate.description.label': 'Başlangıç tarihi seç',
  'input.endDate.description.label': 'Bitiş tarihi seç',
  'input.closedDatedDescription.placeholder': 'Örneğin. Bayram',
  'input.service.label': 'Hizmet',
  'input.services.label': 'hizmetler',
  'input.service.placeholder': 'Bir hizmet seçin',
  'input.staff.label': 'Personel',
  'input.staff.placeholder': 'Personel seçin',
  'input.pickDateAndTime.title': 'Seçim tarihi ve saati',
  'input.pickDate.title': 'Bir tarih seçin',
  'service.add.another': 'Başka bir hizmet ekleyin',
  'input.appointmentNotes.label': 'Randevu notları',
  'input.emailSubject.placeholder': 'Randevunuz hakkında not',
  'input.appointmentNotes.placeholder': 'Randevu notu ekleyin (yalnızca personel tarafından görülür)',
  'input.searchClients.placeholder': 'Müşteri Ara',
  'input.searchService.placeholder': 'Aramak',
  'appointment.empty.list': 'Müşteri eklemek için arama yapın veya randevusuz olarak kaydetmek için boş tutun.',
  'appointment.clients.empty.list': 'Müşteri listesi boş.',
  'appointment.clients.addClient': 'Müşteri oluştur',
  'client.create.new': 'Yeni müşteri oluştur',
  'client.choose': 'Müşteri seç',
  'client.menu.edit': 'Müşteri detaylarını düzenleyin',
  'client.menu.remove': 'Randevudan kaldır',
  'appointment.btn.save': 'Kaydetmek',
  'appointment.vat': 'KDV ({0}%)',
  'appointment.total': 'Toplam',
  'appointment.view.title': 'Randevuyu Görüntüle',
  'appointment.status.btn': 'Randevu durumu',
  'appointment.btn.cancel': 'Silmek',
  'btn.cancelAppointment': 'Silmek',
  'modal.cancelAppointment.title': 'Silmek',
  'title.clients': 'Müşteriler',
  'client.create.title': 'Yeni Müşteri',
  'client.options.export.title': 'İstemcileri dışa aktar',
  'client.options.export.description': "İstemci verilerini içeren CSV'yi indirin",
  'client.options.import.title': 'İstemcileri içe aktar',
  'client.export.premium.description': 'Dışa Aktarma işlevini kullanabilmek için planınızın yükseltilmesi gerekir.',
  'client.export.premium.visit': 'Yükseltmek için ziyaret edin ',
  'client.import.instructions.description':
    'Müşterileri Plandok hesabınıza aktarmak için aşağıdaki talimatları izleyin',
  'client.import.instructions.download': 'Aşağıdaki düğmeyi kullanarak içe aktarma dosyanızı indirin',
  'client.import.instructions.copy': 'Müşteri ayrıntılarınızı kopyalayıp dosyaya yapıştırın',
  'client.import.instructions.email': 'info@plandok.com adresinden bize e-postayla gönderin',
  'client.import.instructions.account': 've hesabınıza aktaracağız',
  'table.clients.name': 'İsim',
  'table.clients.phone': 'Telefon',
  'table.clients.mobile': 'Telefon',
  'table.clients.email': 'E-posta',
  'table.clients.gender': 'Cinsiyet',
  'client.page.title': 'Müşteri profili',
  'btn.more.options': 'Daha Fazla Seçenek',
  'btn.showAll': 'Hepsini göster',
  'btn.collapse': 'Daralt',
  'btn.reschedule': 'Yeniden planla',
  'title.edit.client': 'Müşteriyi düzenleyin',
  'menu.edit.client': 'Müşteriyi düzenleyin',
  'menu.delete.client': 'Müşteriyi sil',
  'title.mobilePhone': 'Cep Telefonu',
  'title.telephoneNumber': 'Telefon Numarası',
  'title.email': 'E-posta',
  'title.address': 'Adres',
  'title.dateOfBirth': 'Doğum tarihi',
  'title.gender': 'Cinsiyet',
  'client.statistics': 'İstatistikler',
  'client.totalSales': 'Toplam satış',
  'client.totalBookings': 'Tüm Rezervasyonlar',
  'client.outstanding': 'Askıda',
  'client.allBooking': 'Tüm rezervasyonlar',
  'client.completed': 'Tamamlanan',
  'client.cancelled': 'İptal edilen',
  'client.noShow': 'Gelmeyen',
  'appointment.list.title': 'Randevular',
  'upcoming.list.title': 'Yaklaşan',
  'past.list.title': 'Geçmiş',
  'modal.confirmClientDelete.text.0': 'Müşteriyi tamamen veritabanından',
  'modal.confirmClientDelete.text.1': 'silmek istediğinize emin misiniz?',
  'input.date.label': 'Belirli Tarih',
  'input.date.placeholder': 'Tarih Seç',
  'input.firstName.label': 'İsim',
  'input.firstName.placeholder': 'örn. Ali',
  'input.lastName.label': 'Soyisim',
  'input.lastName.placeholder': 'örn. Alkış',
  'input.mobile.label': 'Cep telefonu',
  'input.mobile.placeholder': 'Numarayı girin',
  'input.email.label': 'E-posta adresi',
  'input.email.placeholder': 'örn. alialkis@gmail.com',
  'input.title.label': 'Başlık',
  'input.title.placeholder': 'örneğin Dişçi',
  'input.notificationType.label': 'Bildirimleri gönder',
  'tab.personalInfo': 'Kişisel Bilgi',
  'input.gender.label': 'Cinsiyet',
  'input.referal.label': 'Referans kaynağı',
  'input.referal.placeholder': 'Kaynağı Seç',
  'input.birthDay.label': 'Doğum günü',
  'input.month.placeholder': 'Ay',
  'input.day.placeholder': 'Gün',
  'input.year.placeholder': 'Yıl',
  'input.clientsNotes.label': 'Müşteri notları',
  'input.clientsNotes.placeholder': 'Müşteri notlarını girin',
  'input.bookingDisplay.label': 'Tüm rezervasyonlarda göster',
  'input.serviceDescription.label': 'Hizmet açıklaması',
  'input.serviceDescription.placeholder': 'Online rezervasyonda müşterilere görünecek kısa bir tanım girin',
  'input.isBookableOnline.label': 'Online rezervasyon için müsait',
  'service.onlineBookings.title': 'Online rezervasyonlar',
  'tab.address': 'Adres',
  'input.address.label': 'Adres',
  'input.address.placeholder': 'Adresinizin girin',
  'input.city.label': 'Şehir',
  'input.city.placeholder': 'Şehir girin',
  'input.zip.label': 'Posta Kodu',
  'input.zip.placeholder': 'Posta Kodunu girin',
  'btn.edit': 'Düzenleyin',
  'btn.cancel': 'İptal',
  'btn.save': 'Kaydet',
  'btn.save.changes': 'Kaydet',
  'tabs.staffWorking.title': 'Çalışma Saatleri',
  'tabs.staffMembers.title': 'Personel',
  'tabs.userPermissions.title': 'Kullanıcı İzinleri',
  'tabs.closedDates.title': 'Kapalı Tarihler',
  'btn.new.staff': 'Yeni Personel',
  'table.staff.name': 'İsim',
  'table.staff.mobileNumber': 'Telefon',
  'table.staff.email': 'E-posta',
  'table.staff.userPermission': 'Kullanıcı izni',
  'table.staff.dataRange': 'Tarih aralığı',
  'table.staff.numberOfDays': 'Gün sayısı',
  'table.staff.location': 'Yer',
  'table.staff.description': 'Açıklama',
  'table.appointmentsList.appointment': 'Randevu',
  'table.appointmentsList.client': 'Müşteri',
  'table.appointmentsList.service': 'Hizmet',
  'table.appointmentsList.date': 'Tarih',
  'table.appointmentsList.time': 'Zaman',
  'table.appointmentsList.duration': 'Süre',
  'table.appointmentsList.location': 'Konum',
  'table.appointmentsList.staff': 'Kadro',
  'table.appointmentsList.price': 'Fiyat',
  'table.appointmentsList.status': 'Durum',
  'table.appointmentsCancellations.ref': 'Referans',
  'table.appointmentsCancellations.client': 'Müşteri',
  'table.appointmentsCancellations.service': 'Hizmet',
  'table.appointmentsCancellations.scheduledDate': 'Planlanan Tarih',
  'table.appointmentsCancellations.cancelledDate': 'İptal Tarihi',
  'table.appointmentsCancellations.cancelledBy': 'Tarafından iptal edildi',
  'table.appointmentsCancellations.reason': 'Sebep',
  'table.appointmentsCancellations.price': 'Fiyat',
  'table.appointmentsCancellations.totalCount': 'Toplam Sayı',
  'table.clients.appointments': 'Randevular',
  'table.clients.noShows': 'Gösteri Yok',
  'table.clients.totalSales': 'Toplam Satışlar',
  'table.clients.added': 'Eklendi',
  'table.clients.daysAbsent': 'Devamsızlık Günleri',
  'table.clients.lastAppointment': 'Son Randevu',
  'table.clients.lastLocation': 'Son Konum',
  'table.appointmentsByService.serviceName': 'Hizmet Adı',
  'table.appointmentsByService.appointments': 'Randevular',
  'table.appointmentsByService.totalValue': 'Toplam Değer',
  'table.appointmentsByStaff.staffMember': 'Personel',
  'table.appointmentsByStaff.appointments': 'Randevular',
  'table.appointmentsByStaff.totalValue': 'Toplam Değer',
  'table.pagination.showing.title': 'Gösteriliyor',
  'table.pagination.results.title': 'sonuçlar',
  'clients.duplicate.title': 'Yinelenen istemciler bulundu.',
  'clients.duplicate.description': 'Birleştirilebilecek benzer istemciler var.',
  'clients.merge.btn.title': 'Birleştir',
  'clients.merge.modal.title': 'İstemcileri birleştir',
  'clients.merge.selectAll.title': 'Tüm kopyaları seç',
  'clients.merge.duplicatesNotFound.title': 'Çiftler bulunamadı',
  'clients.merge.duplicatesNotFound.description': 'Birleştirilebilecek müşteri yok',
  'clients.mergeConfirm.modal.title': 'Birleştirmeyi Onayla',
  'clients.mergeConfirm.modal.description':
    '{0} kopyayı birleştirmek istediğinizden emin misiniz? Bu işlem kalıcıdır ve geri alınamaz.',
  'clients.mergeConfirm.checkbox.title': 'İstemci birleştirme işleminin geri alınamayacağını anlıyorum',
  'clients.mergeConfirm.btn.title': 'Onayla ve Birleştir',
  'clients.mergeConfirm.cancel.btn.title': 'İptal etmek',
  'clients.byNumber.btn.title': 'Telefonla {0}',
  'clients.byEmail.btn.title': 'E-postayla {0}',
  'clients.byName.btn.title': 'Ada Göre {0}',
  'title.newStaff': 'Yeni personel',
  'title.editStaff': 'Personeli düzenleyin',
  'description.extraCharge': 'Yeni personel eklendiğinde, aylık aboneliğinize ekstra ücret eklenecektir',
  'tabs.details': 'Detaylar',
  'input.staffPicture.label': 'Resim',
  'input.cropImage.title': 'Resmi Kırp',
  'input.cropImage.btn.apply.title': 'Uygula',
  'input.stafffirstName.label': 'İsim',
  'input.stafffirstName.placeholder': 'örn. Ali',
  'input.stafflastName.label': 'Soyisim',
  'input.stafflastName.placeholder': 'örn. Alkış',
  'input.stafmobile.label': 'Telefon',
  'input.stafemail.label': 'E-posta',
  'input.stafemail.placeholder': 'örn. alialkis@plandok.com',
  'input.permission.label': 'Kullanıcı izinleri',
  'input.uploadProfile.label': 'Profil resmi yükle',
  'input.changeProfile.label': 'Profil resmi değiştir',
  'input.notes.label': 'Notlar',
  'input.staffNotes.placeholder': 'Sadece personel ayarlarında görüntülenebilir özel notlar ekleyin (opsiyonel)',
  'input.blockTime.placeholder': 'örn. yemek toplantısı',
  'tabs.locations': 'Konum',
  'tabs.integrations': 'Entegrasyonlar',
  'input.staffLocations.description': 'Bu personelin rezerve edilebileceği yerleri ata.',
  'input.multiLocation.description': 'Bu personelin rezerve edilebileceği yerleri ata.',
  'tabs.services': 'Hizmetler',
  'input.staffServices.description': 'Bu personelin sunduğu hizmetleri ata.',
  'input.multiServices.description': 'Bu personelin sunduğu hizmetleri ata.',
  'input.shiftStart.label': 'Vardiya başlangıç',
  'input.shiftEnd.label': 'Vardiya bitiş',
  'edit.staff.title.resendPassword': 'Şifre yeniden gönder',
  'edit.staff.info.resendPassword':
    'Personel, şifreli bir e-posta almadı mı? Parola kurulum talimatlarını içeren e-postayı yeniden göndermek için aşağıdaki bir düğmeye tıklayın.',
  'edit.staff.button.resendPassword': 'Şifre e-postasını yeniden gönder',
  'btn.add.shift': 'Başka bir vardiya ekleyin',
  'break.time': '{0} mola',
  'input.repeat.label': 'Tekrarla',
  'input.repeatWeekly.label': 'Haftalık',
  'input.repeatDoNot.label': 'Tekrarlama',
  'input.endRepeat.label': 'Sonları tekrarla',
  'input.endRepeatOngoing.label': 'Devam eden',
  'input.endRepeatSpecificDate.label': 'Belirli Tarih',
  'input.endRepeatDate.placeholder': 'Tarih Seç',
  'btn.deleteUpcomingShift': 'Yaklaşan vardiyaları silin',
  'btn.deleteThisShift': 'Sadece bu vardiyayı silin',
  'btn.updateUpcomingShift': 'Yaklaşan vardiyaları güncelleyin',
  'btn.updateThisShift': 'Sadece bu vardiyayı güncelleyin',
  'modal.repeatShift.title': 'Tekrar eden vardiya',
  'form.error.uniqShifts': 'Geçişler benzersiz olmalı',
  'btn.add.new': 'Yeni ekleyin',
  'btn.addNewService': 'Yeni hizmet ekleyin',
  'btn.newCategory': 'Yeni kategori',
  'btn.newServiceGroup': 'Yeni kategori',
  'btn.editCategory': 'Kategoriyi düzenleyin',
  'btn.deleteCategory': 'Kategoriyi sil',
  'btn.downloadImportFile': 'İçe aktarma dosyasını indir',
  'serviceGroup.delete.success': 'Kategori başarıyla silindi!',
  'service.new.title': 'Yeni Hizmet',
  'service.edit.title': 'Hizmeti Düzenleyin',
  'input.serviceName.label': 'Hizmet adı',
  'input.serviceName.placeholder': 'örn. Masaj',
  'input.serviceCategory.label': 'Hizmet Kategorisi',
  'input.serviceCategory.placeholder': 'Servis kategorisi seçin',
  'input.price.label': 'Fiyat',
  'input.price.placeholder': '0',
  'input.duration.label': 'Süre',
  'input.duration.placeholder': 'Süre',
  'input.staffSelect.description': 'Bu hizmeti sağlayan personel seç',
  'input.newCategoryName.label': 'Kategori adı',
  'input.newCategoryDescription.label': 'Kategori açıklaması',
  'input.newCategoryDescription.placeholder': 'Bu kategoriyi açıklayın, Çevrimiçi rezervasyonlarda gösterilecektir.',
  'modal.newCategory.title': 'Yeni kategori',
  'modal.editCategory.title': 'Kategoriyi düzenleyin',
  'input.chooseCategory.label': 'Kategori rengini seçin:',
  'client.messages.title': 'Müşteri mesajı',
  'tabs.messagesLog': 'Mesaj kaydı',
  'tabs.messagesLog.description': 'Müşterilerinize gönderilen mesajların listesi',
  'tabs.messagesSettings': 'Ayarlar',
  'tabs.messagesSettings.description': 'Mesaj ayarlarınızı iş ihtiyaçlarınıza uyacak şekilde ayarlayın',
  'table.header.timeSent': 'Gönderildi',
  'table.header.client': 'Müşteri',
  'table.header.appointment': 'Randevu',
  'table.header.destination': 'Adres',
  'table.header.type': 'Tür',
  'table.header.message': 'Mesaj',
  'table.header.status': 'Durum',
  'table.header.cost': 'Maliyet',
  'modal.message.title': 'Mesaja Bak',
  'setup.page.title': 'Kurulum',
  'title.accountSettings': 'Hesap ayarları',
  'title.accountSetup': 'Hesap kurulumu',
  'title.accountSetup.description': 'İş adı ve saat dilimi gibi ayarları yönetin',
  'resources.list.title': 'Kaynaklar',
  'title.resources': 'Kaynaklar',
  'title.resources.description': 'Oda, sandalye veya ekipman gibi rezerve edilebilir kaynakları kurun',
  'title.locations': 'Konum',
  'title.locations.description': 'İşletme çoklu satış yerlerini yönetin',
  'calendar.settings.title': 'Takvim Ayarları',
  'calendar.btn.selectFromCalendar': 'Takvimden seçim yapın',
  'title.calendarSettings': 'Takvim Ayarları',
  'title.calendarSettings.description': 'Takvimin renk ve düzenini ayarla',
  'title.clientSettings': 'Müşteri ayarları',
  'title.onlineBookingSettings': 'Online rezervasyon ayarları',
  'title.salesSettings': 'Satış ayarları',
  'title.receiptSequencing': 'Makbuz sıralaması',
  'title.receiptSequencing.description':
    'Müşterilerinize verilen satış makbuzlarında görüntülenen ayrıntıları yapılandırın',
  'title.receiptTemplate': 'Makbuz şablonu',
  'title.taxes.description': 'Ödeme sırasında satılan ürünlere uygulanan vergi oranlarını yönetme',
  'title.paymentTypes': 'Ödeme türleri',
  'title.paymentTypes.description': 'Ödeme sırasında kullanılmak üzere manuel ödeme türlerini ayarlama',
  'title.discounts.description': 'İndirimlerinizi ayarlayın',
  'title.receiptPrefix': 'Makbuz №. Önek',
  'title.receiptNumber': 'Sonraki makbuz numarası',
  'title.change': 'Değişim',
  'title.saleReceiptTemplate': 'Satış makbuzu şablonu',
  'description.saleReceiptTemplate': 'Müşterilerinize verilen satış makbuzlarında görüntülenen içeriği özelleştirin',
  'setting.automaticallyPrint': 'Satış tamamlandığında makbuzları otomatik olarak yazdırın',
  'setting.showMobile': 'Satış makbuzunda müşterinin cep telefonunu ve e-postasını gösterin',
  'setting.showAddress': 'Satış makbuzunda müşteri adresini gösterin',
  'title.receiptTitle': "Makbuz başlığı'",
  'title.referrals': 'Yönlendirme Kaynakları',
  'title.referrals.description': 'Müşterinin işletmenizi nasıl bulduğunu takip etmek için özel kaynak oluşturun',
  'title.clientNotifications': 'Müşteri Bildirim Ayarları',
  'title.clientNotifications.description': 'Müşterilere randevuları hakkında gönderilen mesajları yönetin',
  'cancelReason.list.title': 'İptal Nedeni',
  'title.cancellationReasons': 'İptal Nedeni',
  'title.cancellationReasons.description': 'Müşterilerin randevularına neden gelmediğini takip et',
  'title.onlineBooking.description': 'Tur çevrimiçi rezervasyon kullanılabilirliğini ve ayarlarını yönetin',
  'title.pointOfSale': 'Satış noktası',
  'discount.list.title': 'İndirim',
  'title.discountTypes': 'İndirim Türleri',
  'title.discountTypes.description': 'Satın alma ekranında kullanılacak manuel indirim türleri belirleyin',
  'title.premiumFeature': 'Premium Özellik',
  'company.details.title': 'Şirket bilgileri',
  'input.businessName.label': 'İş adı',
  'input.businessName.placeholder': 'örn. Erdal Bakkal',
  'input.description.label': 'Açıklama',
  'input.description.placeholder': 'Açıklama gir',
  'input.businessAddress.label': 'Adres',
  'input.businessAddress.placeholder': 'Adres girin',
  'input.website.label': 'Site',
  'input.website.placeholder': 'Site adresi girin',
  'input.contactNumber.label': 'İletişim numarası',
  'input.contactNumber.placeholder': 'Numarayı girin',
  'input.businessTimeZone.label': 'Saat dilimi',
  'input.businessTimeZone.placeholder': 'GMT +02.00',
  'input.businessCountry.label': 'Ülke',
  'input.businessCountry.placeholder': 'Ülke Seçin',
  'input.timeFormat.label': 'Saat biçimi',
  'input.timeFormat.placeholder': '24-saat',
  'table.header.resourceName': 'Kaynak',
  'btn.new.resource': 'Yeni Kaynak',
  'input.resourceName.label': 'Kaynak Adı',
  'input.resourceName.placeholder': 'İsim girin',
  'input.resourceDescription.label': 'Açıklama',
  'input.resourceDescription.placeholder': 'Açıklama gir',
  'input.resourceLocation.placeholder': 'Konum adı',
  'resourcesTooltip.location': 'Kaynak için konum bilgi çubuğu',
  'modal.newResource.title': 'Yeni Kaynak Oluştur',
  'modal.editResource.title': 'Kayank düzele',
  'table.header.locationName': 'Konum',
  'table.header.locatonAddress': 'Adres',
  'table.header.location.Phone': 'Telefon',
  'modal.newLocation.title': 'Yeni Konum',
  'modal.editLocation.title': 'Konum Düzenleyin',
  'btn.new.location': 'Yeni Konum',
  'btn.addExtraLocation': 'Ekstra konum ekleyin',
  'input.locationName.label': 'Konum adı',
  'input.locationName.placeholder': 'Konum Adını Girin',
  'input.profile.locationName.placeholder': 'Örn. Berber Dükkanı',
  'input.slug.label': 'Slug',
  'input.locationTips.label': 'Konum ipuçları',
  'input.slug.explanation': "Konum URL'sinde görünür olacaktır",
  'input.contactEmail.label': 'İletişim E-posta adresi',
  'input.locationAddress.label': 'Adres',
  'input.locationAddress.placeholder': 'Konum Adresi Girin',
  'tooltip.contactEmail': 'Müşteri randevu bildirim cevapları bu mail adresine gidecek.',
  'input.appointmentColors.label': 'Randevu Rengi',
  'input.colorBy.service': 'Hizmet grububa göre',
  'input.colorBy.employee': 'Çalışana göre',
  'input.colorBy.appointment': 'Randevu durumuna göre',
  'input.timeSlot.label': 'Zaman dilimi aralığı',
  'input.defaultViewType.label': 'Varsayılan görünüm',
  'input.calendarTimeRange.label': 'Takvim zaman aralığı',
  'input.calendarTimeRange.allTimes.option': 'Tüm zamanlar (00:00 - 24:00)',
  'input.calendarTimeRange.onlyWorkingHours.option': 'Sadece çalışma saatleri',
  'input.calendarTimeRange.customRange.option': 'Özel aralık',
  'input.weekStart.label': 'Hafta başlangıç günü',
  'table.header.refSourcetitle': 'Yönlendirme Kaynağı',
  'table.header.refSourceAddDate': 'Tarih eklendi',
  'btn.new.referral': 'Yeni Yönlendirme Kaynağı',
  'input.nameRefSource.label': 'Yönlendirme Kaynak adı',
  'input.nameRefSource.placeholder': 'örn. Facebook reklamları',
  'modal.newRefSource.title': 'Yeni Yönlendirme Kaynağı',
  'modal.editRefSource.title': 'Yönlendirme Kaynağı Düzenleyin',
  'input.sendBy.label': 'Gönder',
  'input.emailSubject.label': 'E-posta konusu',
  'input.emailTemplate.label': 'E-posta şablonu',
  'input.emailTemplate.placeholder': 'E-posta şablonu girin',
  'input.subject.label': 'E-posta konusu',
  'input.subject.placeholder': 'E-posta konusu girin',
  'title.messagingSettings.label': 'Mesaj Ayarları',
  'title.messagingSettings.description':
    'Burada belirlenen mesaj türleri müşterilere otomatik olarak gönderilecektir. Oluşturulan tüm mesajlara mesajlar sayfasından kolayca erişilebilir (ana menüde). Mesajların nasıl ve ne zaman gönderileceği ayarlarını yapın ve metni kişiselleştirmek için şablonları düzenleyin. Mesajların içerisine randevu detaylarını dahil etmek için aşağıdaki etiketleri kullanın:',
  'client.first.name': 'CLIENT_FIRST_NAME',
  'client.last.name': 'CLIENT_LAST_NAME',
  'staff.first.name': 'STAFF_FIRST_NAME',
  'staff.last.name': 'STAFF_LAST_NAME',
  'booking.date.time': 'BOOKING_DATE_TIME',
  'booking.date': 'BOOKING_DATE',
  'booking.time': 'BOOKING_TIME',
  'service.name': 'SERVICE_NAME',
  'business.name': 'BUSINESS_NAME',
  'business.phone': 'BUSINESS_PHONE',
  'business.address': 'BUSINESS_ADDRESS',
  'business.email': 'BUSINESS_EMAIL',
  'location.name': 'LOCATION_NAME',
  'location.phone': 'LOCATION_PHONE',
  'location.address': 'LOCATION_ADDRESS',
  'location.email': 'LOCATION_EMAIL',
  'tabs.reminders.title': 'Hatırlatıcı',
  'input.enableReminder.label': 'Hatırlatıcı Mesajlarını Etkinleştir',
  'input.enableReminder.description': 'Müşterilere yaklaşan randevuları öncesinde otomatik olarak gönderir',
  'input.reminder.title': 'Hatırlatma {0}',
  'input.message.title': 'Mesaj {0}',
  'input.reminder.description': 'Kanallar ve gönderme zamanı',
  'input.reminder.channels': 'Bu mesajın gönderileceği kanalları seçin',
  'input.reminderBefore.label': 'Hatırlatıcılar öncesinde gönderilecektir',
  'input.smsSender.label': 'SMS Gönderen Kimliği',
  'input.messageSender.label': 'Mesaj Gönderen Kimliği',
  'input.smsSender.placeholder': 'SMS Gönderen Kimliği Girin',
  'input.smsTemplate.label': 'SMS şablonu',
  'input.messageTemplate.label': 'Mesaj şablonu',
  'input.smsTemplate.placeholder': 'SMS şablonunu girin',
  'input.whatsAppSender.placeholder': 'Plandok',
  'reminder.whatsApp.notificate':
    'WhatsApp mesaj şablonu şu anda sınırlamalar nedeniyle düzenlenemiyor. Ancak, tamamen düzenlenebilir hale getirmek için aktif olarak çalışıyoruz.',
  'reminder.charactersCount': 'Karakter sayısı:',
  'reminder.pricing': 'Fiyatlandırma',
  'modal.pricing.description':
    'Farklı ülkelerin farklı fiyat oranları vardır. Ortaklarımızdan mümkün olan en iyi fiyatı sağlıyoruz. Değişiklik gösterebilir.',
  'modal.pricing.subDescription':
    'Ücretler, mesajlar gönderildikten sonra uygulanacak ve gelecek faturaya eklenecektir.',
  'modal.pricing.counterDescription':
    "Standart bir metin SMS'i 160 karakterle ve özel karakterler içeren mesajlar için 70 karakterle sınırlıdır.",
  'modal.pricing.learnMore': 'Daha fazla bilgi edinin',
  'modal.pricing.priceRates': 'Fiyat oranları',
  'modal.pricing.messagePrice': '1 mesaj fiyatı:',
  'modal.sendTestMessageByNumber.title': 'Bu numaraya test mesajı gönderin:',
  'modal.sendTestMessageByNumber.description': 'Ücretler normal mesaja göre uygulanır.',
  'modal.sendTestMessageByEmail.title': 'Bu e-postaya test Mesajı gönderin:',
  'modal.sendTestMessageByEmail.placeholder': 'Lütfen e-posta adresini girin',
  'btn.send': 'Gönder',
  'reminder.sendTestMessage': 'Test mesajı gönder',
  'button.reminder.addTag': 'Etiket ekle',
  'button.reminder.addAnotherReminder': 'BAŞKA BİR HATIRLATICI EKLE',
  'button.message.addAnotherMessage': 'BAŞKA BIR MESAJ EKLEYIN',
  'button.reminder.upgrade': 'Erişmek için yükseltin',
  'label.emailSettings': 'Email Ayarları',
  'label.SMSSettings': 'SMS Ayarları',
  'label.whatsAppSettings': 'WhatsApp Ayarları',
  'tooltip.sender.0': 'SMS gönderen adı olarak görüntülenecektir',
  'tooltip.sender.1': 'En fazla 11 karakter',
  'tooltip.sender.2': 'Bazı ülkelerde çalışmayabilir',
  'tooltip.sender.3': 'Sadece Latin harfleri veya sayılar',
  'tabs.confirmations.title': 'Onay',
  'btn.appBookings': 'Uygulama İçi Rezervasyonlar',
  'btn.onlineBookings': 'Online Rezervasyonlar',
  'input.enableAppConfirmation.label': 'Uygulama içi Rezervasyon onaylarını etkinleştirin',
  'input.enableOnlineConfirmation.label': 'Online Rezervasyon onaylarını etkinleştirin',
  'input.enableConfirmationApp.description': 'Müşteriler için randevu alındığında müşterilere otomatik olarak gönderir',
  'input.enableConfirmationOnline.description': 'Randevu aldıklarında müşterilere otomatik olarak gönderilir',
  'messageSetup.header': 'Kanallar',
  'channels.subHeader': 'Channels',
  'tabs.reschedules.title': 'Yeniden planla',
  'input.enableReschedule.label': 'Yeniden Planlama Mesajlarını Etkinleştir',
  'input.enableReschedule.description': 'Randevu saati değiştiğinde müşterilere otomatik olarak gönderir',
  'tabs.cancellations.title': 'İptal',
  'input.enableCancellation.label': 'İptal Mesajlarını Etkinleştir',
  'input.enableCancellations.description': 'Randevu iptal edilidğinde müşterilere otomatik olarak gönderir',
  'tabs.thanks.title': 'Teşekkürler',
  'input.enableThank.label': 'Teşekkür Mesajlarını Etkinleştir',
  'input.enableThankyou.description': 'Randevu durumu tamamlandı olarak işaretlendiğinde müşterilere gönderilir',
  'tabs.lastVisit.title': 'Son ziyaret',
  'input.enableLastVisit.label': 'Son Ziyaret Mesajlarını Etkinleştir',
  'input.enableLastVisit.description':
    'Belirlenen gün sayısı kadar işletmeniz ziyaret edilmediğinde müşterilere otomatik olarak gönderir',
  'table.cancelReason.name': 'İptal Nedeni',
  'table.cancelReason.addDate': 'Tarih eklendi',
  'btn.new.cancelReason': 'Yeni İptal Nedeni',
  'modal.newCancelReason.title': 'Yeni İptal Nedeni',
  'modal.editCancelReason.title': 'İptal Nedeni Düzenleyin',
  'input.cancelReason.title': 'İptal Nedeni',
  'input.cancelReason.placeholder': 'örn. müşteri müsait değil',
  'table.discounts.name': 'İndirim',
  'table.discounts.value': 'Değer',
  'table.discounts.addDate': 'Oluşturulma Tarihi',
  'input.discountName.label': 'İndirim adı',
  'input.discountName.placeholder': 'örn. Yaşlı müşteriler',
  'input.discountValue.label': 'İndirim Değeri',
  'btn.new.discount': 'Yeni İndirim',
  'input.discountPercentage.label': '% Yüzde',
  'input.discountSum.label': 'Miktar',
  'modal.newDiscount.title': 'Yeni İndirim Türü',
  'modal.editDiscount.title': 'İndirimi Düzenleyin',
  'modal.deleteAccount.title': 'Hesabı Sil',
  'modal.deleteAccount.description':
    'Hesabınızı silmek geri döndürülemez bir işlemdir ve tüm verileriniz sonsuza kadar silinecektir.',
  'modal.deleteAccount.successMessage': 'Hesabınız başarıyla silindi.',
  'title.mySettings': 'Ayarlarım',
  'title.personalDetails': 'Kişisel Bilgiler',
  'title.personalDetails.description':
    'İsim ve iletişim bilgilerinizi girin, burada girilen e-posta giriş için kullanılacaktır.',
  'btn.continueWithGoogle.title': 'Google ile devam edin',
  'btn.continueWithFacebook.title': 'Facebook ile devam edin',
  'notification.googleAccountUnlinked': 'Google hesabının bağlantısı kaldırılmış',
  'notification.googleAccountLinked': 'Google hesabı bağlantılı',
  'notification.facebookAccountUnlinked': 'Facebook hesabının bağlantısı kaldırıldı',
  'notification.facebookAccountLinked': 'Facebook hesabı bağlantılı',
  'btn.linkAccount': 'Hesap bağlantısı',
  'btn.unlinkAccount': 'Hesap bağlantısını kaldır',
  'btn.deleteYourAccount': 'Hesabınızı silin',
  'input.myName.label': 'İsim',
  'input.myName.placeholder': 'örn. Ali',
  'input.mylastName.label': 'Soyisim',
  'input.mylastName.placeholder': 'örn. Alkış',
  'title.changePassword': 'Şifre değiştir',
  'title.changePassword.description':
    'Değiştirmek için, varolan şifrenizi ve sonrasında yeni şifrenizi girin. Varolan şifrenizi bilmiyorsanız, çıkış yapabilir ve Giriş sayfasındaki Şifreyi Sıfırla seçeneğini kullanabilirsiniz.',
  'input.currentPassword.label': 'Varolan Şifre',
  'label.currentPassword.placeholder': 'Varolan Şifreyi Girin',
  'input.newPassword.label': 'Yeni şifre',
  'input.newPassword.placeholder': 'Yeni Şifreyi Girin',
  'input.verifyPassword.label': 'Şifreyi Yeniden Girin',
  'input.verifyPassword.placeholder': 'Yeni Şireyi Yeniden Girin',
  'btn.confirmDelete': 'Evet, şimdi Sil',
  'btn.confirm': 'Onayla',
  'btn.confirmBook': 'Onaylayın ve ayırtın',
  'btn.confirmPay': 'Onaylayın ve ödeyin',
  'btn.delete': 'Sil',
  'btn.close': 'Kapat',
  'validation.required': 'Gerekli alan',
  'validation.email.format': 'Geçersiz e-posta biçimi',
  'validation.url.format': 'Hatalı url biçimi, https:// veya http:// ile başlamalıdır',
  'validation.password.length': 'Şifre en az 7 karakter olmalıdır',
  'validation.password.letters': 'Şifre sayı ve harf içermelidir',
  'validation.startTime': 'Başlangıç zamanı Bitiş zamanından sonra veya aynı olamaz',
  'validation.endTime': 'Bitiş zamanı Başlangıç zamanından önce veya aynı olamaz',
  'tooltip.password.title': 'Şifrede şunlar olmalı:',
  'tooltip.password.characters': 'En az 7 karakter olmalı',
  'tooltip.password.number': 'Bir sayı içemeli',
  'tooltip.password.letter': 'Bir harf içermeli',
  'tooltip.location': 'Konum bilgi çubuğu',
  'footer.support.center': 'Yardım mı gerekli? Bize ulaşın',
  'not.found': 'Bulunamadı',
  'input.cancelReason.label': 'İptal Nedeni',
  'input.location.label': 'Konum',
  'input.location.placeholder': 'Konum adı',
  'input.name.label': 'İsim',
  'title.recentlyAddedClients': 'Müşteriler (Son Eklenen)',
  'title.calendarView': 'Takvim Görünümü',
  'text.manageProfile': 'Kullanıcı profilini yönetin',
  'modal.language.title': 'Dili seç',
  'modal.language.placeholder': 'Arama dili',
  'dropdown.access.medium': 'Orta',
  'dropdown.access.high': 'Yüksek',
  'dropdown.access.low': 'Düşük',
  'dropdown.access.basic': 'Temel',
  'dropdown.access.no': 'Erişim Yok',
  'dropdown.access.owner': 'Sahip',
  'button.select.all': 'Tümünü Seç',
  'button.selected': 'Seçildi',
  'dropdown.gender.unknown': 'Bilinmiyor',
  'dropdown.gender.male': 'Erkek',
  'dropdown.gender.female': 'Kadın',
  'dropdown.notificationBy.none': 'Gönderme',
  'dropdown.notificationBy.sms': 'SMS',
  'dropdown.notificationBy.email': 'E-posta',
  'dropdown.notificationBy.combined': 'SMS ve e-posta',
  'notification.success.login': 'Başarıyla giriş yapıldı!',
  'notification.success.register': 'Başarıyla kayıt olundu!',
  'dropdown.time.minutes': 'dakika',
  'dropdown.repeat.no': 'Tekrarlama',
  'dropdown.repeat.weekly': 'Haftalık',
  'dropdown.endRepeat.ongoing': 'Devam eden',
  'dropdown.endRepeat.weekly': 'Belirli Tarih',
  'dropdown.time.appointment.immediately': 'Hemen',
  'dropdown.time.appointment.upTo1Hour': '1 saate kadar',
  'dropdown.time.appointment.upTo2Hours': '2 saate kadar',
  'dropdown.time.appointment.upTo3Hours': '3 saate kadar',
  'dropdown.time.appointment.upTo4Hours': '4 saate kadar',
  'dropdown.time.appointment.upTo5Hours': '5 saate kadar',
  'dropdown.time.appointment.upTo6Hours': '6 saate kadar',
  'dropdown.time.appointment.upTo7Hours': '7 saate kadar',
  'dropdown.time.appointment.upTo8Hours': '8 saate kadar',
  'dropdown.time.appointment.upTo9Hours': '9 saate kadar',
  'dropdown.time.appointment.upTo10Hours': '10 saate kadar',
  'dropdown.time.appointment.upTo11Hours': '11 saate kadar',
  'dropdown.time.appointment.upTo12Hours': '12 saate kadar',
  'dropdown.time.appointment.upTo13Hours': '13 saate kadar',
  'dropdown.time.appointment.upTo14Hours': '14 saate kadar',
  'dropdown.time.appointment.upTo15Hours': '15 saate kadar',
  'dropdown.time.appointment.upTo16Hours': '16 saate kadar',
  'dropdown.time.appointment.upTo17Hours': '17 saate kadar',
  'dropdown.time.appointment.upTo18Hours': '18 saate kadar',
  'dropdown.time.appointment.upTo19Hours': '19 saate kadar',
  'dropdown.time.appointment.upTo20Hours': '20 saate kadar',
  'dropdown.time.appointment.upTo21Hours': '21 saate kadar',
  'dropdown.time.appointment.upTo22Hours': '22 saate kadar',
  'dropdown.time.appointment.upTo23Hours': '23 saate kadar',
  'dropdown.time.appointment.upTo24Hours': '1 güne kadar',
  'dropdown.time.appointment.upTo48Hours': '2 güne kadar',
  'dropdown.time.appointment.upTo3days': '3 güne kadar',
  'dropdown.time.appointment.upTo4days': '4 güne kadar',
  'dropdown.time.appointment.upTo5days': '5 güne kadar',
  'dropdown.time.appointment.upTo6days': '6 güne kadar',
  'dropdown.time.appointment.upTo1week': '1 haftaya kadar',
  'dropdown.time.appointment.upTo2weeks': '2 haftaya kadar',
  'dropdown.time.appointment.upTo15Min': '15 dakika kadar',
  'dropdown.time.appointment.upTo30Min': '30 dakika kadar',
  'dropdown.time.appointment.upTo1Month': '1 aya kadar',
  'dropdown.time.appointment.upTo2Months': '2 aya kadar',
  'dropdown.time.appointment.upTo3Months': '3 aya kadar',
  'dropdown.time.appointment.upTo4Months': '4 aya kadar',
  'dropdown.time.appointment.upTo5Months': '5 aya kadar',
  'dropdown.time.appointment.upTo6Months': '6 aya kadar',
  'dropdown.time.appointment.interval5Min': '5 dakika',
  'dropdown.time.appointment.interval10Min': '10 dakika',
  'dropdown.time.appointment.interval15Min': '15 dakika',
  'dropdown.time.appointment.interval20Min': '20 dakika',
  'dropdown.time.appointment.interval30Min': '30 dakika',
  'dropdown.time.appointment.interval40Min': '40 dakika',
  'dropdown.time.appointment.interval45Min': '45 dakika',
  'dropdown.time.appointment.interval50Min': '50 dakika',
  'dropdown.time.appointment.interval60Min': '60 dakika',
  'dropdown.time.appointment.interval75Min': '75 dakika',
  'dropdown.time.appointment.interval90Min': '90 dakika',
  'dropdown.time.appointment.interval120Min': '120 dakika',
  'dropdown.time.appointment.interval150Min': '150 dakika',
  'dropdown.time.appointment.interval180Min': '180 dakika',
  'dropdown.time.appointment.interval240Min': '240 dakika',
  'dropdown.time.appointment.anyTime': 'Her zaman',
  'dropdown.time.appointment.disabled': 'Devre dışı',
  'dropdown.time.appointment.doesntRepeat': 'Tekrarlanmıyor',
  'dropdown.time.appointment.daily': 'Günlük',
  'dropdown.time.appointment.every2Days': 'Her 2 günde bir',
  'dropdown.time.appointment.every3Days': 'Her 3 günde bir',
  'dropdown.time.appointment.every4Days': 'Her 4 günde bir',
  'dropdown.time.appointment.every5Days': 'Her 5 günde bir',
  'dropdown.time.appointment.every6Days': 'Her 6 günde bir',
  'dropdown.time.appointment.every7Days': 'Her 7 günde bir',
  'dropdown.time.appointment.weekly': 'Haftalık',
  'dropdown.time.appointment.every2weeks': 'Her 2 haftada bir',
  'dropdown.time.appointment.every3weeks': 'Her 3 haftada bir',
  'dropdown.time.appointment.every4weeks': 'Her 4 haftada bir',
  'dropdown.time.appointment.every5weeks': 'Her 5 haftada bir',
  'dropdown.time.appointment.every6weeks': 'Her 6 haftada bir',
  'dropdown.time.appointment.every7weeks': 'Her 7 haftada bir',
  'dropdown.time.appointment.every8weeks': 'Her 8 haftada bir',
  'dropdown.time.appointment.every9weeks': 'Her 9 haftada bir',
  'dropdown.time.appointment.every10weeks': 'Her 10 haftada bir',
  'dropdown.time.appointment.monthly': 'Aylık',
  'dropdown.time.appointment.every2months': 'Her 2 ayda bir',
  'dropdown.time.appointment.every3months': 'Her 3 ayda bir',
  'dropdown.time.appointment.every4months': 'Her 4 ayda bir',
  'dropdown.time.appointment.every5months': 'Her 5 ayda bir',
  'dropdown.time.appointment.every6months': 'Her 6 ayda bir',
  'dropdown.time.appointment.every7months': 'Her 7 ayda bir',
  'dropdown.time.appointment.every8months': 'Her 8 ayda bir',
  'dropdown.time.appointment.every9months': 'Her 9 ayda bir',
  'dropdown.time.appointment.every10months': 'Her 10 ayda bir',
  'dropdown.time.appointment.every11months': 'Her 11 ayda bir',
  'dropdown.time.appointment.after2times': '2 kez sonra',
  'dropdown.time.appointment.after3times': '3 kez sonra',
  'dropdown.time.appointment.after4times': '4 kez sonra',
  'dropdown.time.appointment.after5times': '5 kez sonra',
  'dropdown.time.appointment.after6times': '6 kez sonra',
  'dropdown.time.appointment.after7times': '7 kez sonra',
  'dropdown.time.appointment.after8times': '8 kez sonra',
  'dropdown.time.appointment.after9times': '9 kez sonra',
  'dropdown.time.appointment.after10times': '10 kez sonra',
  'dropdown.time.appointment.after11times': '11 kez sonra',
  'dropdown.time.appointment.after12times': '12 kez sonra',
  'dropdown.time.appointment.after13times': '13 kez sonra',
  'dropdown.time.appointment.after14times': '14 kez sonra',
  'dropdown.time.appointment.after15times': '15 kez sonra',
  'dropdown.time.appointment.after20times': '20 kere sonra',
  'dropdown.time.appointment.after25times': '25 kez sonra',
  'dropdown.time.appointment.after30times': '30 kez sonra',
  'dropdown.time.appointment.specificDate': 'Belirli tarih',
  'modal.createHours.title': '{0} saat oluştur',
  'modal.editHours.title': '{0} saat düzenleyin',
  'modal.deleteItem.confirm.0': 'Müşteriyi tamamen veritabanından',
  'modal.deleteItem.confirm.1': 'silmek',
  'modal.deleteItem.confirm.2': 'istediğinize emin misiniz?',
  'modal.entity.category': 'Kategori',
  'modal.entity.referal': 'Referans kaynağı',
  'modal.entity.resource': 'Kaynak',
  'modal.entity.location': 'Konum',
  'modal.deleteReminder.title': 'Hatırlatıcıyı Sil',
  'modal.deleteMessage.title': 'Mesajı Sil',
  'modal.deleteReminder.description': 'Bu Hatırlatıcıyı silmek istediğinizden emin misiniz ?',
  'modal.deleteMessage.description': 'Bu Mesajı silmek istediğinizden emin misiniz ?',
  'modal.deleteReminder.confirm': 'Evet, sil',
  'modal.deletePaymentMethod.title': 'Ödeme yöntemini sil',
  'modal.deletePaymentMethod.description': 'Bu ödeme yöntemi kalıcı olarak silinecektir',
  'closedDate.create.modal.title': 'Kapalı Tarih Ekle',
  'closedDate.edit.modal.title': 'Kapalı Tarih Düzenle',
  'staff.access.owner': 'Sahip',
  'staff.access.high': 'Yüksek',
  'staff.access.medium': 'Orta',
  'staff.access.low': 'Düşük',
  'staff.access.basic': 'Temel',
  'staff.access.no_access': 'Erişim Yok',
  'staff.create.title': 'Kapalı Tarih Ekle',
  'staff.emptyState.title': 'Planlanmış personel yok.',
  'staff.emptyState.description': 'Çalışma saatlerini eklemek için personel bölümüne bakın.\n',
  'staff.viewStaffMembers.button': 'Personel üyelerini görüntüle',
  'staff.picture.changePhoto': 'Fotoğrafı değiştir',
  'staff.picture.removePhoto': 'Fotoğrafı kaldır',
  'input.clientSearch.placeholder': 'İsim, e-posta veya cep telefonuyla arama',
  'input.blockedDate.label': 'Tarih',
  'appointment.notes.title': 'Randevu notları',
  'appointment.history.title': 'Randevu geçmişi',
  'appointment.history.by.title': ' tarafından {0}',
  'appointment.log.created.label': 'Oluşturan',
  'appointment.log.updated.label': 'Güncelleyen',
  'appointment.log.rescheduled.label': 'Yeniden planlayan',
  'appointment.log.cancelled.label': 'İptal eden',
  'appointment.log.changed.label': 'Durumu değiştiren',
  'appointment.notificationLog.rescheduling': 'Gönderilen bildirimi yeniden planlayın',
  'appointment.notificationLog.cancellation': 'İptal bildirimi gönderildi',
  'appointment.notificationLog.confirmation': 'Onay bildirimi şu tarihte gönderildi:',
  'appointment.notificationLog.reminder': 'Hatırlatma bildirimi gönderildi',
  'appointment.notificationLog.thankYou': 'Teşekkür bildirimi gönderildi',
  'appointment.notificationLog.lastVisit': 'Son ziyaret bildirimi şu tarihte gönderildi:',
  'state.cancelReason.title': 'İptal nedeni belirtin',
  'state.cancelReason.description':
    'İptal nedenleri, müşterilerinizin neden randevularına gelmediğini takip etmenizi kolaylaştırır.',
  'state.cancelReason.button': 'İptal nedeni ekleyin',
  'state.resources.title': 'Kaynaklarınızı yönetin',
  'state.resources.description':
    'Elinizdeki kaynakların farkında olursanız işler daha başarılı olacaktır. Oda, mobilya ve diğer ev aletlerinin kaydını tutun ve kesintisiz hizmet sunarken daha az sorunla karşılaşın.',
  'state.resources.button': 'Kaynak ekleyin',
  'state.referralSource.title': 'Yönlendirme Kaynaklarını Yönetin',
  'state.referralSource.description':
    'Yönlendirme kaynağı müşterinin nereden geldiğini gösterir. Bunun takibini yapın ve işinizin görünürlüğünü ve başarı şansını artırmak için bu bilgiyi kullanın.',
  'state.referralSource.button': 'Yönlendirme kaynağı ekleyin',
  'state.locations.title': 'Konumları ayarlayın',
  'state.locations.description':
    'Burada birden fazla satış yerlerini yönetebilir, her konum için personelinize farklı çalışma saatleri ayarlayabilir ve her biri için kişisel takvim oluşturabilirsiniz.',
  'state.staff.title': 'Personeli yönetin',
  'state.staff.description':
    'Çalışanlarınız için profiller oluşturun, böylece programlarını ekleyip düzenleyebilir, ayrıca randevuları ve çalışma saatleri kaydını tutabilirsiniz.',
  'state.messageLog.title': 'Mesajların kaydını tutun',
  'state.messageLog.description':
    'Mesajlar, müşterilerinize yaklaşan randevularını hatırlatır veya onları bilgilendirir. Buradan mesajları ve durumlarını takip edebileceksiniz.\u000bOtomatik bir mesaj yapılandırmak için, şuraya gidin',
  'state.messageLog.link': 'Bildirim Ayarları',
  'state.clients.title': 'Müşterilerinizi yönetin',
  'state.clients.description':
    'Müşterilerinizle ilgilenme, sorunsuz deneyim ve onlara ulaşabilmek için gerekli bilgilerin bir arada olduğu profil oluşturmakla başlar.',
  'state.clients.button': 'Müşteri ekleyin',
  'state.services.title': 'Grup ve hizmet ayarlayın',
  'state.services.description':
    'Sağladığınız benzer hizmetleri gruplandırabildiğiniz için gruplar oldukça önemlidir. Ayrıca, takvimin temiz ve kolayca erişebilmesini sağlar.',
  'state.services.button': 'Hizmet grubu ekleyin',
  'state.calendar.title': 'Görevlendirilmemiş Personel',
  'state.calendar.description': 'Personel çalışma saatlerini ayarlamak için, şuraya bakın',
  'state.calendar.button': 'Personel Bölümü',
  'state.closedDates.title': 'Kapalı Tarihler',
  'state.closedDates.description':
    'İş yerinizin tatiller, bakım veya diğer nedenlerle kapalı olduğu tarihleri listeleyin. Müşteriler bu tarihlerde rezervasyon gerçekleştiremeyecek.',
  'state.closedDates.button': 'Kapalı Tarihi ekle',
  'title.billingPlan': 'Plan ve Faturalandırma',
  'btn.upgrade': 'Yükseltme',
  'header.usage': 'KULLANIM',
  'header.dropdown.subscription.plan': '{0} Plan',
  'header.dropdown.subscription.staff': '({0} Personel üyeleri)',
  'header.dropdown.notifications.description': '{0} mesaj',
  'header.paymentDetails': 'Ödeme ayrıntılarını görüntüle',
  'title.billingPlan.description':
    'Hesaplarınızı yönetin Plan ve faturalandırma detayları, işlemlere bakabilir ve faturaları indirebilirisiniz.',
  'plan.free.title': 'Başlangıç',
  'plan.individual.title': 'Bireysel (Sadece 1 Personel)',
  'plan.business.title': 'İş',
  'btn.upgradeNow': 'Yükseltme',
  'btn.getStarted': 'Başlayın',
  'btn.currentPlan': 'Güncel Plan',
  'plan.free.option.0': '1 Konum',
  'plan.free.option.1': 'En fazla 5 Çalışan',
  'plan.free.option.2': 'Sınırsız Randevu',
  'plan.free.option.3': 'Sınırsız Müşteri',
  'plan.free.option.4': 'Sınırsız Çevrimiçi rezervasyon',
  'plan.free.option.5': 'E-posta Bildirimleri',
  'plan.premium.option.0': 'Sınırsız Konum',
  'plan.premium.option.1': 'Sınırsız Randevu',
  'plan.premium.option.2': 'Sınırsız Müşteri',
  'plan.premium.option.3': 'Sınırsız Çevrimiçi rezervasyon',
  'plan.premium.option.4': 'Tekrarlanan Randevular',
  'plan.premium.option.5': 'E-posta Bildirimleri',
  'plan.premium.option.6': 'SMS Bildirimleri* ',
  'plan.premium.option.7': 'Whatsapp bildirimleri*',
  'plan.premium.option.8': 'Sınırsız Analiz',
  'plan.premium.option.9': 'Sınırsız Rapor',
  'plan.premium.option.10': 'Programları yazdırma',
  'plan.premium.option.11': 'İstemci dışa aktarımı',
  'plan.premium.option.12': 'Öncelikli destek',
  'plan.premium.option.13':
    'Plandok markası e-postalardan kaldırıldı (ücretsiz e-postalar Plandok tarafından desteklenmektedir)',
  'plan.premium.costsInfo': '*SMS/WhatsApp ücretleri plana dahil değildir, ekstra ücret uygulanır.',
  'plan.premium.activeUntilDate': 'Kadar aktif {0}',
  'period.perMonth': 'aylık (KDV Hariç)',
  'plan.premium.description': 'Daha fazla personel eklendiğinde fiyat artacaktır.',
  'plan.premium.viewPricing': 'Fiyatlandırmayı görüntüle',
  'plan.premium.numberStuff': 'Mevcut personel sayınız: {0}',
  'premium.membership': 'Premium üyelik ücreti sahip olduğunuz personel sayısına göre değişiklik gösterebilir.',
  'premium.price.perMonth': 'Aylık prim fiyatı',
  'pricing.calculated': 'Fiyat şu şekilde hesaplanır:',
  'pricing.calculator': 'Fiyat hesaplayıcı',
  'pricing.numberStuff': 'Personel sayısı',
  'pricing.extra.member': '{0} her ekstra üye için',
  'modal.title.pricing': 'Fiyatlandırma',
  'plan.foreverFree': 'Sonsuza Kadar Özgür',
  'plan.individual.0': "Her şey Starter'da, artı:",
  'plan.individual.1': 'Özelleştirilmiş bildirimler',
  'plan.individual.2': 'Plandok markasını kaldırma',
  'plan.individual.option.3': 'Sınırsız SMS hatırlatıcı',
  'plan.individual.4': 'Öncelikli destek',
  'plan.business.0': "Bireysel'de olan her şey, artı:",
  'plan.business.1': 'Sınırsız konum',
  'plan.business.2': 'Sınırsız personel',
  'block.billingInfo.title': 'Faturalandırma Bilgileri',
  'block.carInfo.title': 'Kredi Kartı Bilgileri',
  'input.cardNumber.label': 'Kart numarası',
  'input.cardNumber.placeholder': '1234 5678 3456 2456',
  'input.expireDate.label': 'Son kullanma tarihi',
  'input.expireDate.placeholder': '05 / 21',
  'input.cvv.label': 'CVV',
  'input.cvv.placeholder': '123',
  'modal.confirmCancel.subscription': 'Abonbeliği iptal etmek istediğinizden emin misiniz?',
  'modal.confirmCancel.subscription.description': 'İptal edince, ücretsiz sürümü kullanacaksınz.',
  'btn.cancelSubscription.confirm': 'Evet, aboneliğimi iptal et.',
  'loading.processingSubscribtion': 'Ödemeniz işleniyor! Lütfen bekleyin...',
  'state.serviceGroup.description': 'Grupta hizmet bulunmuyor',
  'state.serviceGroup.btn': 'Hizmet ekleyin',
  'appointment.status.new': 'Yeni Randevu',
  'appointment.status.confirmed': 'Onaylandı',
  'appointment.status.notShow': 'Gelmeyen',
  'appointment.status.completed': 'Tamamlanan',
  'appointment.status.cancelled': 'İptal edilen',
  'billing.tab.plan': 'Planınız',
  'billing.tab.costs': 'Varsayılan fiyatlar',
  'billing.tab.billing': 'Faturalandırma bilgileri',
  'billing.tab.invoice': 'Faturalar',
  'plan.free': 'Ücretsiz',
  'plan.deactivated.title': 'Premium devre dışı bırakıldı',
  'plan.active.title': 'Etkinleştir',
  'payment.step.title': 'Ödeme',
  'payment.error.title': 'Ödemeyi Güncelle',
  'payment.payNow.title': 'Ödemek',
  'payment.dismiss.title': 'Azletmek',
  'payment.tab.title.sub': 'Abonelik',
  'payment.tab.subTitle': 'Premium plan',
  'payment.per.members': 'üye başına',
  'payment.tab.title.notification': 'Bildirimler',
  'payment.card.details': 'Kart detayları',
  'payment.success.message': 'Ödeme başarılı oldu',
  'payment.payWithCard.title': 'Kart ile ödeme',
  'payment.cardNumber.title': 'Kart Numarası',
  'payment.expirationDate.title': 'Son kullanma tarihi',
  'payment.digits.title': '(3 basamaklı)',
  'payment.amount': 'Ödeme miktarı:',
  'payment.btn.pay': 'Ödemek',
  'invoice.table.date': 'Tarih',
  'invoice.table.invoice': 'Fatura',
  'invoice.table.download': 'İndir',
  'invoice.table.status': 'Durum',
  'costs.billingPeriod.title': 'Bu fatura dönemi için tahmini maliyetler',
  'costs.billingPeriod.description':
    'Bu, bu fatura dönemindeki kullanımınız için geçerli bir maliyettir. Maliyetlerinizin dökümü aşağıda mevcuttur.',
  'costs.billingPeriod.additionalInfo':
    "Bildirim harcamalarınız 50 €'ya ulaştığında, otomatik olarak ücretlendirilecektir. Ancak miktar daha küçükse endişelenmeyin; aylık aboneliğinize uygun bir şekilde dahil edilecektir.",
  'costs.summary.title': 'Aydan bugüne özet',
  'costs.summary.description': 'Bu masraflar hesap bakiyenize dahil edilir',
  'costs.summary.detailedCharges': 'Ayrıntılı ücretler',
  'costs.summary.noData': 'Veri yok',
  'costs.summary.quantity': 'Miktar',
  'costs.summary.subtotal': 'Ara toplam',
  'costs.summary.taxes': 'Vergiler',
  'dropdown.time.hours': 'saat',
  'message.planUpgrade': 'Planınızı başarıyla yükselttiniz!',
  'costs.subscription': 'Abonelik',
  'btn.cancelSubscription': 'Aboneliği iptal edin',
  'btn.no': 'Hayır',
  'notification.success.update': 'Başarıyla güncellendi',
  'validation.password.match': 'Şifre ve onay şifresi eşleşmiyor',
  'blocked.time': 'Bloklanan Süre',
  'message.successReset.password':
    'Tamamlandı! Şifre sıfırlama e-postası gönderildi, lütfen gelen kutunuzu kontrol edin.',
  'title.newPassword': 'Yeni şifreyi girin',
  'title.newPassword.description': 'Yeni bir giriş şifresi oluşturarak hesabınıza güvenli bir şekilde erişin',
  'input.newPasswordRepeat.label': 'Yeni şifrenizi onaylayın',
  'btn.change.password': 'Şifremi değiştir',
  'text.havingTrouble': 'Sorun mu yaşıyorsunuz?',
  'link.resetPassword.again': 'Şifrenizi tekrar sıfırlayın',
  'message.successNew.password': 'Şifre başarıyla değiştirildi.',
  'title.repeatShifts': 'Tekrar eden vardiya',
  'text.confirmShift.update':
    'Haftalık olarak tekrarlanan bir vardiya düzenlediniz. Yaklaşan vardiyaları silmek, {0} devam eden {0} programın üzerine yazacaktır',
  'text.confirmShift.delete':
    'Haftalık olarak tekrarlanan bir vardiyayı siliyorsunuz. Yaklaşan vardiyaları silmek, {0} devam eden {0} programın üzerine yazacaktır',
  'btn.deleteUpcoming.shifts': 'Yaklaşan vardiyaları silin',
  'btn.deleteThis.shift': 'Sadece bu vardiyayı silin',
  'btn.updateUpcoming.shifts': 'Yaklaşan vardiyaları güncelleyin',
  'btn.updateThis.shift': 'Sadece bu vardiyayı güncelleyin',
  'text.permisionTab':
    'Her kullanıcı izin seviyesi için hangi bölümlerin erişilebilir olduğunu ayarlayın. Oturum açan tüm personel takvime erişebilirken, admin hesapların tam sistem erişimi bulunmaktadır.',
  'title.permission.locations': 'Konum',
  'title.permission.booking': 'Rezervasyon Ve Müşteriler',
  'title.permission.staff': 'Personel',
  'title.permission.setup': 'Kurulum',
  'title.permission.basic': 'Temel',
  'title.permission.low': 'Düşük',
  'title.permission.med': 'Orta',
  'title.permission.high': 'Yüksek',
  'title.permission.owner': 'Sahip',
  'title.allLocations.permission': 'Tüm Konumlara Erişim',
  'title.accessCalendar.permission': 'Kendi Takvimine Erişim',
  'title.accessOtherCalendar.permission': 'Diğer Personel Takvime Erişim',
  'title.canBook.permission': 'Rezervasyon Oluşturabilir',
  'title.contactInfo.permission': 'Müşteri iletişim bilgilerini görebilir',
  'title.clients.permission': 'Müşteriler',
  'title.messages.permission': 'Mesajlar',
  'title.services.permission': 'Hizmetler',
  'title.workingHours.permission': 'Çalışma Saatleri',
  'title.staffMembers.permission': 'Personel',
  'title.permissionLevels.permission': 'İzin Düzeyi',
  'title.accountSetup.permission': 'Hesap ayarları',
  'title.clientSettings.permission': 'Müşteri ayarları',
  'title.onlineBookings.permission': 'Çevrimiçi Rezervasyonlar',
  'title.analytics.permission': 'Analitik',
  'title.marketing.permission': 'Pazarlama',
  'notification.type.reminder': 'Hatırlatıcı',
  'notification.type.confirmation': 'Onay',
  'notification.type.online_booking_confirmation': 'Online rezervasyon onayı',
  'notification.type.rescheduling': 'Yeniden planla',
  'notification.type.cancellation': 'İptal',
  'notification.type.thank_you': 'Teşekkürler',
  'notification.type.last_visit': 'Son ziyaret',
  'notification.channel.sms': 'SMS',
  'notification.channel.email': 'E-posta',
  'notification.channel.whatsapp': 'WhatsApp',
  'notification.status.sent': 'Gönderildi',
  'notification.status.pending': 'Beklemede',
  'notification.status.failed': 'Başarısız olmak',
  'dropdown.time.days': 'gün',
  'title.appointment': 'randevu',
  'input.reminderBeforeLastVisit.label': 'Belirtilen gün sayısından sonra bildirim gönderilecek',
  'txt.with': 'ile',
  'short.hours': 's',
  'short.minutes': 'dk',
  'input.registrationNumber.label': 'Şirket numarası',
  'input.vatNumber.label': 'KDV bilgisi',
  'invoice.table.priceTaxIncl': 'Miktar',
  'error.wrongSenderId':
    "Gönderen ID'si rakam veya harf içermeli, 3 karakterden uzun olmalı ve 11 karakterden kısa olmalıdır",
  'sidebar.help': 'Yardım',
  'modal.help.title': 'Nasıl yardımcı olabiliriz?',
  'modal.help.liveChat.title': 'Canlı sohbet',
  'modal.help.liveChat.description': 'Herhangi bir sorunuz var mı? Hemen bize sorun',
  'modal.help.suggestFeature.title': 'Bir özellik önerin',
  'modal.help.suggestFeature.description':
    'Önerilerinizi paylaşın ve başkalarının önerilerini görün ve istediğiniz özellikler için oy verin',
  'modal.help.viewUpdates.title': 'Güncellemeleri görüntüle',
  'modal.help.viewUpdates.description': 'En yeni güncellemelerimizi ve iyileştirmelerimizi görüntüleyin',
  'warning.title.paidFeature': 'Ücretli Özellik',
  'warning.smsFeatureMissing':
    'SMS ayarlarına erişmek için planınızın güncellenmesi gerekir. Yükseltmek için ziyaret edin',
  'warning.link.billingAndPricing': 'Plan ve Faturalandırma',
  'warning.newPage.0': 'yükseltildi!',
  'warning.newPage.1': "Plandok'un eski sürümünü kullandıysanız, şifreniz artık geçerli değil, lütfen",
  'warning.newPage.2': 'yeni sistemden faydalanmak için',
  'warning.newPage.3': 'şifreyi sıfırlayın. Daha fazla bilgi için bize ulaşın',
  'warning.newPage.4': 'veya',
  'title.blockTime.select': 'Randevu için zaman seçin',
  'calendar.allResources': 'Tüm Kaynaklar',
  'appointment.edit.title': 'Randevuyu Düzenle',
  'appointment.create.title': 'Randevu Başarıyla Oluşturuldu.',
  'appointment.update.title': 'Randevu Başarıyla Güncellendi.',
  'select.notify.title': '{0} seçilen aralıkta çalışmıyor ancak yine de randevu alabilirsiniz.',
  'booking.notify.title': 'Seçilen süre rezerve edilir, ancak randevu oluşturabilirsiniz.',
  'textArea.characters.left': 'karakter kaldı',
  'textArea.characters.exceeded': 'limit aşıldı',
  'validation.characters.exceeded': 'Karakter sınırı aşıldı',
  'staff.menu.members': 'Personel',
  'staff.menu.dates': 'Sigara işgünü',
  'staff.menu.working': 'Personel Çalışma Saatleri',
  'staff.menu.permissions': 'Kullanıcı İzinleri',
  'staff.menu.members.description': 'Ekip üyesi ekleyin veya çıkarın ve kullanıcı erişim seviyesini kontrol edin',
  'staff.menu.dates.description': 'Yaklaşan iş yeri kapaı günleri ve tatilleri programla',
  'staff.menu.working.description': 'Personel çalışma programlarını ayarlayarak takvim müsaitliğini yönetin',
  'staff.menu.permissions.description': 'Hassas özellikler ve bilgiler için erişim izinleri ayarlayın',
  'message.successUpdateLocation': 'Tamamlandı! Konum profili başarıyla güncellendi.',
  'ob.title': 'Online Rezervasyon',
  'ob.overview.tab': 'Genel Bakış',
  'ob.massMessages.tab': 'Toplu mesajlar',
  'ob.campaigns.tab': 'Kampanyalar',
  'ob.profile.tab': 'Çevrimiçi profil',
  'ob.links.tab': 'Bağlantılar',
  'ob.settings.tab': 'Ayarlar',
  'ob.payments.tab': 'Ödemeler',
  'ob.status.online': 'Çevrimiçi',
  'ob.status.offline': 'Çevrimdışı',
  'ob.status.title': 'Durum',
  'ob.profile.step': '{0} / 4',
  'ob.profile.step.title': 'Profil ve Konum',
  'ob.profile.step.description':
    'İşletmenizi tanıtın ve müşterilerinize sunduğunuz hizmetleri anlatın. "Hakkımızda" kısmı müşterilerinizin ilk bakacağı kısımdır ve eğer yeterli bilgi yoksa, müşterilerin size ulaşabileceği bir irtibat numarası olduğundan emin olun! İşletme adresinizi vererek, hatta haritada konum göstererek müşterilerinize nerede hizmet verdiğinizi bildirim!',
  'ob.overview.placheholder.title': 'Rezervasyon oluşturmak hiç bu kadar kolay olmadı!',
  'ob.overview.placheholder.description':
    'Online iş profili oluşturun ve kendi siteniz dahil olmak üzere çeşitli sosyal medya platformlarında insanların <b>SİZİ</b> keşfetmesini sağlayın.',
  'ob.overview.start.now': 'Şimdi başlayın',
  'ob.links.direct.title': 'Doğrudan Bağlantı',
  'ob.links.facebook.title': 'Facebook Bağlantısı',
  'ob.links.instagram.title': 'Instagram Bağlantısı',
  'ob.links.direct.description':
    'Daha kolay hale getirin ve online rezervasyonlarınızı doğrudan bağlayın!\nKendi sitenizde veya ya da nerede isterseniz orada kullanabilirsiniz.\nLinkleri nasıl kullanacağınızı bilmiyorsanız, endişelenmeyin, sizin için buradayız!',
  'ob.links.facebook.description':
    "Daha fazla müşteri çekmek için bu bağlantıyı Facebook'ta paylaşın ve sizi bulmalarını ve rezervasyon yapmalarını kolaylaştırın.",
  'ob.links.instagram.description':
    'Daha kolay hale getirin ve online rezervasyonlarınızı doğrudan bağlayın!\nKendi sitenizde veya ya da nerede isterseniz orada kullanabilirsiniz.\nLinkleri nasıl kullanacağınızı bilmiyorsanız, endişelenmeyin, sizin için buradayız!',
  'ob.analytics.tab.description':
    'Trafiği ve kullanıcı davranışını analiz etmek için çevrimiçi rezervasyon sayfanıza Google Analytics ve Facebook Pixel ekleyin.',
  'ob.analytics.google.title': 'Google Analytics',
  'ob.analytics.facebook.title': 'Facebook Pikseli',
  'ob.analytics.google.description':
    'Google Analytics ile web sitenizin trafiğini ve kullanıcı davranışlarını izleyip analiz ederek web sitenizi nasıl geliştirebileceğinize dair içgörüler elde edebilirsiniz.Aktif',
  'ob.analytics.active': 'Aktif',
  'ob.analytics.inactive': 'Aktif değil',
  'ob.analytics.setUp.btn': 'Kurulum',
  'ob.analytics.google.description.1':
    'Google Analytics, web sitesi trafiğini ve kullanıcı davranışlarını izlemenize yardımcı olabilecek güçlü bir araçtır. Google Analytics ile web sitenize gelen ziyaretçi sayısını, nereden geldiklerini, hangi sayfaları ziyaret ettiklerini ve sitenizde ne kadar süre kaldıklarını takip edebilirsiniz.',
  'ob.analytics.google.description.2':
    'Ayrıca Google Analytics, hedefler belirlemenize ve konuşmaları izlemenize olanak tanıyarak web sitenizin ne kadar iyi performans gösterdiğini ve bunu nasıl geliştirebileceğinizi daha iyi anlamanızı sağlar.',
  'ob.analytics.google.trackingId': 'İzleme Kimliği',
  'ob.analytics.google.tooltip':
    'İzleme Kimliğinizi bulmak için önce Google Analytics hesabınıza giriş yapmalı, izlemek istediğiniz web sitesini veya uygulamayı seçmeli ve ardından "Yönetici" sekmesine gitmelisiniz. Buradan "İzleme Kodu "na tıklayın. İzleme kimliğiniz "UA-XXXX-Y" biçiminde görüntülenecektir.',
  'ob.analytics.google.account': 'Henüz bir Google Analytics hesabınız yok mu?',
  'ob.analytics.click': 'Tıklayın',
  'ob.analytics.here': 'burada',
  'ob.analytics.learn': 'nasıl oluşturulacağını öğrenmek için.',
  'ob.analytics.facebook.description.1':
    'Facebook Pixel, dönüşümler, sayfa görüntülemeleri ve diğer etkinlikler dahil olmak üzere web sitenizdeki kullanıcı davranışlarını izlemenize olanak tanıyan bir araçtır.',
  'ob.analytics.facebook.description.2':
    'Facebook Pixel, kitleniz hakkında demografik bilgiler, ilgi alanları, cihaz türü, sayfa görüntülemeleri ve gerçekleştirilen eylemler gibi değerli bilgiler toplayarak sitenizdeki kullanıcı deneyimini daha iyi anlamanıza ve optimize etmenize yardımcı olabilir.',
  'ob.analytics.facebook.pixelId': 'Piksel Kimliği',
  'ob.analytics.facebook.tooltip':
    "Facebook Piksel Kimliğinizi bulmak için aşağıdaki adımları izleyin: Facebook Reklam Yöneticisi hesabınıza gidin. Ekranın sol üst köşesindeki 'Menü' düğmesine tıklayın. 'Etkinlik Yöneticisi' bölümünün altında 'Pikseller'i seçin. Burada, hesabınızla ilişkili tüm piksellerin bir listesini görmelisiniz. Kimliğine ihtiyacınız olan pikselin adına tıklayın. Piksel Kimliğiniz ekranın sol üst köşesinde, piksel adının yanında görüntülenecektir.",
  'ob.analytics.facebook.account': 'Henüz bir Facebook işletme sayfanız yok mu?',
  'ob.analytics.google.account.mobile.1': "Henüz bir Google Analytics'iniz yok mu?",
  'ob.analytics.google.account.mobile.2': 'Hesap mı? Tıklayın',
  'ob.analytics.google.account.mobile.3': 'nasıl yapılacağını öğrenmek için',
  'ob.analytics.google.account.mobile.4': 'bir tane oluşturun.',
  'ob.analytics.facebook.account.mobile.1': "Zaten bir Facebook'unuz yok",
  'ob.analytics.facebook.account.mobile.2': 'işletme sayfası? Tıklayın',
  'ob.analytics.facebook.account.mobile.3': 'öğrenmek için',
  'ob.analytics.facebook.account.mobile.4': 'bir tane oluşturmak.',
  'ob.analytics.successfullySaved.message': 'Başarıyla kaydedildi',
  'ob.settings.booking.title': 'Online rezervasyon müsaitliği',
  'ob.settings.booking.description':
    'En erken ve en geç rezervasyonların ne zaman olacağını belirleyin. Rezervasyon zaman aralığını ayarlayın ve personel seçimi dahil olmak üzere önemli bilgileri müşterilerinize sağlayın.',
  'ob.settings.cancellation.title': 'İptal ve Yeniden Planlama',
  'ob.settings.cancellation.description':
    'Müşterilerin randevularını iptal edip yeniden planlayabileceği zaman dilimi belirleyin.',
  'ob.settings.locationsUrl.title': "Konum URL'si",
  'ob.settings.locationsUrl.description':
    'Tüm online rezervasyon yapılabilir alanlar için tek bir URL belirleyin ve paylaşın. Bu sadece birden fazla konumda olan işletmeler için kullanışlıdır.',
  'ob.settings.businessName.explanation': "URL'niz: book.plandok.com/business-name/Location2556",
  'ob.settings.notifications.title': 'Bildirim E-Postaları',
  'ob.settings.notifications.description':
    'Yeni randevulara veya yapılan değişikliklere ilişkin bildirim e-postası alıcılarını belirleyin.',
  'ob.settings.required.title': 'Zorunlu ve isteğe bağlı alanlar',
  'ob.settings.required.description':
    'Çevrimiçi rezervasyon yaparken müşterinin hangi alanları sağlaması gerekeceğini seçin.',
  'ob.settings.requiredField.title': 'Gerekli',
  'ob.settings.optionalField.title': 'İsteğe bağlı',
  'ob.settings.requiredField.notification.title': 'Ayarlarla ilgili bir sorun vardı',
  'ob.settings.requiredField.notification.description':
    'Üzgünüz, ancak "notlar" dışında bir alanın zorunlu olması gerekiyor.',
  'ob.settings.nameField.title': 'İlk İsim',
  'ob.settings.lastNameField.title': 'Soy isim',
  'ob.settings.phoneField.title': 'Telefon Numarası',
  'ob.settings.emailField.title': 'E-posta',
  'ob.settings.notes.label': 'Notlar için yer tutucu metin',
  'ob.settings.notes.placeholder': 'Örn. Notlarınızı girin',
  'ob.bookingLimit.title': 'Online rezervasyon limiti',
  'ob.bookingLimit.description': 'Lütfen ayarlamak istediğiniz maksimum hizmet rezervasyonu sayısını belirtin.',
  'ob.bookingLimit.label': 'Hizmet rezervasyonlarını sınırlama',
  'ob.bookingLimit.unlimited.title': 'Sınırsız',
  'ob.bookingLimit.1service.title': 'maks. 1 Servis',
  'ob.bookingLimit.2services.title': 'maks. 2 Servis',
  'ob.bookingLimit.3services.title': 'maks. 3 Servis',
  'ob.menu.edit.profile': 'Profili Düzenle',
  'ob.menu.turnOff.profile': 'Profili Kapatın',
  'ob.profile.status': 'Durum',
  'ob.overview.tab.description': 'Müşterilerinize online rezervasyon imkanı sunarak işletmenizi büyütün.',
  'ob.profile.tab.description':
    'Konumlarınız için online profilleri yönetin. Resimleri, tanımları, adresleri ve daha fazlasını ayarlayın.',
  'ob.links.tab.description': 'Müşterilerinizle paylaşabileceğiniz online rezervasyon adresini burada bulabilirsiniz.',
  'ob.settings.tab.description':
    'Online rezervasyon ayarlarını buradan ayarlayın böylece, müşterileriniz sadece size uygun olduğunda rezervasyon yapabilir.',
  'ob.payments.tab.description':
    'Müşterilerinizin hizmetler için ödeme yaparken kullanabilecekleri ödeme yöntemlerini seçin.',
  'ob.payments.page.description':
    'Burada, müşterilerinizin hizmetler için ödeme yaparken hangi ödeme yöntemlerini kullanabileceklerini seçebilirsiniz.',
  'ob.payOnSite.title': 'Site üzerinden ödeme',
  'ob.payOnSite.description': 'Müşterileriniz yerinde nakit ödeme yapabilecektir.',
  'ob.payWithCard.title': 'Kart ile Ödeme',
  'ob.payWithCard.description': 'Müşterileriniz Stripe uygulaması üzerinden kredi kartı ile ödeme yapabilecekler.',
  'ob.payWithCard.info.1': 'Ücretsiz Plandok planı kullanıyorsunuz, bu yüzden ödeme yapmanız gerekecek',
  'ob.payWithCard.info.2': 'Bu ödeme yöntemini kullanarak Plandok komisyon ücreti ve Stripe komisyon ücreti.',
  'ob.payWithCard.info.3': ' sahip olmak için',
  'ob.payWithCard.info.4': 'Plandok komisyon ücretleri.',
  'ob.payWithCard.info.5': 'Premium plan, Stripe komisyon ücretlerini ödemekten muaf değildir. ',
  'ob.payWithCard.info.6': 'Stripe fiyatlarını buradan kontrol edin',
  'ob.stripeConnected.info': '(Stripe bağlı)',
  'ob.requiresSetup.info': '(Stripe hesabınızı kurmanız gerekir)',
  'ob.connectToStripe.btn': "Stripe'a Bağlanın",
  'ob.connectToStripe.successMessage.title': "Stripe'a başarıyla bağlandı",
  'ob.waitingAuthorization.title': "Stripe'ta yetkilendirmeniz bekleniyor",
  'ob.stripeDashboard.title': 'Tüm ödeme kayıtları ve geri ödemeler şu adreste mevcuttur ',
  'ob.stripeDashboard.url': 'Stripe gösterge paneli',
  'ob.yourStripeAccount.title': 'Stripe hesabınız',
  'ob.visitStripe.option': "Stripe'ı ziyaret edin",
  'ob.changeAccount.option': 'Stripe hesabını değiştir',
  'input.earliestAppointment.label': 'En erken randevu',
  'input.earliestAppointment.placeholder': 'Randevudan hemen önce',
  'input.latestAppointment.label': 'En geç randevu',
  'input.latestAppointment.placeholder': '6 ay öncesinden uzun olamaz',
  'input.timeSlots.label': 'Zaman aralığı aralıkları',
  'input.timeSlots.placeholder': '15 dakika',
  'checkbox.allowCustomers': 'Müşterilerin randevuları için personel seçmelerine izin verin',
  'input.importantInfo.label': 'Önemli bilgiler',
  'input.importantInfo.placeholder': 'Müşterilere randevularını onaylarken gösterilecek bilgiler',
  'input.changeAppointment.label': 'Randevulara yapılabilecek değişiklikler',
  'input.changeAppointment.placeholder': 'Her zaman',
  'checkbox.sendToBooked': 'Rezervasyon yapılan personele gönderin',
  'checkbox.sendSpecificEmail': 'Belirli bir e-posta adresine gönderin',
  'input.specificEmail.label': 'Belirli e-posta adresi',
  'input.specificEmail.placeholder': 'E-Posta adresinizi girin',
  'input.locationsUrl.label': "Konum URL'si",
  'input.locationsUrl.placeholder': "Örn. Ali'nin-Berber-Dükkanı",
  'ob.btn.save': 'Kaydet',
  'ob.btn.cancel': 'İptal',
  'ob.btn.showLink': 'Bağlantıyı Göster',
  'ob.btn.active': 'Aktif',
  'ob.btn.inactive': 'Aktif değil',
  'ob.linkModal.title': 'Rezervasyon için doğrudan bağlantı',
  'ob.linkModal.description': 'Online rezervasyon hizmetlerine doğrudan erişilebilecek bağlantı burada!',
  'ob.linkModal.afterDescription':
    'Bu bağlantı müşterileri doğrudan hizmetlerinize yönlendirir. Bir tıklama ile müşteriler sizin iş profilinize ulaşabilir ve rezervasyon yapabilir.\nBilgisayar, telefon veya tablet üzerinden bu bağlantı kullanması kolay ve kullanıcı dostudur.',
  'ob.fbLinkModal.title': 'Facebook Sayfası Şimdi Rezervasyon Yap',
  'ob.fbLinkModal.description':
    'Doğrudan Facebook üzerinden zahmetsizce rezervasyonları almak için Facebook işletme sayfanıza entegre Şimdi Rezervasyon Yap butonu yerleştirin.',
  'ob.fbLinkModal.step1': "Facebook'ta işletme sayfanıza giriş yapın",
  'ob.fbLinkModal.step2': 'Kapak fotoğrafı yanındaki "Düğme ekle"ye tıklayın',
  'ob.fbLinkModal.step3': '"Giriş Yap"a tıklayın ve aşağıdaki bağlantıyı girin',
  'ob.linkModalCopySuccess.title': 'Bağlantı kopyalandı!',
  'ob.btn.copyLink': 'Bağlantıyı kopyalayın',
  'title.appointmentSlot.select': 'Randevu için zaman seçin',
  'btn.back': 'Geri',
  'btn.continue': 'Devam et',
  'ob.hours.open': 'Aç',
  'ob.hours.close': 'Kapat',
  'ob.hours.step.title': 'Çalışma Saatleri',
  'ob.hours.step.description': 'İşletmenizin ne zaman açılıp, ne zaman kapandığını müşterilerinize belirtin.',
  'ob.staffHours.step.title': 'Personel Çalışma Saatleri',
  'ob.staffHours.step.description':
    'Önemli bir adım, müşterilerin ne zaman çevrimiçi rezervasyon yapabileceğini bilmek için sistem için çalışma saatleri ekleyin.',
  'ob.location.step.title': 'Konum Bilgisi',
  'ob.photo.step.title': 'Fotoğraflar',
  'ob.photo.step.description': 'Müşterilerinize işletmenizin nasıl göründüğünü gösterin.',
  'input.file.dnd.photos': 'Fotoğrafları sürükleyip ve bırakın',
  'btn.publish': 'Aç',
  'text.next': 'Sonraki',
  'input.phone.number.label': 'Telefon Numarası',
  'input.state.label': 'Şehir',
  'input.state.placeholder': 'Şehir girin',
  'input.addressDetails.label': 'Adres detayları',
  'input.addressDetails.placeholder': 'Kapı numarası, giriş, park bilgisi gibi adres detaylarını girin.',
  'placeholder.no.photos': 'Fotoğraf yok',
  'btn.options': 'Seçenekler',
  'btn.list.profile': 'Olanak vermek',
  'btn.preview': 'Ön izleme',
  'btn.next': 'Sonraki',
  'market.ob.pleaseWait.title': 'Lütfen bekleyin ...',
  'market.ob.location.step.title': 'Konum seçin',
  'market.ob.services.step.title': 'Hizmet seçin',
  'market.ob.employee.step.title': 'Tarih ve personel seçin',
  'market.ob.timeSlot.step.title': 'Tarih ve saati seçin',
  'market.ob.personalInfo.step.title': 'Ödeme',
  'market.ob.appointmentInfo.step.title': 'Randevu bilgisi',
  'market.ob.informationAboutYou.title': 'Sizin hakkınızda bilgi',
  'market.ob.paymentMethod.title': 'Ödeme yöntemi',
  'market.ob.securePayment.title': 'Güvenli Ödeme',
  'market.ob.payWithCard.title': 'Kart ile ödeme',
  'market.ob.payOnSite.title': 'Yerinde ödeme',
  'market.ob.payOnSite.firstDescription': 'İşlemden sonra salonda ödeme yapmanız gerekecektir.',
  'market.ob.payOnSite.secondDescription':
    'Bizden küçük bir talep: işletmeler genellikle küçük bağımsız işletmelerdir, bu nedenle planlanan randevunuza giremezseniz, yeniden planlayın veya iptal edin.',
  'market.ob.validThru.title': 'Geçerlilik tarihi (Ay/yıl)',
  'market.ob.information.title': 'Bilgi',
  'market.ob.additionalInformation.title': 'Ek bilgi',
  'market.ob.reschedulingPolicy.title': 'İptal/Yeniden Planlama politikası',
  'market.ob.reschedulingPolicy.description': 'Randevu değişiklikleri randevu saatine {0} kala yapılabilir.',
  'market.ob.fieldsAreRequired.title': '* Alanlar zorunludur',
  'market.ob.step.title': 'Adım {0} 3 üzerinden',
  'market.ob.confirm.step.title': 'Görüntüleyin ve onaylayın',
  'market.ob.noServices.placeholder': 'Hiç bir hizmet henüz seçilmedi',
  'market.ob.input.firstName.label': '{0} İsim',
  'market.ob.input.firstName.placeholder': 'e.g. Ali',
  'market.ob.input.lastName.label': '{0} Soyisim',
  'market.ob.input.lastName.placeholder': 'e.g. Alkis',
  'market.ob.input.phone.label': '{0} Cep telefonu numarası',
  'market.ob.input.phone.placeholder': '670 70 822',
  'market.ob.input.email.label': '{0} E-posta adresi',
  'market.ob.input.email.placeholder': 'ali.alkis@gmail.com',
  'market.ob.input.description.label': 'Notlar',
  'market.ob.staff.assignedSpecialist.label': 'Personel ile',
  'market.ob.staff.noPreference.label': 'Tercih yok',
  'market.ob.timeSlots.fullyBooked.title': 'Üzgünüz, bu gün için uygun zamanımız yok.',
  'market.ob.timeSlots.choseAnotherDay.title': 'Lütfen başka bir gün seçin.',
  'market.ob.timeSlots.closedSlot.title': 'en yakındaki uygun zamanı',
  'market.ob.timeSlots.selectClosest.title': 'En yakın tarihi seçin',
  'market.ob.timeSlots.note.title': 'Önemli bilgiler',
  'market.ob.service.duration.title': 'min',
  'market.location.workingHours.title': 'Çalışma saatleri',
  'market.location.monday.title': 'Pazartesi',
  'market.location.tuesday.title': 'Salı',
  'market.location.wednesday.title': 'Çarşamba',
  'market.location.thursday.title': 'Perşembe',
  'market.location.friday.title': 'Cuma',
  'market.location.saturday.title': 'Cumartesi',
  'market.location.sunday.title': 'Pazar',
  'market.location.meetOurTeam.title': 'Ekibimizle tanışın',
  'market.location.title': 'Iletişime geçin',
  'market.location.services.title': 'Hizmetler',
  'market.location.about.title': 'Hakkında',
  'market.location.aboutUs.title': 'Hakkımızda',
  'market.confirmation.appointmentCanceled.title': 'Bu randevu iptal edildi',
  'market.confirmation.appointmentConfirmed.title': 'Randevu onaylandı',
  'market.confirmation.appointmentConfirmed.description': 'Randevunuz onaylandı, işte ayrıntılar:',
  'market.confirmation.appointmentDate.title': 'Randevu tarihi',
  'market.confirmation.scheduledAppointment.title': 'Online iptal mümkün değildir.',
  'market.confirmation.scheduledAppointment.description':
    'Planladığınız randevuya gelemeyecekseniz, lütfen yeniden planlayın veya iptal edin.',
  'market.modal.cancelAppointment.title': 'Bu randevuyu iptal etmek istediğinizden emin misiniz ?',
  'market.modal.cancelledAppointment.title': 'Randevu iptal edildi',
  'market.language.changer.title': 'Dil (Language)',
  'market.btn.bookNow': 'Randevu Al',
  'market.servicesAvailable.title': 'hizmetler',
  'market.poweredByPlandok.title': 'Plandok',
  'market.poweredByPlandok.description': 'Benzer bir web sitesi ister misiniz ? Ziyaret edin',
  'market.btn.readMore': 'Daha fazla bilgi edinin',
  'market.btn.showLess': 'Daha az göster',
  'market.btn.reviews': 'Yorumlar',
  'market.btn.viewMore': 'Daha fazla görüntüle',
  'market.btn.cancelAppointment': 'Randevu iptal',
  'market.btn.cancel': 'Evet, iptal et',
  'market.wh.closed.title': 'Kapalı',
  'market.ob.success.message': 'Bitti! Rezervasyon başarıyla oluşturuldu',
  'market.ob.cancelAppointment.success.message': 'Randevu başarıyla iptal edildi',
  'market.ob.cancelAppointment.error.message': 'Randevu iptali başarısız',
  'market.ob.cancelAppointment.confirm.title': 'Randevunuzu iptal etmek istediğinize emin misiniz?',
  'market.location.getDirections.title': 'Yol Tarifi Al',
  'market.notFound.title': 'Sayfa bulunamadı',
  'market.notFound.btn.goBack': 'Geri dön',
  'market.ob.quantitativeLimit.title': 'Niceliksel sınır',
  'market.ob.quantitativeLimit.description':
    'Üzgünüz, tesis sahibi rezervasyon hizmetlerinin sayısına bir sınırlama getirerek yalnızca {0} hizme{0} seçimine izin vermiştir',
  'market.ob.quantitativeLimit.three': 'üç',
  'market.ob.quantitativeLimit.two': 'iki',
  'market.ob.quantitativeLimit.one': 'bir',
  'market.ob.quantitativeLimit.serviceSingular': 't',
  'market.ob.quantitativeLimit.servicesPlural': 'tler',
  'validation.characters.minLength': 'Bu değer çok kısa. 3 karakter veya daha fazla olmalı.',
  'not.found.btn.label': 'Eve dön',
  'not.found.subTitle.label': 'Maalesef, ziyaret sayfa mevcut değil.',
  'analytics.title': 'Analitik',
  'analytics.dashboard.tab': 'Gösterge Paneli',
  'analytics.reports.tab': 'Raporlar',
  'analytics.premium.title': 'Ödül',
  'analytics.totalAppointments.title': 'Toplam atamalar',
  'analytics.cancellationReasons.title': 'En nedenleri iptal',
  'analytics.occupancy.title': 'işgal',
  'analytics.topServices.title': 'En hizmetler',
  'analytics.clients.title': 'Müşteriler',
  'analytics.completed.title': 'Tamamlandı',
  'analytics.notCompleted.title': 'Tamamlanmamıs',
  'analytics.canceled.title': 'İptal edildi',
  'analytics.noShowAppointments.title': 'Hiçbir gösteri randevuları',
  'analytics.reasonName.title': 'Nedeni adı',
  'analytics.reasonCount.title': 'Miktar',
  'analytics.lastPeriod.title': 'Son dönem',
  'analytics.workingHours.title': 'Çalışma saatleri',
  'analytics.bookedHours.title': 'Rezervasyon Saatler',
  'analytics.blockedHours.title': 'Engellenen Saatler',
  'analytics.unbookedHours.title': 'Ayrılmamış Saatler',
  'analytics.service.title': 'Hizmetler',
  'analytics.thisMonth.title': 'Bu periyot',
  'analytics.lastMonth.title': 'Son dönem',
  'analytics.totalClients.title': 'Toplam istemciler',
  'analytics.averageVisits.title': 'Müşteri başına ortalama ziyaret',
  'analytics.newClients.title': 'Yeni müşteriler',
  'analytics.returningClients.title': 'müşterilerine dönersek',
  'analytics.returnRate.title': 'Müşteri dönüş hızı',
  'analytics.clientsByGender.title': 'Cinsiyete göre Müşteriler',
  'analytics.clientsBySource.title': 'Referans kaynak tarafından Müşteriler',
  'analytics.clientsByAge.title': 'Yaşa göre Müşteriler',
  'analytics.timeRange.custom': 'görenek',
  'analytics.timeRange.today': 'Bugün',
  'analytics.timeRange.yesterday': 'Dün',
  'analytics.timeRange.last1day': 'Son 1 gün',
  'analytics.timeRange.last7days': 'Son 7 gün',
  'analytics.timeRange.last30days': 'Son 30 gün',
  'analytics.timeRange.last90days': 'Son 90 gün',
  'analytics.timeRange.last3months': 'Son 3 ay',
  'analytics.timeRange.lastMonth': 'Geçen ay',
  'analytics.timeRange.last6Months': 'Son 6 ay',
  'analytics.timeRange.lastYear': 'Geçen yıl',
  'analytics.timeRange.last2year': 'Son 2 yıl',
  'analytics.timeRange.thisWeek': 'Bu hafta',
  'analytics.timeRange.thisMonth': 'Bu ay',
  'analytics.timeRange.thisYear': 'Bu yıl',
  'analytics.timeRange.tomorrow': 'yarın',
  'analytics.timeRange.next7days': 'Önümüzdeki 7 gün',
  'analytics.timeRange.next30days': 'Önümüzdeki 30 gün',
  'analytics.menu.dashboard.description': 'İş verilerinizi grafiklerde ve istatistiklerde keşfedin',
  'analytics.menu.reports.description': 'Raporlarda iş verilerinizi keşfedin',
  'analytics.timeRange.allTime': 'Her zaman',
  'analytics.timeRange.label': 'Zaman aralığı aralıkları',
  'analytics.services.lastPeriod.label': 'Son dönem',
  'analytics.totalAppointments.appointment.label': 'Randevular',
  'analytics.totalAppointments.onlineBook.label': 'Online rezervasyonlar',
  'analytics.gender.male': 'Erkek',
  'analytics.gender.female': 'Kadın',
  'analytics.gender.unknown': 'Bilinmeyen',
  'analytics.noData': 'Seçilen dönemde veri yok',
  'analytics.premium.info.title': '7 günden fazla analizlerini görüntülemek için prim sürümüne yükseltin.',
  'analytics.premium.upgradeButton.title': 'ETKINLEŞTIRME',
  'analytics.premium.skipButton.title': 'Atla',
  'reports.appointments.title': 'Randevular',
  'reports.appointments.description':
    'Yaklaşan randevuların tahmini gelirlerini görüntüleyin, iptal oranlarını ve nedenlerini takip edin.',
  'reports.appointmentsList.title': 'Randevu listesi',
  'reports.appointmentsList.description': 'Tüm randevuları ilgili bilgilerle birlikte görüntüler.',
  'reports.appointmentsSummary.title': 'Randevu özeti',
  'reports.appointmentsSummary.description': 'Randevuları Personel ve Hizmete göre görüntüler.',
  'reports.appointmentsCancellations.title': 'Randevu iptalleri',
  'reports.appointmentCancellations.title': 'Randevu İptalleri',
  'reports.appointmentsCancellations.description':
    'Seçtiğiniz döneme göre iptal edilen tüm randevu nedenlerini ve adetlerini görüntüler.',
  'reports.summary.title': 'Özet',
  'reports.clients.title': 'Müşteriler',
  'reports.clients.description':
    'Müşterilerin işletmenizle nasıl etkileşim kurduğu ve en çok harcama yapanların kimler olduğu hakkında bilgi edinin',
  'reports.clientsList.title': 'Müşteriler',
  'reports.clientsList.description': 'Müşterilerin verileriyle birlikte listesi',
  'reports.exportCsv.title': "CSV'yi dışa aktar",
  'reports.appointmentsByStaff.title': 'Personele Göre Randevular',
  'reports.appointmentsByService.title': 'Hizmete Göre Randevular',
  'reports.clearFilters.button.title': 'Filtreleri temizle',
  'reports.filterStaff.placeholder': 'Tüm Personel',
  'reports.filterLocations.placeholder': 'Tüm Konumlar',
  'reports.filters.title': 'Filtreler',
  'reports.locations.mobile.title': 'Konumlar:',
  'reports.staff.mobile.title': 'Kadro:',
  'reports.filters.apply.title': 'Uygula',
  'reports.search.placeholder': 'Referansa veya müşteriye göre arayın',
  'reports.subscription.description': '{0} plan (sadece {0} personel)',
  'reports.notifications.description': '{0} mesaj ({0})',
  'serviceGroup.addCategory.description':
    'Benzer hizmetleri gruplandırmanıza izin verdikleri için kategoriler önemlidir.',
  'serviceGroup.addCategory.title': 'Kategori Ekle',
  'app.install.title': "Plandok'u yükleyin",
  'app.install.description.title':
    'Yükleme neredeyse hiç depolama alanı kullanmaz ve bu uygulamaya geri dönmenin hızlı bir yolunu sağlar',
  'app.install.button.title': 'Düzenlemek',
  'app.skip.button.title': 'Atlamak',
  'app.actionUndone.button.title': 'Eylem geri alındı',
  'app.appointmentCancelled.button.title': 'Randevu silindi',
  'app.undo.button.title': 'Geri al',
  'app.showAllTimes.button.title': 'Tüm zamanları göster',
  'app.install.success.title': 'Uygulama başarıyla yüklendi!',
  'rp.upgradeToPremiumLink.title': "Premium'a Yükselt",
  'rp.upgradeToPremium.title': 'bu özelliği kullanmak için',
  'rp.repeatAppointment.title': 'Tekrarlamak',
  'rp.input.frequency.title': 'Sıklık',
  'rp.input.endsAfter.title': 'Bitiş',
  'rp.input.selectDate.title': 'Tarih seçin',
  'rp.upgrade.title': 'Planınızı yükseltin',
  'rp.upgrade.description.title':
    "Plandok'un premium sürümü aktif değil. Plandok tarafından sağlanan tüm özelliklere tam olarak erişmek için planınızı yükseltin.",
  'rp.upgrade.button.title': "Premium'a yükseltme",
  'rp.repeating.title': 'Tekrarlanıyor',
  'rp.repeats.title': 'Tekrarlar',
  'rp.repeatsUntil.title': 'değin',
  'rp.repeatingTimes.title': '({0} kez)',
  'rp.cancel.upcoming.title': 'Yaklaşan tüm tekrarlanan randevuları sil',
  'rp.update.upcoming.title': 'Randevu tüm tekrar randevularını silin',
  'rp.edit.appointment.title': 'Randevuyu Güncelle',
  'rp.edit.appointment.description.title':
    'Tekrarlanan bir randevuyu düzenliyorsunuz. Hangi randevuların güncelleneceğini seçin:',
  'rp.edit.thisAppointment.title': 'Yalnızca bu randevuyu güncelle',
  'rp.edit.allAppointments.title': 'Yaklaşan tüm randevuları güncelle',
  'rp.edit.saveAppointment.title': 'Kaydetmek',
  'modal.cancelSubscriptionError.title': 'Abonelik iptal hatası',
  'modal.cancelSubscriptionError.text.1': 'Ücretsiz plan yalnızca maksimum 5 kişi ile kullanılabilir',
  'modal.cancelSubscriptionError.text.2': 'çalışanlar',
  'modal.cancelSubscriptionError.text.3': 've 1',
  'modal.cancelSubscriptionError.text.4':
    'Ücretsiz sürümü kullanmak için, gereksinimleri karşılamak üzere konumları / personeli kaldırın.',
  'modal.latestPaymentFailed.title': 'Son ödeme başarısız oldu',
  'modal.latestPaymentFailed.text.1':
    'Tüm özelliklere erişmek için lütfen ödeme yapın veya ücretsiz sürümü kullanmak için gereksinimleri karşılamak üzere',
  'modal.latestPaymentFailed.text.2':
    'kaldırın. Ücretsiz plan yalnızca maksimum 5 çalışan ve 1 konum ile kullanılabilir.',
  'modal.extraCostsForNewMember.title': 'Yeni üye için ekstra maliyetler',
  'modal.extraCostsForNewMember.description':
    'Personel sayısı arttıkça aylık aboneliğinizde ekstra maliyetler olacaktır.',
  'modal.premiumUpgradeRequired.title': 'Premium yükseltme gerekli',
  'modal.premiumUpgradeRequired.description': "Ek {0} eklemek için planınızın Premium'a yükseltilmesi gerekir",
  'modal.premiumUpgradeRequired.location': 'konum',
  'modal.premiumUpgradeRequired.staffMembers': 'personel üyeleri',
  'modal.changeAccount.title': 'Stripe hesabını mı değiştiriyorsunuz?',
  'modal.changeAccount.description':
    'Hesap değiştirirken ödeme kabul edemezsiniz. Değişikliği yapmak yaklaşık bir dakika sürecektir.',
  'btn.agree': 'Katılıyorum',
  'btn.decline': 'Düşüş',
  'paymentMethod.paymentMethod.title': 'Ödeme yöntemi',
  'paymentMethod.currentPaymentMethod.title': 'Güncel ödeme yöntemi',
  'paymentMethod.expirationDate': 'Son kullanma tarihi {0}',
  'paymentMethod.otherPaymentMethods.title': 'Diğer ödeme yöntemleri',
  'paymentMethod.addAnotherPaymentMethod.btn': 'Başka bir ödeme yöntemi ekleyin',
  'paymentMethod.addPaymentMethod.title': 'Ödeme yöntemi ekle',
  'paymentMethod.noPaymentMethodAdded.title': 'Ödeme yöntemi eklenmedi',
  'paymentMethod.noPaymentMethodAdded.description': 'Premium plan seçildiğinde ödeme yöntemi eklenecektir',
  'paymentMethod.goToPlanPage.btn': 'Plan sayfasına git',
  'paymentMethod.add.btn': 'Ekle',
  'paymentMethod.makeDefault.btn': 'Varsayılan yap',
  'paymentMethod.cardHasBeenAdded.message': 'Kart eklendi',
  'paymentMethod.cardHasBeenAssign.message': 'Kart atandı',
  'page.marketing.overview.messages.description':
    'Birkaç basit tıklama ile müşterilerinize SMS veya E-posta yoluyla özel pazarlama mesajları gönderin',
  'page.marketing.overview.btn.sendMessage': 'Mesaj gönder',
  'page.marketing.overview.campaigns.title': 'Otomatik gönderim kampanyaları',
  'page.marketing.overview.campaigns.description':
    'Müşterilerin doğum günleri ve daha fazlası gibi etkinliklere dayalı kişiselleştirilmiş kampanyalar oluşturun. \nKampanyaları e-posta ve kısa mesaj yoluyla iletmek için birden fazla kanaldan gönderin',
  'page.marketing.overview.btn.newCampaign': 'Yeni Kampanya',
  'page.marketing.massMessages.title': 'Yeni mesaj oluşturun',
  'page.marketing.massMessages.messageLog': 'Mesaj günlüğü',
  'page.marketing.massMessages.sendOn': 'Gönder',
  'page.marketing.massMessages.sendTo': 'Gönder',
  'page.marketing.massMessages.channel': 'Kanal',
  'page.marketing.campaigns.description':
    'Birkaç basit tıklama ile müşterilerinize SMS veya E-posta yoluyla otomatik pazarlama mesajları gönderin',
  'page.marketing.campaigns.birthdaySpecial.title': 'Doğum Gününe Özel',
  'page.marketing.campaigns.winBackClients.title': 'Müşterileri Geri Kazanın',
  'page.marketing.campaigns.welcomeNewClients.title': 'Yeni Müşterilere Hoş Geldiniz',
  'page.marketing.campaigns.rewardRegularClients.title': 'Düzenli Müşterileri Ödüllendirin',
  'page.marketing.campaigns.birthdaySpecial.description':
    'Müşterileri özel günlerinde şaşırtın, bu kampanya müşterilere doğum günlerine yakın otomatik olarak gönderilir',
  'page.marketing.campaigns.winBackClients.description':
    'Bağlantısı kopmuş müşterileri özel bir teklifle geri dönmeleri için hedefleyin. Bu kampanya, belirli bir süre sonra geri dönmeyen müşterilere gönderilir',
  'page.marketing.campaigns.welcomeNewClients.description':
    'İlk kez gelen müşterileri özel bir indirimle tekrar rezervasyon yapmaya teşvik ederek yeni gelenleri müdavimlere dönüştürün. Bu kampanya, müşterilere ilk satışlarından bir gün sonra otomatik olarak gönderilir',
  'page.marketing.campaigns.rewardRegularClients.description':
    'En iyi müşterilerinizi özel bir teklifle şaşırtın, en iyi müşterilerinizin daha da fazla katılımını sağlamanın kesin bir yolu. Bu kampanya, son randevu etkinliğine göre müşterilere otomatik olarak gönderilir',
  'page.marketing.campaigns.btn.active': 'Aktif',
  'page.marketing.campaigns.btn.inactive': 'Aktif değil',
  'page.marketing.campaigns.btn.startCampaign': 'Kampanya Başlat',
  'page.marketing.campaigns.btn.editCampaign': 'Kampanyayı Düzenle',
  'page.marketing.campaigns.btn.resetCampaign': 'Sıfırlama Kampanyası',
  'modal.resetCampaign.description': 'Bu Kampanyayı sıfırlamak istediğinizden emin misiniz ?',
  'modal.resetCampaign.btn.yesReset': 'Evet, sıfırla',
  'page.marketing.smsCreate.step.1': 'SMS kurulumu. Adım 1/4',
  'page.marketing.smsCreate.step.2': 'SMS kurulumu. Adım 2/4',
  'page.marketing.smsCreate.step.3': 'SMS kurulumu. Adım 3/4',
  'page.marketing.smsCreate.step.4': 'SMS kurulumu. Adım 4/4',
  'page.marketing.emailCreate.step.1': 'E-posta kurulumu. Adım 1/4',
  'page.marketing.emailCreate.step.2': 'E-posta kurulumu. Adım 2/4',
  'page.marketing.emailCreate.step.3': 'E-posta kurulumu. Adım 3/4',
  'page.marketing.emailCreate.step.4': 'E-posta kurulumu. Adım 4/4',
  'page.marketing.campaignCreate.step.1': 'Kampanya kurulumu. Adım 1/3',
  'page.marketing.campaignCreate.step.2': 'Kampanya kurulumu. Adım 2/3',
  'page.marketing.campaignCreate.step.3': 'Kampanya kurulumu. Adım 3/3',
  'page.marketing.smsCreate.step.1.title': 'Mesaj ve gönderen kimliği',
  'page.marketing.smsCreate.step.1.defaultTitleForSms': 'SMS Gönderen Kimliğini Girin',
  'page.marketing.smsCreate.step.1.defaultMessageForSms': 'Mesajınızı girin',
  'page.marketing.emailCreate.step.1.defaultTitleForEmail': 'Mesaj başlığını girin',
  'page.marketing.emailCreate.step.1.defaultMessageForSms': 'Mesajınızı girin',
  'page.marketing.emailCreate.step.1.title': 'E-posta ayarları',
  'page.marketing.emailCreate.step.1.messageTitle': 'Mesaj başlığı',
  'page.marketing.emailCreate.step.1.replyToEmail': 'E-postaya yanıt ver',
  'page.marketing.emailCreate.step.1.clientReplies': 'Müşteri yanıtları bu e-postaya gönderilecektir',
  'page.marketing.emailCreate.step.1.photo.title': 'Fotoğraf',
  'page.marketing.emailCreate.step.1.enablePhoto': 'Fotoğrafı etkinleştir',
  'page.marketing.emailCreate.step.1.changePhoto': 'Fotoğrafı değiştir',
  'page.marketing.emailCreate.step.1.button.title': 'Düğme',
  'page.marketing.emailCreate.step.1.enableButton': 'Etkinleştir düğmesi',
  'page.marketing.emailCreate.step.1.buttonName': 'Düğme adı',
  'page.marketing.emailCreate.step.1.buttonURL': "Düğme URL'si",
  'page.marketing.emailCreate.step.1.socialMedia.title': 'Sosyal medya',
  'page.marketing.emailCreate.step.1.enableSocialMedia': 'Sosyal medya bağlantılarını etkinleştirin',
  'page.marketing.emailCreate.step.1.facebookPage': 'Facebook sayfası',
  'page.marketing.emailCreate.step.1.instagramPage': 'Instagram sayfası',
  'page.marketing.emailCreate.step.1.yourPage': 'Sizin sayfanız',
  'page.marketing.smsCreate.yourMessage': 'Mesajınız',
  'page.marketing.btn.sendMeTestMessage': 'Bana test mesajı gönder',
  'page.marketing.smsCreate.step.1.freeTests': 'Bugün için {0} ücretsiz test kaldı',
  'page.marketing.smsCreate.messagePreview': 'Mesaj önizlemesi',
  'page.marketing.smsCreate.btn.clientSelection': 'Müşteri seçimi',
  'page.marketing.audience.title': 'İzleyici',
  'page.marketing.smsCreate.step.2.description': 'Mesajınızı hangi müşterilerin alacağını seçin',
  'page.marketing.smsCreate.step.2.allClient.title': 'Tüm Müşteri',
  'page.marketing.smsCreate.step.2.allClient.description': 'Tüm müşterilerinize mesaj gönderin',
  'page.marketing.smsCreate.step.2.clientGroups.title': 'Müşteri grupları',
  'page.marketing.smsCreate.step.2.clientGroups.description': 'Seçili müşteri gruplarına mesaj gönderme',
  'page.marketing.smsCreate.step.2.totalClients': 'Toplam Müşteriler',
  'page.marketing.smsCreate.step.2.totalPrice': 'Toplam fiyat',
  'page.marketing.smsCreate.step.3.description': 'Mesajınızın gönderilmesini istediğiniz zamanı seçin',
  'page.marketing.smsCreate.step.3.btn.selectDateTime': 'Özel tarih ve saat seçin',
  'page.marketing.smsCreate.step.4.title': 'Toplu mesaj için ödeme',
  'page.marketing.smsCreate.step.4.description': 'Kredi kartı bilgilerinizi yazın ve ödeme yapın',
  'page.marketing.smsCreate.step.4.paymentAmount': 'Ödeme tutarı:',
  'btn.scheduleSend': 'Program gönder',
  'btn.previous': 'Önceki',
  'btn.payment': 'Ödeme',
  'btn.payAndFinish': 'Öde ve bitir',
  'btn.backToDashboard': 'Gösterge tablosuna geri dön',
  'btn.saveAndClose': 'Kaydet ve kapat',
  'modal.editClientSelection.title': 'Müşteri seçimini düzenleme',
  'modal.newClient.description': 'Son eklenen müşteriler:',
  'modal.recentClient.title': 'Son Müşteri',
  'modal.recentClient.description': 'Son ziyaret eden müşteriler:',
  'modal.clientByAge.title': 'Yaşa Göre Müşteri',
  'modal.clientsByGender.title': 'Cinsiyete Göre Müşteriler',
  'modal.paymentConfirmation.title': 'Ödeme başarılı !',
  'modal.paymentConfirmation.messageSend': 'Mesajınız gönderilecektir',
  'modal.previewMessage.title': 'Önizleme Mesajı',
  'modal.previewSms.tab': "Önizleme SMS'i",
  'modal.previewEmail.tab': 'E-posta Önizleme',
  'modal.editServiceSelection.title': 'Hizmet seçimini düzenleme',
  'modal.editServiceSelection.allServices': 'Tüm hizmetler',
  'modal.campaignActivated.title': 'Kampanya etkinleştirildi !',
  'modal.campaignActivated.description': 'Bu Kampanyayı istediğiniz zaman duraklatabilir veya düzenleyebilirsiniz',
  'btn.all': 'Tümü {0}',
  'btn.included': 'Dahil {0}',
  'btn.excluded': 'Hariç {0}',
  'checkbox.allClients': 'Tüm müşteriler {0}',
  'scheduleSend.time': 'zaman',
  'scheduleSend.timeSetTo': 'Zaman ayarlandı:',
  'scheduleSend.setTime': 'Zaman ayarla',
  'campaignSetup.step.1.header.title': 'Kampanya ortamı',
  'campaignSetup.step.2.header.title': 'Mesajı düzenle',
  'campaignSetup.step.3.header.title': 'İnceleme',
  'campaignSetup.channel.description': 'Kampanyanın hangi kanallara gönderileceğini seçin',
  'campaignSetup.smsSetup.tab': 'SMS kurulumu',
  'campaignSetup.emailSetup.tab': 'E-posta kurulumu',
  'campaignSetup.almostDone.title': 'Neredeyse bitti !',
  'campaignSetup.almostDone.description':
    'Akıllı kampanyanız kullanıma hazırdır, etkinleştirildikten sonra müşterilere otomatik olarak sürekli mesaj gönderecektir. Bu kampanyayı istediğiniz zaman kolayca değiştirebilir veya duraklatabilirsiniz',
  'campaignSetup.approximatePrice.header': 'Yaklaşık fiyat',
  'campaignSetup.approximatePrice.description': 'Önümüzdeki 30 gün için',
  'campaignSetup.approximatePrice.approx': 'Yaklaşık',
  'campaign.birthdaySpecial.description':
    'Bu kampanya müşterilere doğum günlerine yakın bir zamanda otomatik olarak gönderilir',
  'campaign.birthdaySpecial.sendToClient': 'Müşteriye Gönder:',
  'campaign.birthdaySpecial.selectOption.1': 'Doğum günü gününde',
  'campaign.birthdaySpecial.selectOption.2': 'Doğum gününden üç gün önce',
  'campaign.birthdaySpecial.selectOption.3': 'Doğum gününden bir hafta önce',
  'campaign.birthdaySpecial.selectOption.4': 'Doğum gününden iki hafta önce',
  'campaign.winBackClients.description': 'Bu kampanya, belirli bir süre sonra geri dönmeyen müşterilere gönderilir',
  'campaign.winBackClients.clientsWhoDidntReturn': 'Son bir yıl içinde geri dönmeyen müşteriler',
  'campaign.winBackClients.appliedTo': 'Tüm',
  'campaign.winBackClients.all': ' hizmetlere ',
  'campaign.winBackClients.services': 'uygulanır',
  'campaign.winBackClients.editSelection': 'Seçimi Düzenle',
  'campaign.welcomeNewClients.description':
    'İlk kez gelen müşterileri özel bir indirimle tekrar rezervasyon yapmaya teşvik ederek yeni gelenleri müdavimlere dönüştürün',
  'campaign.welcomeNewClients.smartCampaignNewClients':
    'Bu akıllı kampanya, yeni müşterilere ilk satışlarından bir gün sonra otomatik olarak gönderilir',
  'campaign.rewardRegularClients.description':
    'Bu kampanya, son randevu etkinliğine göre müşterilere otomatik olarak gönderilir',
  'campaign.rewardRegularClients.clientsWithAtLeast': 'En az aşağıdaki özelliklere sahip müşteriler',
  'campaign.rewardRegularClients.appointmentsInThe': 'randevuları',
  'campaign.rewardRegularClients.last': 'son',
  'campaign.rewardRegularClients.appointments': 'randevuları',
  'campaign.rewardRegularClients.inTheLast': 'son',
  'campaign.rewardRegularClients.months': 'aylar',
  'subscriptionPaymentFailed.title':
    'Lütfen ödeme yönteminizi {0} olana kadar güncelleyin, aksi takdirde tüm premium özellikler durdurulacak, teşekkür ederiz.',
  'subscriptionCancelled.title':
    "Özür dileriz, ancak ödeme yapılmaması nedeniyle Premium'unuz devre dışı bırakıldı. Premium özellikleri kullanmaya devam etmek istiyorsanız lütfen Premium Planı etkinleştirin, teşekkürler.",
  'notificationPaymentFailed.title':
    'Bildirimler için ödemeniz başarısız oldu. Lütfen ödemeyi {0} tarihine kadar tamamlayın, aksi takdirde bildirimler devre dışı bırakılacaktır, teşekkür ederiz.',
  'notificationCancelled.title':
    'Özür dileriz ancak ödeme yapılmaması nedeniyle bildirimleriniz devre dışı bırakıldı. Bildirim göndermeye devam etmek istiyorsanız lütfen ödeme yapın, teşekkür ederiz.',
  'btn.hideOptions': 'Seçenekleri gizle',
  'btn.showOptions': 'Seçenekleri göster',
  'notification.googleDisconnected': 'Google Takvim bağlantısı kesildi',
  'notification.googleConnected': 'Google Takvim bağlı',
  'notification.appleDisconnected': 'Apple Takvim bağlantısı kesildi',
  'notification.appleConnected': 'Apple Takvim bağlı',
  'notification.microsoft365Disconnected': 'Microsoft365 Takvim bağlantısı kesildi',
  'notification.microsoft365Connected': 'Microsoft365 Takvim bağlı',
  'notification.linkCopied': 'Bağlantı kopyalandı',
  'calendarSync.title': 'Takvim senkronizasyonu',
  'calendarSync.description':
    'Zahmetsiz organizasyon için Plandok randevularını favori takviminizle tek yönlü senkronize edin ve önemli bir etkinliği asla kaçırmayın.',
  'googleCalendar.title': 'Google Takvim',
  'appleCalendar.title': 'Apple Takvim',
  'microsoft365Calendar.title': 'Microsoft365 Takvim',
  'linkYourCalendar.title': 'Takvimlerinizi Bağlayın',
  'connect.btn': 'Bağlan',
  'disconnect.rtn': 'Bağlantıyı Kes',
  'connected.label': 'Bağlı',
  'modal.workingHours.standartMethod.btn': 'Standart yöntem',
  'modal.workingHours.customMethod.btn': 'Özel yöntem',
  'modal.workingHours.workingDates.input': 'Çalışma tarihleri',
  'modal.workingHours.selectDates.placeholder': 'Tarihleri seçin',
  'noInternetConnection.notification.title': 'İnternet bağlantısı yok',
  'app.update.title': 'Yeni sürüm mevcut',
  'app.update.description.title':
    'Plandok ile deneyiminizi olabildiğince sorunsuz hale getirmek için yeni özellikler ekledik ve bazı hataları düzelttik.',
  'app.update.button.title': 'Şimdi güncelle',
  'app.notNow.button.title': 'Şimdi olmaz',
  'app.updateAvailable.button.title': 'Yeni sürüm mevcut',
  'upgradePlan.success.message': 'Abonelik başarıyla güncellendi',
  'upgradePlan.error.message':
    'Abonelik güncelleme hatası, kartınızdan ücret alındıysa ve abonelik yükseltilmediyse lütfen destek ekibiyle iletişime geçin',
  'sidebar.inventory': 'Envanter',
  'products.tab': 'Ürünler',
  'suppliers.tab': 'Tedarikçiler',
  'stockOrders.tab': 'Stok siparişleri',
  'stockTracking.tab': 'Stok takibi',
  'input.productsSearch.placeholder': 'Ürün adına veya barkoda göre arama',
  'product.create.btn.title': 'Yeni ürün ekle',
  'table.products.product': 'Ürün',
  'table.products.barcode': 'Barkod',
  'table.products.brand': 'Marka',
  'table.products.sale': 'Satış',
  'table.products.saleOpt': 'Satış Seç.',
  'saleOptions.tooltip.title': 'Satış seçenekleri',
  'saleOptions.tooltip.description': 'Ödeme sırasında satışı etkinleştirmeyi/devre dışı bırakmayı seçebilirsiniz.',
  'seeMore.title': 'daha fazlasını gör',
  'option.products.downloadCSV': "CSV'yi İndir",
  'option.products.downloadExcel': "Excel'i İndirin",
  'option.products.manageBrands': 'Markaları Yönet',
  'option.products.manageCategories': 'Kategorileri Yönet',
  'option.products.manageStock': 'Stok Yönetimi',
  'option.products.importProducts': 'İthal Ürünler',
  'modal.filter.categories.title': 'Kategoriler',
  'modal.filter.brands.title': 'Markalar',
  'modal.filter.stock.title': 'Stok',
  'modal.filter.allCategories.selectItem': 'Tüm kategoriler',
  'modal.filter.allBrands.selectItem': 'Tüm markalar',
  'modal.filter.allSuppliers.selectItem': 'Tüm tedarikçiler',
  'modal.filter.allProducts.selectItem': 'Tüm ürünler',
  'input.suppliersSearch.placeholder': 'Tedarikçi adına göre arama',
  'supplier.create.btn.title': 'Yeni tedarikçi ekle',
  'table.suppliers.supplierName': 'Tedarikçi adı',
  'table.suppliers.phoneNumber': 'Telefon numarası',
  'table.suppliers.physicalAddress': 'Fiziksel adres',
  'validation.notification.reminderTime': 'Aynı hatırlatma saatine sahip bildirimler gönderilemiyor.',
  'validation.paymentType.switches': 'En az bir ödeme yöntemine izin verilmelidir',
  'plan.premium.option.14':
    'Kredi kartı kullanılarak Online Rezervasyonlar üzerinden alınan randevular için %0 Plandok komisyon ücreti.',
  'title.dragAndExtend.appointment.permission': 'Randevuları sürükleyip uzatabilir',
  'button.lastVisit.addAnotherLastvisit': 'SON ZİYARETE BAŞKA BİR ZİYARET EKLE',
  'notifications.template.badge.validate': 'Doğrula',
  'notifications.template.badge.refused': 'Reddedildi',
  'paid.by.card': 'Kartla ödenen',
  'costs.manual.paid.link': 'Veya bu bağlantıya tıklayarak bildirimlerinizi kartınızla manuel olarak ödeyebilirsiniz.',
  'modal.remove.account.title': 'Stripe hesabını kaldır',
  'modal.remove.account.description': 'Hesabınız kaldırılacak ve çevrimiçi ödemeleri kabul edemeyeceksiniz. ',
  'ob.remove.account.option': 'Stripe hesabını kaldır',
  'btn.remove.account': 'Kaldırmak',
  'calendar.tooltip.selected': 'Seçildi',
  'calendar.tooltip.no.preference': 'Tercih yok',
  'ob.gtag.manager.title': 'Google Etiket Yöneticisi',
  'ob.analytics.gtag.description.1':
    'Google etiket yöneticisi, reklam yatırım getirinizi ölçmenin yanı sıra Flash, video ve sosyal ağ sitelerinizi ve uygulamalarınızı izlemenize olanak tanır.',
  'ob.gtag.manager.description.1':
    'Google Etiket Yöneticisi, kodu doğrudan değiştirmenize gerek kalmadan web sitenizde veya mobil uygulamanızda pazarlama etiketlerini (kod parçacıkları veya izleme pikselleri) yönetmenize ve dağıtmanıza olanak tanıyan ücretsiz bir etiket yönetimi sistemidir.',
  'ob.gtag.manager.description.2':
    'Temelde GTM, pazarlama etiketlerini yönetme sürecini düzene sokarak web sitesi performansını izlemeyi, pazarlama kampanyalarını ölçmeyi ve kullanıcı deneyimlerini optimize etmeyi kolaylaştırır.',
  'text.gender.male': 'Erkek',
  'text.gender.female': 'Dişi',
  'text.gender.unknown': 'Bilinmiyor',
};

export default labels;
