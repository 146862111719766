import { isMobile } from 'react-device-detect';
import React, { ReactNode} from "react";
import truncate from 'lodash/truncate';
import { Avatar } from 'antd';
import { IntlLabel, IntlMoney } from '@plandok/i18n';
import { CustomIcon, Text } from '@plandok/core';
import { formatDuration, formatStartTimeAndDuration } from 'helpers/date';
import { STATUS_CONFIG, NOTES_LENGTH } from './constants';
import { concatString } from '../../helpers';
import * as SC from './styles';

interface CardRowProps {
  label: string;
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch';
  children: ReactNode;
}

const CardRow = ({ label, children, alignItems }: CardRowProps) => (
  <SC.Column {...(alignItems && { alignItems })}>
    <Text size={isMobile ? 'small' : 'xsmall'} weight="normal" colorType="lightGrey" mb="none">
      <IntlLabel label={label} />
    </Text>
    <Text size={isMobile ? 'base' : 'small'} weight="medium" colorType="base" mb="none">
      {children}
    </Text>
  </SC.Column>
);

export default function CardTooltip({ booking, hideStatus, isBlocked, timeFormat }: any) {
  const statuses = Object.keys(STATUS_CONFIG);

  let statusText = '';


  const { text, color: statusColor } = statuses.includes(booking.appointmentStatus)
    ? STATUS_CONFIG[booking.appointmentStatus as keyof typeof STATUS_CONFIG]
    : { text: '', color: '#FC6792' };

  if (!hideStatus) {
    statusText = text;
  }

  const hasClient = !booking.isWalkIn;
  const staff = concatString([booking.employeeFirstName, booking.employeeLastName]);
  const hasFooter = booking.isbookedOnline || booking.recurrenceId;

  if (isBlocked) {
    return (
      <SC.TooltipContent>
        <div data-card-tooltip-title>{booking.description}</div>
      </SC.TooltipContent>
    );
  }

  return (
    <SC.TooltipContent>
      <SC.CardHeader>
        <SC.Flex alignItems="center">
          <Avatar size="large" icon="user" />
          {hasClient ? (
            <SC.UserInfo>
              <div>
                {booking.clientFirstName} {booking.clientLastName}
              </div>
              <div>{booking.clientPhoneNumber}</div>
            </SC.UserInfo>
          ) : (
            <div>
              <IntlLabel label="calendar.tooltip.emptyCustomer" />
            </div>
          )}
        </SC.Flex>

        <SC.UserNotes>{truncate(booking.appointmentNotes, { length: NOTES_LENGTH })}</SC.UserNotes>
      </SC.CardHeader>

      <SC.CardContent>
        <div>
          <CardRow label="calendar.tooltip.time">
            {timeFormat && formatStartTimeAndDuration(booking.startTime, booking.duration, timeFormat)} (
            {formatDuration(booking.duration)})
          </CardRow>
          <CardRow label="calendar.tooltip.service">{booking.serviceName}</CardRow>
          <CardRow label="calendar.tooltip.category">{booking.serviceGroupName}</CardRow>
          <CardRow label="calendar.tooltip.staff">{staff}</CardRow>
        </div>
        <div>
          <SC.StatusPadding>
            <Text size="small" mb="none" style={{ color: statusColor }}>
              <IntlLabel label={statusText} />
            </Text>
          </SC.StatusPadding>
          <CardRow alignItems="flex-end" label="calendar.tooltip.price">
            <IntlMoney value={booking.price} />
          </CardRow>
        </div>
      </SC.CardContent>

      {hasFooter && <SC.Separator />}

      {booking?.isBookedOnline &&
        <div style={{ marginTop: '8px', display: 'flex', justifyContent: 'space-between' }}>
          <Text size="base" weight="medium" colorType="violet" mb="none">
            <IntlLabel label='calendar.tooltip.bookedOnline'/>
          </Text>
          <Text size="base" weight="medium" colorType="link" mb="none">
            (
            <IntlLabel
              label={booking?.isRandomlySelected ? 'calendar.tooltip.selected' : 'calendar.tooltip.no.preference'}
            />
            )
          </Text>
        </div>
      }

      {booking.recurrenceId && (
        <Text size="base" weight="medium" colorType="link" mb="none">
          <IntlLabel label="calendar.tooltip.repeating" />
        </Text>
      )}

      {booking.paymentType === 'stripe_payment' && (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <SC.Separator />
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <CustomIcon type="paidByCard" />
            <Text size="base" weight="medium" colorType="base" ml="xsmall" mb="none">
              <IntlLabel label="paid.by.card" />
            </Text>
          </div>
        </div>
      )}
    </SC.TooltipContent>
  );
}
