import GtagManager from 'pages/dashboard/BookingPage/BookingAnalytics/GtagManager';
import PaymentConfirmationPage from 'pages/plans/PaymentConfirmationPage';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import React, { useEffect } from 'react';
import { LocalStorage, useAppContext } from '@plandok/core';
import { Language } from '@plandok/i18n';
import RewardRegularClientsSetting from 'pages/dashboard/MarketingCampaignsPages/CampaignSettings/RewardRegularClientsSetting';
import BirthdayCampaignSetting from 'pages/dashboard/MarketingCampaignsPages/CampaignSettings/BirthdayCampaignSetting';
import WelcomeNewClientSetting from 'pages/dashboard/MarketingCampaignsPages/CampaignSettings/WelcomeNewClientSetting';
import WinBackClientsSetting from 'pages/dashboard/MarketingCampaignsPages/CampaignSettings/WinBackClientsSetting';
import ModifyScheduleSendCustomTimePage from 'pages/dashboard/SMSSetup/ModifyScheduleSendCustomTimePage';
import ReportsAppointmentCancellationsPage from 'pages/dashboard/ReportsAppointmentCancellationsPage';
import GoogleAnalytics from 'pages/dashboard/BookingPage/BookingAnalytics/GoogleAnalytics';
import ReportsAppointmentsSummaryPage from 'pages/dashboard/ReportsAppointmentsSummaryPage';
import MobileAnalyticsMenuPage from 'pages/dashboard/AnalyticsPage/MobileAnalyticsMenuPage';
import ModifyClientSelectionPage from 'pages/dashboard/SMSSetup/ModifyClientSelectionPage';
import StartCampaignPage from 'pages/dashboard/MarketingCampaignsPages/StartCampaignPage';
import MobileMessagesMenuPage from 'pages/dashboard/MessagesPage/MobileMessagesMenuPage';
import ModifyPaymentDetailsPage from 'pages/dashboard/SMSSetup/ModifyPaymentDetailsPage';
import FacebookPixel from 'pages/dashboard/BookingPage/BookingAnalytics/FacebookPixel';
import ModifyEmailMessagePage from 'pages/dashboard/EmailSetup/ModifyEmailMessagePage';
import MobileBookingMenuPage from 'pages/dashboard/BookingPage/MobileBookingMenuPage';
import ModifyScheduleSendPage from 'pages/dashboard/SMSSetup/ModifyScheduleSendPage';
import ReportsAppointmentListPage from 'pages/dashboard/ReportsAppointmentListPage';
import MobileBillingMenuPage from 'pages/plans/BillingPage/MobileBillingMenuPage';
import { ModalContainer, LogoutPage, PrivateRoute, PublicRoute } from 'components';
import CampaignSetup from 'pages/dashboard/MarketingCampaignsPages/CampaignSetup';
import CancellationReasonsPage from 'pages/setup-list/CancellationReasonsPage';
import AppointmentDetailsPage from 'pages/dashboard/AppointmentDetailsPage';
import ModifyMessagePage from 'pages/dashboard/SMSSetup/ModifyMessagePage';
import ModifyAppointmentPage from 'pages/dashboard/ModifyAppointmentPage';
import PricingCalculationPage from 'pages/support/PricingCalculationPage';
import CalendarSettingsPage from 'pages/dashboard/CalendarSettingsPage';
import MarketingPage from 'pages/dashboard/MarketingPage/MarketingPage';
import PersonalSettingsPage from 'pages/dashboard/PersonalSettingsPage';
import MobileStaffPage from 'pages/dashboard/StaffPage/MobileStaffPage';
import ReferralSourcesPage from 'pages/setup-list/ReferralSourcesPage';
import ReportsClientsPage from 'pages/dashboard/ReportsClientsPage';
import CompanyDetailsPage from 'pages/dashboard/CompanyDetailsPage';
import BookingsPage from 'pages/dashboard/BookingPage/BookingPage';
import CreateBookingFlow from 'pages/dashboard/CreateBookingFlow';
import ClientDetailsPage from 'pages/dashboard/ClientDetailsPage';
import ModifyServicePage from 'pages/dashboard/ModifyServicePage';
import NotificationChargePage from './pages/plans/NotificationChargePage';
import ModifyClientPage from 'pages/dashboard/ModifyClientPage';
import SetNewPasswordPage from 'pages/auth/SetNewPasswordPage';
import ModifyStaffPage from 'pages/dashboard/ModifyStaffPage';
import ResetPasswordPage from 'pages/auth/ResetPasswordPage';
import ResourcesPage from 'pages/setup-list/ResourcesPage';
import DiscountsPage from 'pages/setup-list/DiscountsPage';
import LocationsPage from 'pages/setup-list/LocationsPage';
import RegistrationPage from 'pages/auth/RegistrationPage';
import MobileMenuPage from 'pages/support/MobileMenuPage';
import AnalyticsPage from 'pages/dashboard/AnalyticsPage';
import MessagesPage from 'pages/dashboard/MessagesPage';
import ServicesPage from 'pages/dashboard/ServicesPage';
import SettingsPage from 'pages/dashboard/SettingsPage';
import CalendarPage from 'pages/dashboard/CalendarPage';
import ClientsPage from 'pages/dashboard/ClientsPage';
import SearchPage from 'pages/dashboard/SearchPage';
import StaffPage from 'pages/dashboard/StaffPage';
import UpdateSubscriptionPage from 'pages/plans/UpdateSubscriptionPage';
import BillingPage from 'pages/plans/BillingPage';
import PlansPage from 'pages/support/PlansPage';
import HelpPage from 'pages/dashboard/HelpPage';
import SetupPage from 'pages/setup/SetupPage';
import LoginPage from 'pages/auth/LoginPage';
import Page404 from 'pages/support/Page404';
import { RoutePath } from './constants/routes';
import history from './history';

function AppRouter() {
  const [state] = useAppContext();
  const { isAuthenticated, isLoading } = state;

  useEffect(() => {
    const refreshToken = LocalStorage.getRefreshToken();

    if (!isAuthenticated && !refreshToken) {
      LocalStorage.clearAll();
    }
  }, [isAuthenticated]);

  // temp fix to make PWA working correctly
  const indexRoute = (
    <Route
      path="/?standalone=true"
      exact
      render={() => (isAuthenticated ? <Redirect to={RoutePath.CALENDAR} /> : <Redirect to={RoutePath.LOGIN} />)}
    />
  );

  return (
    <>
      <Router history={history}>
        <Switch>
          {indexRoute}
          <Route path={RoutePath.LOGOUT} exact render={() => <LogoutPage />} />

          <Route
            path="/"
            exact
            render={() => (isAuthenticated ? <Redirect to={RoutePath.CALENDAR} /> : <Redirect to={RoutePath.LOGIN} />)}
          />

          <PublicRoute
            isAuthenticated={false}
            path={RoutePath.NEW_PRICING}
            exact
            appComponent={PricingCalculationPage}
          />
          {Object.values(Language).map((key) => (
            <PublicRoute
              key={key}
              isAuthenticated={false}
              path={`/${key}${RoutePath.NEW_PRICING}`}
              exact
              appComponent={PricingCalculationPage}
            />
          ))}

          <PublicRoute isAuthenticated={false} path={RoutePath.PLANS} exact appComponent={PlansPage} />
          {Object.values(Language).map((key) => (
            <PublicRoute
              key={key}
              isAuthenticated={false}
              path={`/${key}${RoutePath.PLANS}`}
              exact
              appComponent={PlansPage}
            />
          ))}

          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.CALENDAR}
            exact
            component={CalendarPage}
          />
          {Object.values(Language).map((key) => (
            <PublicRoute
              key={key}
              isAuthenticated={isAuthenticated}
              path={`/${key}${RoutePath.LOGIN}`}
              appComponent={LoginPage}
            />
          ))}
          <PublicRoute isAuthenticated={isAuthenticated} path={RoutePath.LOGIN} exact appComponent={LoginPage} />
          <PublicRoute
            isAuthenticated={isAuthenticated}
            path="/test_error_reports_page"
            exact
            appComponent={() => {
              throw new Error('This page is to test app crash and how error reports works.');
              // eslint-disable-next-line
              return null;
            }}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            path="/test_error_reports_page_2"
            exact
            appComponent={() => {
              throw new Error('This page is to test app crash and how error reports works.');
              // eslint-disable-next-line
              return null;
            }}
          />
          {Object.values(Language).map((key) => (
            <PublicRoute
              key={key}
              isAuthenticated={isAuthenticated}
              path={`/${key}${RoutePath.RESET_PASSWORD}`}
              appComponent={ResetPasswordPage}
            />
          ))}
          <PublicRoute
            isAuthenticated={isAuthenticated}
            path={RoutePath.RESET_PASSWORD}
            appComponent={ResetPasswordPage}
          />
          <Route path={`${RoutePath.CREATE_PASSWORD}/:email/:token`} component={SetNewPasswordPage} />
          {Object.values(Language).map((key) => (
            <Route
              key={key}
              path={`/${key}${RoutePath.CREATE_PASSWORD}/:email/:token`}
              component={SetNewPasswordPage}
            />
          ))}
          <Route path={`${RoutePath.RESET_PASSWORD_CONFIRMATION}/:email/:token`} component={SetNewPasswordPage} />
          {Object.values(Language).map((key) => (
            <Route
              key={key}
              path={`/${key}${RoutePath.RESET_PASSWORD_CONFIRMATION}/:email/:token`}
              component={SetNewPasswordPage}
            />
          ))}
          {Object.values(Language).map((key) => (
            <PublicRoute
              key={key}
              isAuthenticated={isAuthenticated}
              path={`/${key}${RoutePath.REGISTRATION}`}
              appComponent={RegistrationPage}
            />
          ))}
          <PublicRoute
            isAuthenticated={isAuthenticated}
            path={RoutePath.REGISTRATION}
            exact
            appComponent={RegistrationPage}
          />

          {Object.values(Language).map((key) => (
            <Route
              key={key}
              exact
              path={`/${key}${RoutePath.PAYMENT_CONFIRMATION}`}
              render={() => <PaymentConfirmationPage />}
            />
          ))}
          <Route exact path={RoutePath.PAYMENT_CONFIRMATION} render={() => <PaymentConfirmationPage />} />

          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.CALENDAR}
            exact
            component={CalendarPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.CLIENTS}
            exact
            component={ClientsPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.SERVICES}
            exact
            component={ServicesPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.SETTINGS}
            exact
            component={SettingsPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.APPOINTMENT_CREATE}
            exact
            component={ModifyAppointmentPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={`${RoutePath.APPOINTMENT_UPDATE}/:id`}
            exact
            component={ModifyAppointmentPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={`${RoutePath.APPOINTMENT_DETAILS}/:id`}
            exact
            component={AppointmentDetailsPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.CLIENT_CREATE}
            exact
            component={ModifyClientPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={`${RoutePath.CLIENT_EDIT}/:id`}
            exact
            component={ModifyClientPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={`${RoutePath.CLIENT_DETAILS}/:id`}
            exact
            component={ClientDetailsPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.SERVICE_CREATE}
            exact
            component={ModifyServicePage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={`${RoutePath.SERVICE_EDIT}/:id`}
            exact
            component={ModifyServicePage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.COMPANY_DETAILS}
            exact
            component={CompanyDetailsPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.CALENDAR_SETTINGS}
            exact
            component={CalendarSettingsPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.LOCATIONS}
            exact
            component={LocationsPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.RESOURCES}
            exact
            component={ResourcesPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.DISCOUNTS}
            exact
            component={DiscountsPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.REFERRAL_SOURCES}
            exact
            component={ReferralSourcesPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.CANCEL_REASONS}
            exact
            component={CancellationReasonsPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.SETUP_NOTIFICATIONS}
            exact
            component={SetupPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.STAFF}
            exact
            component={StaffPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.STAFF_SELECT}
            exact
            component={MobileStaffPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.STAFF_CREATE}
            exact
            component={ModifyStaffPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={`${RoutePath.STAFF_UPDATE}/:id`}
            exact
            component={ModifyStaffPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.CLIENT_MESSAGES}
            exact
            component={MessagesPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.PERSONAL_SETTINGS}
            exact
            component={PersonalSettingsPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.SEARCH}
            exact
            component={SearchPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={`${RoutePath.BOOKING_CREATE}/:locationId`}
            exact
            component={CreateBookingFlow}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.BILLING}
            exact
            component={BillingPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.BILLING_SELECT}
            exact
            component={MobileBillingMenuPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.MORE}
            exact
            component={MobileMenuPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.BOOKING}
            exact
            component={BookingsPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.BOOKING_MOBILE}
            exact
            component={MobileBookingMenuPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.GOOGLE_ANALYTICS}
            exact
            component={GoogleAnalytics}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.FACEBOOK_PIXEL}
            exact
            component={FacebookPixel}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.GTAG_MANAGER}
            exact
            component={GtagManager}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.ANALYTICS}
            exact
            component={AnalyticsPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.ANALYTICS_MOBILE}
            exact
            component={MobileAnalyticsMenuPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.REPORTS_APPOINTMENT_LIST}
            exact
            component={ReportsAppointmentListPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.REPORTS_APPOINTMENT_CANCELLATIONS}
            exact
            component={ReportsAppointmentCancellationsPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.REPORTS_APPOINTMENT_SUMMARY}
            exact
            component={ReportsAppointmentsSummaryPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.REPORTS_CLIENTS}
            exact
            component={ReportsClientsPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.MARKETING}
            exact
            component={MarketingPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.HELP_MOBILE}
            exact
            component={HelpPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.MESSAGE_CREATE}
            exact
            component={ModifyMessagePage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.MESSAGES_MOBILE}
            exact
            component={MobileMessagesMenuPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.EMAIL_MESSAGE_CREATE}
            exact
            component={ModifyEmailMessagePage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.CLIENT_SELECTION}
            exact
            component={ModifyClientSelectionPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.SCHEDULE_SEND}
            exact
            component={ModifyScheduleSendPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.SCHEDULE_SEND_CUSTOM_TIME}
            exact
            component={ModifyScheduleSendCustomTimePage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.PAYMENT_DETILES}
            exact
            component={ModifyPaymentDetailsPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.BIRTHDAY_CAMPAIGN_SETTING}
            exact
            component={BirthdayCampaignSetting}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.WIN_BACK_CLIENTS_SETTING}
            exact
            component={WinBackClientsSetting}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.WELCOME_NEW_CLIENTS_SETTING}
            exact
            component={WelcomeNewClientSetting}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.REWARD_REGULAR_CLIENTS_SETTING}
            exact
            component={RewardRegularClientsSetting}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.CAMPAIGN_SETUP}
            exact
            component={CampaignSetup}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.START_CAMPAIGN_PAGE}
            exact
            component={StartCampaignPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.UPDATE_SUBSCRIPTION}
            exact
            component={UpdateSubscriptionPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            path={RoutePath.NOTIFICATION_PAYMENT}
            exact
            component={NotificationChargePage}
          />
          <Route component={Page404} />
        </Switch>
        <ModalContainer />
      </Router>
    </>
  );
}

export default AppRouter;
