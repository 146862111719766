import { format, isFuture, isValid, subDays } from 'date-fns';
import React, { useCallback, useState } from 'react';
import { Col, Popover, Row } from 'antd';
import cn from 'classnames';
import { CustomIcon, getIsMobile, SelectInput, Text, useLocation } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import { AppLayout, AppTable, BackButton, NoInternetConnection, tableStoreFactory } from 'components';
import { useReportBookingsByEmployeeQuery } from 'graphql/queries/reportBookingsByEmployee.generated';
import { useReportBookingsByServiceQuery } from 'graphql/queries/reportBookingsByService.generated';
import { exportTableDataToCSV, timeRangeOptions } from 'helpers/reports';
import { useEmployeesQuery } from 'graphql/queries/employees.generated';
import { useLocationsQuery } from 'graphql/queries/locations.generated';
import { useUserQuery } from 'graphql/queries/userQuery.generated';
import { AnalyticsHash, RoutePath } from 'constants/routes';
import useGetCurrentPlan from 'hooks/use-get-current-plan';
import * as SC from 'styles/reports/index';
import { useStore } from 'store/store';
import ReportsMobileFilter from '../AnalyticsPage/AnalyticsReports/components/ReportsMobileFilter';
import { ReportsMobileTabs } from '../AnalyticsPage/AnalyticsReports/components/ReportsMobileTabs';
import DatePicker from '../AnalyticsPage/AnalyticsDashboard/components/DatePicker/DatePicker';
import { AnalyticsMobileHeader } from '../AnalyticsPage/components/AnalyticsMobileHeader';
import { appointmentsSummaryTabsConfig } from '../AnalyticsPage/AnalyticsReports/config';
import { AnalyticsErrorState } from '../AnalyticsPage/components/AnalyticsErrorsState';
import { analyticsTabsConfig } from '../AnalyticsPage/config';
import { useFilters } from '../common/hooks/useFilters';
import { query } from '../../../graphql';
import { appointmentsByServiceColumns, appointmentsByStaffColumns } from './config';

type TimeRangeData = { timeRange: Date; startDate: Date | null; endDate: Date | null };
type FromDate = Date | string | null;

export default function ReportsAppointmentsSummaryPage() {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [isStepByDay, setIsStepByDay] = useState(false);
  const [isVisibleFilter, setIsVisibleFilter] = useState(false);
  const [employeePage, setEmployeePage] = useState(1);
  const [servicePage, setServicePage] = useState(1);
  const [employeeLoading, setEmployeeLoading] = useState(true);
  const [serviceLoading, setServiceLoading] = useState(true);

  const isMobile = getIsMobile();
  const { goBack } = useLocation();

  const { data: employeesData, error: employeesErrors } = useEmployeesQuery();
  const { data: locationsData, error: locationsErrors } = useLocationsQuery();
  const { isSubscriptionLoading, planType } = useGetCurrentPlan();
  const { data: userData, error: userErrors } = useUserQuery();

  const [timeRangeData, setTimeRangeData] = useState<TimeRangeData>({} as TimeRangeData);
  const locationId = useStore(useCallback((state) => state.locationId, []));
  const employeeId = useStore(useCallback((state) => state.employeeId, []));

  const employeeOffset = employeePage === 0 ? 0 : (employeePage - 1) * 10;
  const serviceOffset = servicePage === 0 ? 0 : (servicePage - 1) * 10;

  const getFromDate = (): FromDate => {
    const isTimeRangeSelected = isValid(timeRangeData.timeRange);
    const isNoDaysSelected = !isValid(timeRangeData.timeRange) && !isValid(timeRangeData.endDate);
    const isCustomRangeSelected = isValid(timeRangeData.startDate) && isValid(timeRangeData.endDate);

    if (isTimeRangeSelected) return formattedTimeRange;
    if (isNoDaysSelected) return defaultFormatLast7Days;
    if (isCustomRangeSelected) return format(timeRangeData.startDate ?? 0, 'yyyy-MM-dd');

    return '';
  };

  const toDate = isValid(timeRangeData.endDate)
    ? format(timeRangeData.endDate ?? 0, 'yyyy-MM-dd')
    : format(new Date(), 'yyyy-MM-dd');

  const defaultFormatLast7Days = format(subDays(new Date(), 7), 'yyyy-MM-dd');
  const formattedTimeRange = isValid(timeRangeData.timeRange) ? format(timeRangeData.timeRange, 'yyyy-MM-dd') : '';
  const isDateInFuture = isFuture(new Date(getFromDate() ?? 0));

  const from = isDateInFuture ? toDate : getFromDate();
  const to = isDateInFuture ? getFromDate() : toDate;

  const commonVariables = {
    limit: 10,
    from,
    to,
    locationId: locationId || null,
    employeeId: employeeId || null,
  };
  const employeeVariables = {
    offset: employeeOffset,
    ...commonVariables,
  };
  const serviceVariables = {
    offset: serviceOffset,
    ...commonVariables,
  };

  const {
    data: dataByEmployee,
    loading: loadingByEmployee,
    error: errorByEmployee,
  } = useReportBookingsByEmployeeQuery({
    variables: employeeVariables,
  });

  const {
    data: dataByService,
    loading: loadingByService,
    error: errorByService,
  } = useReportBookingsByServiceQuery({
    variables: serviceVariables,
  });

  const refetchData = () => {
    tableStoreFactory.getTableStoreInstance('bookingsByEmployee').fetchFreshData(dataByEmployee?.reports);
    tableStoreFactory.getTableStoreInstance('bookingsByService').fetchFreshData(dataByService?.reports);
  };

  const loadMoreByEmployee = (page: number) => {
    setEmployeePage(page);
    setServiceLoading(false);
  };
  const loadMoreByService = (page: number) => {
    setServicePage(page);
    setEmployeeLoading(false);
  };

  const {
    mappedLocations,
    mappedEmployees,
    clearFilters,
    selectAllStaffValues,
    selectAllLocationValues,
    selectedStaffValue,
    selectedLocationValue,
    onStaffChange,
    onLocationChange,
    setSelectedStaffValue,
    setSelectedLocationValue,
  } = useFilters({
    locations: locationsData?.locations,
    employees: employeesData?.employees,
    setTimeRange: setTimeRangeData,
  });

  const errors = employeesErrors || locationsErrors || userErrors || errorByEmployee || errorByService;
  if (errors) {
    return AnalyticsErrorState(errors);
  }

  const isPremium = userData?.user?.partner?.subscription.premium ?? false;

  const handlePopoverVisibleChange = (isVisible: boolean) => setIsPopoverVisible(isVisible);

  const closeFilter = () => setIsVisibleFilter(false);
  const toggleFilter = () => setIsVisibleFilter(!isVisibleFilter);

  const exportCSVByClosingPopover = (type: string, from: any, to: any) => {
    exportTableDataToCSV(type, from, to);
    setIsPopoverVisible(false);
  };

  return (
    <NoInternetConnection>
      <AppLayout
        headerTitle="analytics.title"
        headerIconTitle="analytics.premium.title"
        mobileHeader={() => AnalyticsMobileHeader('reports.appointments.title')}
        mobileBackground="#fff"
        mobileWhiteBg={true}
        className={cn('analytics-page')}
        isSubscriptionLoading={isSubscriptionLoading}
        planType={planType}
      >
        <SC.HashTabsWrapper
          className="analytics-tabs"
          tabs={analyticsTabsConfig}
          defaultTab={AnalyticsHash.REPORTS}
          rootPath={RoutePath.ANALYTICS}
        >
          <div />
          <AppLayout.Container maxWidth={'inherit'} noMargin noWhiteBG>
            {!isMobile && (
              <>
                <div style={{ maxWidth: '20rem' }}>
                  <BackButton onClick={goBack} />
                </div>

                <Text size="mlarge" label="reports.appointmentsSummary.title" Type="base" bold />
              </>
            )}
            <Row align="middle" className="w-auto mb-2 justify-between" gutter={16}>
              <Col span={isMobile ? 20 : 8}>
                <DatePicker
                  hasCustomPlacement
                  isPremium={isPremium}
                  timeRangeOptions={timeRangeOptions(isPremium)}
                  isStepByDay={isStepByDay}
                  refetchData={refetchData}
                  className="custom-react-datepicker-wrapper"
                  setTimeRangeData={setTimeRangeData}
                  setIsStepByDay={setIsStepByDay}
                />
              </Col>
              {!isMobile && (
                <>
                  <Col span={4}>
                    <SelectInput
                      options={mappedEmployees}
                      placeholder={<IntlLabel label="reports.filterStaff.placeholder" />}
                      defaultValue="reports.filterStaff.placeholder"
                      value={selectedStaffValue}
                      onChange={onStaffChange}
                      onDefaultChange={selectAllStaffValues}
                      dropdownStyle={{ minWidth: 'max-content' }}
                    />
                  </Col>
                  <Col span={4}>
                    <SelectInput
                      options={mappedLocations}
                      value={selectedLocationValue}
                      placeholder={<IntlLabel label="reports.filterLocations.placeholder" />}
                      defaultValue="reports.filterLocations.placeholder"
                      onChange={onLocationChange}
                      onDefaultChange={selectAllLocationValues}
                      dropdownStyle={{ minWidth: 'max-content' }}
                    />
                  </Col>
                  <Col span={2}>
                    <SC.ClearFilterButton upperCase={false} type="link">
                      <Text size="base" color="link" weight="normal" mb="none" onClick={clearFilters}>
                        <IntlLabel label="reports.clearFilters.button.title" />
                      </Text>
                    </SC.ClearFilterButton>
                  </Col>
                </>
              )}
              {isMobile && (
                <Col span={4}>
                  <SC.ToolsButton icon="tools" onClick={toggleFilter} />
                </Col>
              )}
              <Col span={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Popover
                  content={
                    <>
                      <SC.PopoverContentRow>
                        <Text
                          size="base"
                          colorType="base"
                          mb="none"
                          onClick={() => exportCSVByClosingPopover('bookings_by_employee', from, to)}
                        >
                          <IntlLabel label="reports.appointmentsByStaff.title" />
                        </Text>
                      </SC.PopoverContentRow>
                      <SC.PopoverContentRow>
                        <Text
                          size="base"
                          colorType="base"
                          mb="none"
                          onClick={() => exportCSVByClosingPopover('bookings_by_service', from, to)}
                        >
                          <IntlLabel label="reports.appointmentsByService.title" />
                        </Text>
                      </SC.PopoverContentRow>
                    </>
                  }
                  placement="bottomLeft"
                  trigger="click"
                  visible={isPopoverVisible}
                  onVisibleChange={handlePopoverVisibleChange}
                  overlayClassName={isMobile ? 'custom-mobile-popover' : 'custom-desktop-popover'}
                >
                  <SC.ExportCSVButton ghost={isMobile ? false : true} upperCase={false} minorBtn>
                    {!isMobile && <Text size="base" colorType="base" label="reports.exportCsv.title" mb="none" />}
                    <CustomIcon type="csv" />
                  </SC.ExportCSVButton>
                </Popover>
              </Col>
            </Row>

            {isMobile && (
              <ReportsMobileTabs tabs={appointmentsSummaryTabsConfig}>
                <AppTable
                  type="bookingsByEmployee"
                  columns={appointmentsByStaffColumns}
                  className="reports-table"
                  paginationSpacing="15px 0"
                  isCustomPagination
                  loading={employeeLoading && loadingByEmployee}
                  loadMore={loadMoreByEmployee}
                  apiConfig={{
                    fetchData: () => dataByEmployee?.reports,
                    mapping: {
                      pageKey: 'page',
                      dataKey: 'bookingsByEmployee',
                      totalCountKey: 'bookingsByEmployee.totalCount',
                      searchKey: 'query',
                      emptyStateKey: 'bookingsByEmployee.emptyState',
                    },
                  }}
                />

                <AppTable
                  type="bookingsByService"
                  columns={appointmentsByServiceColumns}
                  className="reports-table"
                  paginationSpacing="15px 0"
                  isCustomPagination
                  loading={serviceLoading && loadingByService}
                  loadMore={loadMoreByService}
                  hidePageSwitcher={false}
                  apiConfig={{
                    fetchData: () => dataByService?.reports,
                    mapping: {
                      pageKey: 'page',
                      dataKey: 'bookingsByService',
                      totalCountKey: 'bookingsByService.totalCount',
                      searchKey: 'query',
                      emptyStateKey: 'bookingsByService.emptyState',
                    },
                  }}
                />
              </ReportsMobileTabs>
            )}

            {!isMobile && (
              <>
                <Row className="w-100 mt-1 mb-2">
                  <Text size="large" colorType="base" bold mb="none">
                    <IntlLabel label="reports.appointmentsByStaff.title" />
                  </Text>
                </Row>

                <AppTable
                  type="bookingsByEmployee"
                  columns={appointmentsByStaffColumns}
                  className="reports-table"
                  paginationSpacing="15px 0"
                  isCustomPagination
                  loading={employeeLoading && loadingByEmployee}
                  loadMore={loadMoreByEmployee}
                  searchStr={query}
                  selectedPage={employeePage}
                  apiConfig={{
                    fetchData: () => dataByEmployee?.reports,
                    mapping: {
                      pageKey: 'page',
                      dataKey: 'bookingsByEmployee',
                      totalCountKey: 'bookingsByEmployee.totalCount',
                      searchKey: 'query',
                      emptyStateKey: 'bookingsByEmployee.emptyState',
                    },
                  }}
                />

                <Row className="w-100 mt-1 mb-2">
                  <Text size="large" colorType="base" bold mb="none">
                    <IntlLabel label="reports.appointmentsByService.title" />
                  </Text>
                </Row>

                <AppTable
                  type="bookingsByService"
                  columns={appointmentsByServiceColumns}
                  className="reports-table"
                  paginationSpacing="15px 0"
                  isCustomPagination
                  loading={serviceLoading && loadingByService}
                  loadMore={loadMoreByService}
                  searchStr={query}
                  selectedPage={servicePage}
                  apiConfig={{
                    fetchData: () => dataByService?.reports,
                    mapping: {
                      pageKey: 'page',
                      dataKey: 'bookingsByService',
                      totalCountKey: 'bookingsByService.totalCount',
                      searchKey: 'query',
                      emptyStateKey: 'bookingsByService.emptyState',
                    },
                  }}
                />
              </>
            )}

            <ReportsMobileFilter
              isVisible={isVisibleFilter}
              onClose={closeFilter}
              staffOptions={mappedEmployees}
              locationOptions={mappedLocations}
              selectedStaffValue={selectedStaffValue}
              selectedLocationValue={selectedLocationValue}
              clearFilters={clearFilters}
              setSelectedStaffValue={setSelectedStaffValue}
              setSelectedLocationValue={setSelectedLocationValue}
              selectAllStaffValues={selectAllStaffValues}
              selectAllLocationValues={selectAllLocationValues}
            />
          </AppLayout.Container>
        </SC.HashTabsWrapper>
      </AppLayout>
    </NoInternetConnection>
  );
}
