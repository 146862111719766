import { CustomIcon, getIsMobile, Text } from '@plandok/core';
import { Spin, Switch } from 'antd';
import * as api from 'api';
import { onReschedule } from 'helpers/app/reschedule';
import PaymentTypesController, {
  IPaymentType,
  PaymentTypeEnum,
} from 'pages/dashboard/BookingPage/BookingPayments/controllers/paymentTypeController';
import { Container } from 'pages/dashboard/CalendarPage/components/PaymentErrorNotification';
import DragHandle from 'pages/dashboard/ServicesPage/components/ServiceGroup/components/DragHandle';
import React from 'react';
import * as RSH from 'react-sortable-hoc';
import PayCardContent from './components/PayCardContent';
import * as SC from './styles';

const SortableContainer = RSH.SortableContainer((props: any) => <div>{props.children}</div>);
const SortableElement = RSH.SortableElement((props: any) => <div>{props.children}</div>);

export default function BookingPayments() {
  const controller = PaymentTypesController();

  const isMobile = getIsMobile();

  const onSitePayment = controller.getPaymentType(PaymentTypeEnum.ON_SITE);
  const stripePayment = controller.getPaymentType(PaymentTypeEnum.STRIPE_PAYMENT);

  return (
    <SC.Container>
      {!isMobile && <Text label="ob.payments.tab" size="xxxlarge" weight="semiBold" mb="xmedium" lineHeight="49px" />}
      <Text label="ob.payments.page.description" lh="xxlarge" marginBottom={isMobile ? '24px' : '40px'} size="medium" />
      <Spin spinning={controller.loading}>
        {controller.error && (
          <Container>
            <div className="align-center">
              <span className="align-center">
                <CustomIcon type="clear" />
                <Text
                  weight="semiBold"
                  size={isMobile ? 'mlarge' : 'base'}
                  mb="none"
                  label="Error"
                  ml="xsmall"
                  lh={isMobile ? 'xxlarge' : 'small'}
                />
              </span>

              <Text
                size="small"
                mb={isMobile ? 'small' : 'none'}
                ml={isMobile ? 'none' : 'base'}
                lh="large"
                style={{ maxWidth: '596px' }}
              >
                {controller.error}
              </Text>
            </div>
          </Container>
        )}

        <SortableContainer
          useDragHandle
          onSortEnd={onReschedule({
            data: controller.paymentTypes,
            changeData: (nextPaymentType: any) => controller.setPaymentTypes(nextPaymentType),
            onPositionUpdate: async (id: string, newPosition: number) =>
              await api.changePaymentTypePosition(id, {
                id: id,
                position: newPosition,
              }),
          })}
        >
          {controller.paymentTypes.map((paymentType, index) =>
            paymentType.paymentType === PaymentTypeEnum.STRIPE_PAYMENT ? (
              <SortableElement index={index}>
                <SC.ContainerDraggable data-payment-type={paymentType.paymentType}>
                  <DragHandle />
                  <StripeCardPayment
                    index={index}
                    isMobile={isMobile}
                    controller={controller}
                    stripePayment={stripePayment}
                    onSitePayment={onSitePayment}
                  />
                </SC.ContainerDraggable>
              </SortableElement>
            ) : (
              <SortableElement index={index}>
                <SC.ContainerDraggable data-payment-type={paymentType.paymentType}>
                  <DragHandle />
                  <OnSitePayment
                    index={index}
                    isMobile={isMobile}
                    controller={controller}
                    stripePayment={stripePayment}
                    onSitePayment={onSitePayment}
                  />
                </SC.ContainerDraggable>
              </SortableElement>
            )
          )}
        </SortableContainer>
      </Spin>
    </SC.Container>
  );
}

export type PaymentTypeProps = {
  index: number;
  isMobile: boolean;
  stripePayment: IPaymentType;
  controller: any;
  onSitePayment: IPaymentType;
};

const OnSitePayment = (props: PaymentTypeProps) => (
  <SC.PaySiteCard>
    <CustomIcon type="cash" />
    <SC.PaySiteInfo>
      <Text
        label="ob.payOnSite.title"
        mb="small"
        weight="semiBold"
        fontSize="22px"
        lineHeight="26px"
        marginBottom={props.isMobile ? '4px' : '15px'}
      />
      <Text
        label="ob.payOnSite.description"
        size="small"
        lh="xlarge"
        color="#5A6F99"
        marginBottom={props.isMobile ? '16px' : '24px'}
      />
      <SC.SwitchContainer>
        <Switch
          defaultChecked
          checked={props.onSitePayment.isActive}
          onChange={(value) => props.controller.changeStatus(props.index, PaymentTypeEnum.ON_SITE, value)}
          disabled={!props.stripePayment.isActive}
        />
        <Text
          color={props.onSitePayment.isActive ? '#009329' : '#7183a7'}
          label={props.onSitePayment.isActive ? 'ob.btn.active' : 'ob.btn.inactive'}
          size="medium"
          lh="mlarge"
          mb="none"
          marginLeft="12px"
        />
      </SC.SwitchContainer>
    </SC.PaySiteInfo>
  </SC.PaySiteCard>
);

const StripeCardPayment = (props: PaymentTypeProps) => (
  <SC.PayCardCard>
    <SC.PayCardContainer>
      <CustomIcon type="creditCard" />
      <SC.PayCardWrapper>
        <Text
          label="ob.payWithCard.title"
          mb="small"
          weight="semiBold"
          fontSize="22px"
          lineHeight="26px"
          marginBottom={props.isMobile ? '4px' : '16px'}
        />
        <Text
          label="ob.payWithCard.description"
          size="small"
          lh="xlarge"
          color="#5A6F99"
          marginBottom={props.isMobile ? '9px' : '16px'}
        />

        {!props.isMobile && (
          <PayCardContent
            stripePayment={props.stripePayment}
            onSitePayment={props.onSitePayment}
            controller={props.controller}
            changeStatus={(value) => props.controller.changeStatus(props.index, PaymentTypeEnum.STRIPE_PAYMENT, value)}
          />
        )}
      </SC.PayCardWrapper>
    </SC.PayCardContainer>

    {props.isMobile && (
      <PayCardContent
        stripePayment={props.stripePayment}
        onSitePayment={props.onSitePayment}
        controller={props.controller}
        changeStatus={(value) => props.controller.changeStatus(props.index, PaymentTypeEnum.STRIPE_PAYMENT, value)}
      />
    )}
  </SC.PayCardCard>
);
