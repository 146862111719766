import React, { useEffect, useState } from 'react';
import { isFirefox } from 'react-device-detect';
import { observer } from 'mobx-react';
import { Spin } from 'antd';
import { getIsMobile, AuthBlock, useModal, useAppContext } from '@plandok/core';
import { PriceType } from 'pages/plans/BillingPage/PricingPage/constants';
import useGetHasPermission from 'hooks/permission/useGetHasPermission';
import { PermissionLevel, PermissionType } from 'constants/auth';
import { AppLayout, NoInternetConnection } from 'components';
import useCheckUpdateApp from 'hooks/use-check-update-app';
import EpicCalendar from 'epic-calendar/EpicCalendar';
import { formatDateField } from 'helpers/date/field';
import { RoutePath } from 'constants/routes';
import { StateCalendar } from './constants';
import { useLocation } from 'hooks/router';
import { useStore } from 'store/store';
import PremiumDeactivatedNotification from './components/PremiumDeactivatedNotification';
import MultipleAddButton from '../ServicesPage/components/MultipleAddButton';
import PaymentErrorNotification from './components/PaymentErrorNotification';
import BlockTimeSelectBanner from './components/BlockTimeSelectBanner';
import CalendarMobileHeader from './components/CalendarMobileHeader';
import TimeCardComponent from './components/TimeCardComponent';
import CalendarFilter from './components/CalendarFilter';
import CalendarHeader from './components/CalendarHeader';
import A2HSProvider from './components/A2HSProvider';
import CardTooltip from './components/CardTooltip';
import calendarState from './store/calendar.state';
import { extractBlockTimeValues, roundDown, roundUp } from './helpers';
import { TimeCardData } from './types';
import * as service from './service';
import * as SC from './styles';
import './style.less';
import useGetLocationStaffPlan from '../../../hooks/use-get-location-staff-plan';

let lastScroll: number | undefined = 0;
let visitedPage: boolean = false;
let scrollTimeout: number;
let scrollTimeoutForBtn: number;

const scrollToCurrentTimeLine = () => {
  const calendarBodyElement = document.getElementById('epic-calendar__body');
  if (scrollTimeout) {
    clearTimeout(scrollTimeout);
  }

  scrollTimeout = window.setTimeout(() => {
    if (visitedPage) {
      calendarBodyElement?.scroll?.({ top: lastScroll });
    } else {
      // Firefox does not support center scroll
      document
        .getElementById('currentTimeIndicator')
        ?.scrollIntoView?.({ block: isFirefox ? 'start' : 'center', behavior: 'smooth' });
    }
  }, 100);
};

function CalendarPage() {
  const [isDisplayPremiumDeactivatedAlert, setIsDisplayPremiumDeactivatedAlert] = useState(true);
  const [isVisibleUpdateNotification, setIsVisibleUpdateNotification] = useState(true);
  const [isDisplayPaymentErrorAlert, setIsDisplayPaymentErrorAlert] = useState(true);
  const [isDisplayBtnShowAllTimes, setIsDisplayBtnShowAllTimes] = useState(false);
  const [isSelectAppointmentMode, setIsCreateAppointment] = useState(false);
  const [isVisibleUpdateBtn, setIsVisibleUpdateBtn] = useState(true);
  const [isDisplayCalendar, setIsDisplayCalendar] = useState(true);
  const [isSelectSlotMode, setBlockTimeMode] = useState(false);
  const [customViewType, setCustomViewType] = useState('');
  const [version, setVersion] = useState(0);

  const { navigate } = useLocation();
  const [context] = useAppContext();

  const setRescheduleItemStaff = useStore((state) => state.setRescheduleItemStaff);
  const setRescheduleItemTime = useStore((state) => state.setRescheduleItemTime);
  const setRescheduleItemDate = useStore((state) => state.setRescheduleItemDate);
  const setRescheduleItemId = useStore((state) => state.setRescheduleItemId);
  const rescheduleItemId = useStore((state) => state.rescheduleItemId);

  const { subscriptionCancelled, notificationCancelled, subscriptionPaymentFailed, notificationPaymentFailed } =
    calendarState.getAlerts;
  const { locationsCount, planType, staffCount, planId, isPlanStaffLocLoading } = useGetLocationStaffPlan();
  const [{ modals }, { showModal }] = useModal();
  const { isUpdated } = useCheckUpdateApp();

  const selectModeOn = isSelectSlotMode || isSelectAppointmentMode;
  const customViewTime = calendarState.data?.customViewTime;
  const hours: number[] = [];

  const isPaymentErrorDismiss = sessionStorage.getItem('isPaymentErrorDismiss');
  const isPremiumDeactivatedDismiss = sessionStorage.getItem('isPremiumDeactivatedDismiss');
  const isPaymentErrorModalDismiss = sessionStorage.getItem('isPaymentErrorModalDismiss');
  const isPremiumDeactivatedModalDismiss = sessionStorage.getItem('isPremiumDeactivatedModalDismiss');

  const closeSlotSelect = () => {
    setBlockTimeMode(false);
    setIsCreateAppointment(false);
  };

  const openSetBlockTime = () => setBlockTimeMode(true);
  const getHasPermission = useGetHasPermission();

  const checkShowAllTimes = (calendarViewTime: string) => {
    const calendarBody = document.getElementById('epic-calendar__body');

    const checkScroll = () => {
      if (calendarBody) {
        const hasScroll = calendarBody.scrollHeight > calendarBody.clientHeight;
        const customViewTime = calendarState.data?.customViewTime;
        const scrollTop = calendarBody.scrollTop;
        const heightToHideFrom = 50;
        const isScroll = !hasScroll || scrollTop > heightToHideFrom;

        const firstCondition = calendarViewTime !== StateCalendar.FULL_CALENDAR;
        const secondCondition = calendarViewTime === StateCalendar.WORKING_HOUR && isScroll;
        const thirdCondition =
          calendarViewTime === StateCalendar.CUSTOM_RANGE &&
          (customViewTime?.startTime !== 0 || customViewTime?.endTime !== 1400) &&
          isScroll;

        let displayBtnShowAllTimes = !calendarState.isLoading && firstCondition && (secondCondition || thirdCondition);

        if (scrollTimeoutForBtn) {
          clearTimeout(scrollTimeoutForBtn);
        }

        scrollTimeoutForBtn = window.setTimeout(() => {
          setIsDisplayBtnShowAllTimes(displayBtnShowAllTimes);
        }, 1000);
      }
    };

    if (calendarBody) {
      const hasScroll = calendarBody.scrollHeight > calendarBody.clientHeight;

      if (hasScroll) {
        calendarBody.addEventListener('scroll', checkScroll);
      } else {
        setIsDisplayBtnShowAllTimes(true);
      }
    }

    return () => {
      if (calendarBody) {
        const hasScroll = calendarBody.scrollHeight > calendarBody.clientHeight;

        if (hasScroll) {
          calendarBody.removeEventListener('scroll', checkScroll);
        }
      }
    };
  };

  useEffect(() => {
    if (isDisplayCalendar && !calendarState.isLoading && calendarState.calendarData.length) {
      checkShowAllTimes(calendarState.data?.calendarViewTime);
    }
  }, [isDisplayCalendar]);

  useEffect(() => {
    scrollToCurrentTimeLine();

    calendarState.initialFetchData().then(() => {
      const calendarViewTime = calendarState.data?.calendarViewTime;

      setCustomViewType(calendarViewTime);
      scrollToCurrentTimeLine();
      checkShowAllTimes(calendarViewTime);
    });

    if (rescheduleItemId) {
      setBlockTimeMode(true);
    }

    return () => {
      const calendarBodyElement = document.getElementById('epic-calendar__body');
      visitedPage = true;
      lastScroll = calendarBodyElement ? calendarBodyElement!.scrollTop : undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rescheduleItemId]);

  const { viewType, timeOffset } = calendarState.filterParams;

  const isOwner = context.access?.permissionLevel === PermissionLevel.OWNER;
  const staffFromFilterParams = calendarState.filterParams?.staff;
  const { timeFormat, eventUrl } = calendarState.data ?? {};
  const isWeekMode = viewType === 'week';

  useEffect(() => {
    if (!isUpdated) {
      localStorage.setItem('isUpdated1', 'false');
    }

    let eventSource: EventSource | undefined;

    if (eventUrl) {
      const url = new URL(`${process.env.REACT_APP_SEE_URL}`);

      url.searchParams.append('topic', `${eventUrl}`);
      url.searchParams.append('authorization', `${process.env.REACT_APP_SSE_TOKEN}`);

      eventSource = new EventSource(url.toString());

      eventSource.onmessage = () => calendarState.fetchData(true);
      eventSource.onerror = (err: Event) => console.error('Error when retrieving calendar data', err);

      return () => {
        if (eventSource) {
          eventSource.close();
        }
      };
    }
  }, [eventUrl, isUpdated]);

  useEffect(() => {
    if (isOwner && !calendarState.isLoading) {
      if ((subscriptionCancelled || notificationCancelled) && !modals.length && !isPremiumDeactivatedModalDismiss) {
        showModal({
          type: 'PREMIUM_DEACTIVATED_MODAL',
          modalProps: { type: subscriptionCancelled?.type || notificationCancelled?.type },
        });
      }

      if ((subscriptionPaymentFailed || notificationPaymentFailed) && !modals.length && !isPaymentErrorModalDismiss) {
        showModal({
          type: 'PAYMENT_ERROR_MODAL',
          modalProps: {
            planType,
            planId,
            warningDate: subscriptionPaymentFailed?.warningDate || notificationPaymentFailed?.warningDate,
            type: subscriptionPaymentFailed?.type || notificationPaymentFailed?.type,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    planType,
    planId,
    showModal,
    subscriptionCancelled,
    notificationCancelled,
    subscriptionPaymentFailed,
    notificationPaymentFailed,
    isOwner,
  ]);

  const createAppointment = () => setIsCreateAppointment(true);

  const onTimeSlotClick = (time: number, info: any) => {
    if (!getHasPermission(PermissionType.CAN_BOOK_APPOINTMENTS)) {
      return;
    }

    const slotDate = isWeekMode ? info : calendarState.filterParams?.date;
    const slotDateTime = slotDate?.getTime();
    const slotStaffId = isWeekMode ? staffFromFilterParams : info.id;

    if (selectModeOn) {
      closeSlotSelect();

      if (isSelectSlotMode && rescheduleItemId) {
        setRescheduleItemDate(slotDate);
        setRescheduleItemTime(time);
        setRescheduleItemStaff(slotStaffId);

        navigate(`${RoutePath.APPOINTMENT_UPDATE}/${rescheduleItemId}`);

        setRescheduleItemId('');
        return;
      }

      if (isSelectSlotMode) {
        return showModal({
          type: 'CREATE_BLOCK_TIME',
          modalProps: {
            onSuccess: calendarState.fetchData,
            initialValues: {
              employeeId: slotStaffId,
              locationId: calendarState.filterParams.location,
              date: formatDateField(slotDate ?? new Date()),
              startTime: time,
              endTime: time + 30,
            },
          },
        });
      }

      return planType === PriceType.FREE && (locationsCount > 1 || staffCount > 5)
        ? showModal({
            type: 'LATEST_PAYMENT_FAILED',
            modalProps: { initialValues: { planType, planId } },
          })
        : navigate(
            `${RoutePath.APPOINTMENT_CREATE}?startTime=${time}&employeeId=${slotStaffId}&locationId=${calendarState.filterParams?.location}&date=${slotDateTime}`
          );
    }

    return planType === PriceType.FREE && (locationsCount > 1 || staffCount > 5)
      ? showModal({
          type: 'LATEST_PAYMENT_FAILED',
          modalProps: { initialValues: { planType, planId } },
        })
      : navigate(
          `${RoutePath.APPOINTMENT_CREATE}?startTime=${time}&employeeId=${slotStaffId}&locationId=${calendarState.filterParams?.location}&date=${slotDateTime}`
        );
  };

  const onCardClick = (timeCard: TimeCardData) =>
    timeCard.isBlocked
      ? showModal({
          type: 'CREATE_BLOCK_TIME',
          modalProps: {
            id: timeCard.id,
            onSuccess: calendarState.fetchData,
            initialValues: extractBlockTimeValues(timeCard),
          },
        })
      : navigate(RoutePath.APPOINTMENT_DETAILS, timeCard.appointmentId);

  const setPosition = (timeCard: TimeCardData, startTime: any, nextColumnIndex: number, indexChanged: true) => {
    const columnInfo = calendarState?.calendarData?.[nextColumnIndex]?.info;
    const nextColumn = isWeekMode ? columnInfo : columnInfo?.id;
    const action = () =>
      service.updateBookingPosition(timeCard, startTime, nextColumn, isWeekMode).then(() => calendarState.fetchData());

    if (timeCard.isCompoundAppointment && indexChanged && viewType !== 'day') {
      return new Promise((resolve) =>
        showModal({
          type: 'CONFIRM_ACTION',
          modalProps: {
            onExit: () => calendarState.fetchData().then(() => setVersion(version + 1)),
            label: 'modal.splitAppointment.text',
            action: () => action().then(resolve),
            title: 'modal.splitAppointment.title',
          },
        })
      );
    } else {
      return action();
    }
  };

  const updateSize = (timeCard: TimeCardData, endTime: any) =>
    service.updateBookingSize(timeCard, endTime).then(() => calendarState.fetchData());

  const getMinTimeStartWorkingHours = () =>
    Math.min(
      ...calendarState.calendarData
        .map(({ workingHours }) => workingHours.map(({ startTime }) => roundDown(startTime)))
        .flat()
    ) || 0;

  const getMaxTimeEndWorkingHours = () =>
    Math.max(
      ...calendarState.calendarData
        .map(({ workingHours }) => workingHours.map(({ endTime }) => roundUp(endTime)))
        .flat()
    ) || 0;

  const getMinTimeStartFirstBooking = () =>
    Math.min(
      ...calendarState.calendarData.map(({ bookings }) => bookings.map(({ startTime }) => roundDown(startTime))).flat()
    ) || 0;

  const getMaxTimeEndLastBooking = () =>
    Math.max(
      ...calendarState.calendarData.map(({ bookings }) => bookings.map(({ endTime }) => roundUp(endTime))).flat()
    ) || 0;

  const workingHoursStartTime = Math.min(getMinTimeStartWorkingHours(), getMinTimeStartFirstBooking());
  const workingHoursEndTime = Math.max(getMaxTimeEndWorkingHours(), getMaxTimeEndLastBooking());
  const isLoading = calendarState.isLoading || isPlanStaffLocLoading || !customViewType;

  const checkBookings = calendarState.calendarData.reduce((acc, { bookings }) => acc + bookings.length, 0);

  calendarState.calendarData.forEach((data) => {
    if (data.workingHours.length) {
      const workingHours = (data.workingHours[0].endTime - data.workingHours[0].startTime) / 60;

      hours.push(workingHours);
    }
  });

  const checkWorkingHours = hours.reduce((acc, val) => acc + val, 0);

  const displayCalendar =
    (customViewType === StateCalendar.WORKING_HOUR && (checkBookings || checkWorkingHours)) ||
    customViewType === StateCalendar.FULL_CALENDAR ||
    customViewType === StateCalendar.CUSTOM_RANGE;

  useEffect(() => {
    setIsDisplayCalendar(Boolean(displayCalendar));
  }, [displayCalendar]);

  const displayBtnShowAllTimes =
    isDisplayBtnShowAllTimes && isDisplayCalendar && customViewType !== StateCalendar.FULL_CALENDAR;

  const displayPremiumDeactivatedNotification =
    (subscriptionCancelled || notificationCancelled) &&
    !isPremiumDeactivatedDismiss &&
    isDisplayPremiumDeactivatedAlert;

  const displayPaymentErrorNotification =
    (subscriptionPaymentFailed || notificationPaymentFailed) && !isPaymentErrorDismiss && isDisplayPaymentErrorAlert;

  return (
    <A2HSProvider
      isVisibleUpdateNotification={isVisibleUpdateNotification}
      setIsVisibleUpdateNotification={setIsVisibleUpdateNotification}
      setIsVisibleUpdateBtn={setIsVisibleUpdateBtn}
      setCustomViewType={setCustomViewType}
      isDisplayBtnShowAllTimes={displayBtnShowAllTimes}
      setIsDisplayBtnShowAllTimes={setIsDisplayBtnShowAllTimes}
    >
      <NoInternetConnection>
        <AppLayout
          headerTitle="sidebar.calendar"
          mobileHeader={() => (
            <CalendarMobileHeader
              date={calendarState.filterParams.date}
              headerComponent={CalendarHeader}
              tooltipComponent={CardTooltip}
              timeCardComponent={TimeCardComponent}
              onMove={setPosition}
              onResize={updateSize}
              onCardClick={onCardClick}
              onTimeSlotClick={onTimeSlotClick}
              isSelectSlotMode={selectModeOn}
              staffFromFilterParams={staffFromFilterParams}
              timeFormat={timeFormat}
              isWeekMode={isWeekMode}
              changeDate={calendarState.changeFilter('date')}
              scrollToCurrentTime={scrollToCurrentTimeLine}
            />
          )}
          contentClassName="is-calendar-page"
          customButton={
            <AuthBlock section={PermissionType.CAN_BOOK_APPOINTMENTS}>
              <MultipleAddButton
                actions={[
                  {
                    title: 'appointment.create.menu',
                    action: createAppointment,
                  },
                  {
                    title: 'blocktime.create.menu',
                    action: openSetBlockTime,
                  },
                ]}
              />
            </AuthBlock>
          }
          disableSidebarShade
          noPaddingBottom
          isSubscriptionLoading={isPlanStaffLocLoading}
          planType={planType}
          isVisibleUpdateBtn={isVisibleUpdateBtn}
          setIsVisibleUpdateBtn={setIsVisibleUpdateBtn}
          setIsVisibleUpdateNotification={setIsVisibleUpdateNotification}
        >
          {isOwner && !calendarState.isLoading && (
            <>
              {displayPaymentErrorNotification && (
                <PaymentErrorNotification
                  planType={planType}
                  planId={planId}
                  warningDate={subscriptionPaymentFailed?.warningDate || notificationPaymentFailed?.warningDate}
                  type={subscriptionPaymentFailed?.type || notificationPaymentFailed?.type}
                  setIsDisplayPaymentErrorAlert={setIsDisplayPaymentErrorAlert}
                />
              )}

              {displayPremiumDeactivatedNotification && (
                <PremiumDeactivatedNotification
                  type={subscriptionCancelled?.type || notificationCancelled?.type}
                  setIsDisplayPremiumDeactivatedAlert={setIsDisplayPremiumDeactivatedAlert}
                />
              )}
            </>
          )}

          <BlockTimeSelectBanner isActive={selectModeOn} closeActive={closeSlotSelect} isBlock={isSelectSlotMode} />
          <CalendarFilter
            openSetBlockTime={openSetBlockTime}
            createAppointment={createAppointment}
            timeFormat={timeFormat}
          />
          <Spin spinning={isLoading}>
            <SC.Container isMobile={getIsMobile()}>
              <EpicCalendar
                key={version}
                timeInterval={timeOffset}
                data={calendarState.calendarData}
                isCalendarLoading={calendarState.isLoading}
                modeType={viewType}
                date={calendarState.filterParams.date}
                headerComponent={CalendarHeader}
                tooltipComponent={CardTooltip}
                timeCardComponent={TimeCardComponent}
                onMove={setPosition}
                onResize={updateSize}
                onCardClick={onCardClick}
                onTimeSlotClick={onTimeSlotClick}
                isSelectSlotMode={selectModeOn}
                staffFromFilterParams={staffFromFilterParams}
                timeFormat={timeFormat}
                customViewType={customViewType}
                customViewTime={customViewTime}
                workingHoursStartTime={workingHoursStartTime}
                workingHoursEndTime={workingHoursEndTime}
              />
            </SC.Container>
          </Spin>
        </AppLayout>
      </NoInternetConnection>
    </A2HSProvider>
  );
}

export default observer(CalendarPage);
