import { LabelKey } from '@plandok/i18n';
import last from 'lodash/last';
import { FORM_ERROR, setIn } from 'final-form';
import { Dispatch, SetStateAction } from 'react';

export const composeValidators =
  (...validators: any) =>
  (value: any) =>
    validators.reduce((error: any, validator: any) => error || validator(value), undefined);

export const validateRequired = (value: unknown) =>
  (value ? (typeof value === 'string' ? value : ((value || '') as any).toString()).replace(/\s+/g, '') : value === 0)
    ? undefined
    : 'validation.required';

export const customRequired = (label: LabelKey) => (val: unknown) => validateRequired(val) ? label : undefined;

const EMAIL_REGEXP =
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail = (value: unknown) => {
  if (value == null) {
    return undefined;
  }

  return EMAIL_REGEXP.test(((typeof value === 'string' ? value : ((value || '') as any).toString()) || '').trim())
    ? undefined
    : 'validation.email.format';
};

export const validatePassword = (value: string) =>
  value && /^(?=.*?[A-Za-z])(?=.*?[0-9]).{7,}$/.test(value)
    ? undefined
    : (value ? value.length : 0) < 7
    ? 'validation.password.length'
    : 'validation.password.letters';

export const validateLength = (maxLength: number) => (value: string) =>
  !value || value?.length < maxLength ? undefined : 'validation.characters.exceeded';

export const validatePhone = (value: string) => {
  if (!last(value.split('-'))?.length) {
    return undefined;
  }

  return value?.includes('+') && value?.length > 10 ? undefined : 'validation.required';
};

export const validateMinLength = (minLength: number) => (value: string) =>
  !value || value?.length >= minLength ? undefined : 'validation.characters.minLength';

export const validateOneOfTheRequiredFiles = (
  fields: string[],
  oneOfTheRequiredFields: string[],
  setIsDisplayWarning: Dispatch<SetStateAction<boolean>>,
  isWasClickedOnHidden: boolean
) => {
  const checkOneOfTheRequiredFields = fields.some((fieldName: string) => {
    return oneOfTheRequiredFields.includes(fieldName);
  });
  setIsDisplayWarning(isWasClickedOnHidden ? false : !checkOneOfTheRequiredFields);

  if (!checkOneOfTheRequiredFields) {
    let errors = {};
    const setError = (key: string, value: string) => (errors = setIn(errors, key, value));
    setError(FORM_ERROR, 'None of the required fields are filled in');
    return errors;
  }
};
