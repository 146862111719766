import React, { useState } from 'react';
import { Drawer, Icon } from 'antd';
import { SelectInput, Text } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import * as SC from './styles';

type SelectOption = { code: string; name: string };

type ReportsMobileFilterProps = {
  isVisible: boolean;
  staffOptions: SelectOption[];
  locationOptions: SelectOption[];
  selectedStaffValue: string;
  selectedLocationValue: string;
  setSelectedStaffValue: any;
  setSelectedLocationValue: any;
  onClose: () => void;
  clearFilters: () => void;
  selectAllStaffValues: () => void;
  selectAllLocationValues: () => void;
};

const ReportsMobileFilter = ({
  isVisible,
  staffOptions,
  locationOptions,
  selectedLocationValue,
  selectedStaffValue,
  onClose,
  setSelectedStaffValue,
  setSelectedLocationValue,
  selectAllStaffValues,
  selectAllLocationValues,
}: ReportsMobileFilterProps) => {
  const [isFiltersCleared, setIsFiltersCleared] = useState(false);

  const onClearFilters = () => {
    setSelectedStaffValue('');
    setSelectedLocationValue('');
    setIsFiltersCleared(true);
  };

  const onSubmit = () => {
    if (isFiltersCleared) {
      setSelectedLocationValue('');
      setSelectedStaffValue('');
    }
    setSelectedLocationValue(selectedLocationValue);
    setSelectedStaffValue(selectedStaffValue);
    onClose();
  };

  return (
    <Drawer
      width="300px"
      placement="right"
      bodyStyle={{ padding: '20px' }}
      closable={false}
      onClose={onClose}
      visible={isVisible}
    >
      <SC.CloseMenu onClick={onClose}>
        <Icon type="close-circle" />
      </SC.CloseMenu>
      <Text size="base" colorType="base" weight="semiBold" mb="none" isCenter>
        <IntlLabel label="reports.filters.title" />
      </Text>
      <SC.TitleDivider />
      <SC.SelectTitle size="small" colorType="base" weight="normal" mb="none">
        <IntlLabel label="reports.staff.mobile.title" />
        <SelectInput
          options={staffOptions}
          placeholder={<IntlLabel label="reports.filterStaff.placeholder" />}
          value={selectedStaffValue}
          defaultValue="reports.filterStaff.placeholder"
          onChange={setSelectedStaffValue}
          onDefaultChange={selectAllStaffValues}
          dropdownStyle={{ minWidth: 'max-content' }}
        />
      </SC.SelectTitle>
      <SC.SelectTitle size="small" colorType="base" weight="normal" mb="none">
        <IntlLabel label="reports.locations.mobile.title" />
        <SelectInput
          options={locationOptions}
          value={selectedLocationValue}
          defaultValue="reports.filterLocations.placeholder"
          placeholder={<IntlLabel label="reports.filterLocations.placeholder" />}
          onChange={setSelectedLocationValue}
          onDefaultChange={selectAllLocationValues}
          dropdownStyle={{ minWidth: 'max-content' }}
        />
      </SC.SelectTitle>

      <SC.FilterActionsWrapper>
        <SC.ClearFilterButton upperCase={false} type="link">
          <Text size="base" color="link" weight="normal" mb="none" onClick={onClearFilters}>
            <IntlLabel label="reports.clearFilters.button.title" />
          </Text>
        </SC.ClearFilterButton>
        <SC.ApplyButton
          label="reports.filters.apply.title"
          upperCase={false}
          color="primary"
          onClick={onSubmit}
          minorBtn
        />
      </SC.FilterActionsWrapper>
    </Drawer>
  );
};

export default ReportsMobileFilter;
