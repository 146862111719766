import styled, { css } from 'styled-components';
import { Button } from '@plandok/core';

type UndoProps = {
  isMobile: boolean;
};

export const UndoContainer = styled.div<UndoProps>`
  position: fixed;
  bottom: 3rem;
  z-index: 15;
  background: #0a1c43;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  left: 10rem;
  border-radius: 10px;
  min-width: max-content;

  ${({ isMobile }) =>
    isMobile &&
    css`
      bottom: 5rem;
      left: 1rem;
      width: max-content;
      padding: 11px 16px;
    `}
`;

export const BtnUndo = styled(Button)<UndoProps>`
  background: #26385b;
  padding: 10px 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-left: 16px;
  display: inherit;
  align-items: center;
  height: 40px;
  :hover {
    background: #59698b;
  }
  ${({ isMobile }) =>
    isMobile &&
    css`
      height: 32px;
      padding: 8px 16px;
      font-size: 14px;
      margin-left: 12px;
    `}
`;

export const BtnClose = styled(Button)<UndoProps>`
  background: inherit;
  padding: 15px;
  margin-left: 16px;
  display: inherit;
  align-items: center;
  height: 40px;
  :hover {
    background: #59698b;
  }
  ${({ isMobile }) =>
    isMobile &&
    css`
      height: 32px;
      margin-left: 28px;
    `}
`;
