// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from '../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OnlineBookingSettingsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type OnlineBookingSettingsQuery = {
  __typename?: 'AppQuery';
  onlineBookingSettings?: {
    __typename?: 'App_OnlineBookingSettings';
    earliestAppointment: Types.App_EarliestAppointmentSetting;
    latestAppointment: Types.App_LatestAppointmentSetting;
    timeSlotInterval: Types.App_TimeSlotIntervalSetting;
    allowEmployeeSelection: boolean;
    importantInformation?: string | null;
    appointmentChangeTime: Types.App_AppointmentChangeTimeSetting;
    notifyBookedEmployee: boolean;
    notifySpecificEmail: boolean;
    notificationSpecificEmail?: string | null;
    partnerSlug?: string | null;
    onlineRequiredField?: Array<Types.App_OnlineRequiredField> | null;
    onlineOptionalField?: Array<Types.App_OnlineRequiredField> | null;
    onlineHiddenField?: Array<Types.App_OnlineRequiredField> | null;
    serviceLimit: number | null;
  } | null;
};

export const OnlineBookingSettingsDocument = gql`
  query OnlineBookingSettings {
    onlineBookingSettings {
      earliestAppointment
      latestAppointment
      timeSlotInterval
      allowEmployeeSelection
      importantInformation
      appointmentChangeTime
      notifyBookedEmployee
      notifySpecificEmail
      notificationSpecificEmail
      partnerSlug
      onlineRequiredField
      serviceLimit
      placeholderNotes
      onlineOptionalField
      onlineHiddenField
    }
  }
`;

/**
 * __useOnlineBookingSettingsQuery__
 *
 * To run a query within a React component, call `useOnlineBookingSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnlineBookingSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnlineBookingSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnlineBookingSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<OnlineBookingSettingsQuery, OnlineBookingSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OnlineBookingSettingsQuery, OnlineBookingSettingsQueryVariables>(
    OnlineBookingSettingsDocument,
    options
  );
}
export function useOnlineBookingSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OnlineBookingSettingsQuery, OnlineBookingSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OnlineBookingSettingsQuery, OnlineBookingSettingsQueryVariables>(
    OnlineBookingSettingsDocument,
    options
  );
}
export type OnlineBookingSettingsQueryHookResult = ReturnType<typeof useOnlineBookingSettingsQuery>;
export type OnlineBookingSettingsLazyQueryHookResult = ReturnType<typeof useOnlineBookingSettingsLazyQuery>;
export type OnlineBookingSettingsQueryResult = Apollo.QueryResult<
  OnlineBookingSettingsQuery,
  OnlineBookingSettingsQueryVariables
>;
