/* eslint-disable */
/*
  Generated file from translation.csv
  to update it - make change in google sheet and run 'yarn generate:file:labels' see more docs in readme.md
*/

const labels = {
  'login.title': 'Inicia sesión en tu cuenta',
  'label.email': 'Dirección de correo electrónico',
  'placeholder.email': 'e.j. johndoe@gmail.com',
  'input.password.label': 'Contraseña',
  'input.password.placeholder': 'Introducir contraseña',
  'login.btn': 'Inicia sesión en Plandok',
  'link.sign.in': 'Inicia sesión',
  'label.password': 'Introducir contraseña',
  'login.forgot.password': '¿Se te olvidó tu contraseña?',
  'login.no.account': '¿No tienes una cuenta?',
  'login.description': 'Si usted está teniendo problemas iniciando sesión puede intentar restablecer su contraseña',
  'register.title': 'Crea una cuenta',
  'register.description':
    'Crear una cuenta gratuita con el software de programación de citas y reservaciones de Plandok ',
  'register.btn': 'Crear mi cuenta',
  'register.agreement.agree': 'Estoy de acuerdo con los',
  'register.agreement.terms': 'Términos del sitio web',
  'register.agreement.policy': 'Política de privacidad',
  'register.agreement.and': 'y',
  'register.agreement.partner': 'Términos de Negocio de los Socios',
  'register.have.account': 'Ya tienes una cuenta ?',
  'register.validation.confirm': 'Antes de continuar, lea y acepte los términos anteriores.',
  'link.sign.up': 'Registrarse',
  'text.alreadyHaveAccount': '¿Ya tienes una cuenta ?',
  'input.company.label': 'Empresa',
  'input.company.placeholder': 'Empresa',
  'input.country.label': 'País',
  'input.country.placeholder': 'Nombre del país',
  'input.timeZone.label': 'Zona horaria',
  'input.timeZone.placeholder': 'GMT +02.00',
  'input.currency.label': 'Moneda',
  'input.language.label': 'Idioma (Language)',
  'input.language.placeholder': 'Inglés',
  'input.currency.placeholder': 'Euro - EUR',
  'reset.title': 'Restablecer la contraseña',
  'reset.btn.text': 'Enviar restablecimiento de contraseña',
  'reset.pwd.btn': 'Restablecer la contraseña',
  'reset.no.password': '¿No desea restablecer la contraseña?',
  'reset.description':
    'Introduzca su dirección de correo electrónico de acceso registrado para recibir un enlace seguro para establecer una nueva contraseña',
  'sidebar.calendar': 'Calendario',
  'sidebar.client': 'Clientes',
  'sidebar.staff': 'Personal',
  'sidebar.service': 'Servicios',
  'sidebar.notification': 'Mensajes',
  'sidebar.settings': 'Ajustes',
  'sidebar.menu': 'Menú',
  'sidebar.analytics': 'Analítica',
  'sidebar.language': 'Idioma (Language)',
  'sidebar.logout': 'Cerrar sesión',
  'user.menu.settings': 'Mi configuración',
  'user.menu.language': 'Cambiar idioma',
  'user.menu.logout': 'Cerrar sesión',
  'input.globalSearch.placeholder': '¿Qué estás buscando ?',
  'input.globalSearch.description': 'Buscar por nombre del cliente, móvil, correo electrónico o número de reserva',
  'title.upcomingAppointments': 'Las próximas citas ',
  'calendar.day': 'Día',
  'calendar.week': 'Semana',
  'calendar.monday': 'lunes',
  'calendar.tuesday': 'martes',
  'calendar.wednesday': 'miércoles',
  'calendar.thursday': 'jueves',
  'calendar.friday': 'viernes',
  'calendar.saturday': 'sábado',
  'calendar.sunday': 'domingo',
  'calendar.mon': 'Lun',
  'calendar.tue': 'Mar',
  'calendar.wed': 'Mie',
  'calendar.thu': 'Jue',
  'calendar.fri': 'Vie',
  'calendar.sat': 'Sab',
  'calendar.sun': 'Dom',
  'calendar.today': 'Hoy',
  'calendar.january': 'Enero',
  'calendar.february': 'Febrero',
  'calendar.march': 'Marzo',
  'calendar.april': 'Abril',
  'calendar.may': 'Mayo',
  'calendar.june': 'Junio',
  'calendar.july': 'Julio',
  'calendar.august': 'Agosto',
  'calendar.september': 'Septiembre',
  'calendar.october': 'Octubre',
  'calendar.november': 'Noviembre',
  'calendar.december': 'Diciembre',
  'calendar.jan': 'Ene',
  'calendar.feb': 'Feb',
  'calendar.mar': 'Mar',
  'calendar.apr': 'Abr',
  'calendar.may.short': 'May',
  'calendar.jun': 'Jun',
  'calendar.jul': 'Jul',
  'calendar.aug': 'Ago',
  'calendar.sept': 'Sep',
  'calendar.oct': 'Oct',
  'calendar.nov': 'Nov',
  'calendar.dec': 'Dic',
  'calendar.allStaff': 'Todas',
  'calendar.workingStaff': 'Trabajando',
  'calendar.tooltip.time': 'Tiempo',
  'calendar.tooltip.category': 'Categoría',
  'calendar.tooltip.service': 'Servicio',
  'calendar.tooltip.staff': 'Empleada',
  'calendar.tooltip.price': 'Precio',
  'calendar.tooltip.repeating': 'Repetición',
  'btn.viewDetails': 'Ver detalles',
  'calendar.tooltip.emptyCustomer': 'Entrar',
  'calendar.tooltip.bookedOnline': 'Reserva en línea',
  'calendar.print.description':
    'Para poder utilizar la función de impresión, su plan debe estar actualizado. Para actualizar visita',
  'calendar.print.appointmentsBetween.title': 'Citas entre',
  'calendar.print.appointmentsAnd.title': 'y',
  'calendar.billing.title': 'Planificación y facturación',
  'calendar.btn.print.title': 'Imprimir',
  'datepicker.next.week': 'La próxima semana',
  'datepicker.in.two.weeks': 'en 2 semanas',
  'datepicker.in.three.weeks': 'en 3 semanas',
  'datepicker.in.four.weeks': 'en 4 semanas',
  'datepicker.in.five.weeks': 'en 5 semanas',
  'datepicker.in.six.weeks': 'en 6 semanas',
  'modal.splitAppointment.title': 'Dividir Cita',
  'modal.splitAppointment.text':
    'Esta reserva es parte de un grupo, moverlo a otro día lo convertirá en una reserva separada. ¿Estás seguro?',
  'blocktime.create.modal.title': 'Horario de bloqueo',
  'blocktime.edit.modal.title': 'Editar Tiempo de bloqueo',
  'appointment.create.menu': 'Cita',
  'blocktime.create.menu': 'Tiempo de bloqueo',
  'appointment.new.title': 'Nueva cita',
  'input.startTime.label': 'Hora de inicio',
  'input.endTime.label': 'Hora de finalización',
  'input.startDate.label': 'Fecha de inicio',
  'input.endDate.label': 'Fecha final',
  'input.startDate.description.label': 'Seleccione la fecha de inicio',
  'input.endDate.description.label': 'Seleccione la fecha final,',
  'input.closedDatedDescription.placeholder': 'p.ej. vacaciones',
  'input.service.label': 'Servicio',
  'input.services.label': 'servicios',
  'input.service.placeholder': 'Elija un servicio',
  'input.staff.label': 'Personal',
  'input.staff.placeholder': 'Seleccionar el personal',
  'input.pickDateAndTime.title': 'Elegir fecha y hora',
  'input.pickDate.title': 'Elige una fecha',
  'service.add.another': 'Añadir otro servicio',
  'input.appointmentNotes.label': 'Notas de las citas',
  'input.emailSubject.placeholder': 'Nota sobre su cita',
  'input.appointmentNotes.placeholder': 'Añadir nota a la cita (visible para el personal solamente)',
  'input.searchClients.placeholder': 'Buscar Cliente',
  'input.searchService.placeholder': 'Buscar',
  'appointment.empty.list':
    'Use la búsqueda para agregar al cliente, o mantener vacío para guardar como sin previa cita.',
  'appointment.clients.empty.list': 'La lista de clientes está vacía.',
  'appointment.clients.addClient': 'Crear cliente',
  'client.create.new': 'Crear nuevo cliente',
  'client.choose': 'Selecciona el cliente',
  'client.menu.edit': 'Editar detalles del cliente',
  'client.menu.remove': 'Eliminar cita',
  'appointment.btn.save': 'Guardar',
  'appointment.vat': 'IVA ({0}%)',
  'appointment.total': 'Total',
  'appointment.view.title': 'Ver cita',
  'appointment.status.btn': 'Status de cita',
  'appointment.btn.cancel': 'Borrar',
  'btn.cancelAppointment': 'Eliminar',
  'modal.cancelAppointment.title': 'Eliminar',
  'title.clients': 'Clientes',
  'client.create.title': 'Cliente nuevo',
  'client.options.export.title': 'Exportar clientes',
  'client.options.export.description': 'Descargar CSV con datos del cliente',
  'client.options.import.title': 'Importar clientes',
  'client.export.premium.description': 'Para utilizar la función Exportar, su plan debe estar actualizado.',
  'client.export.premium.visit': 'Para actualizar visita ',
  'client.import.instructions.description':
    'Siga las instrucciones a continuación para importar clientes a su cuenta Plandok',
  'client.import.instructions.download': 'Descargue su archivo de importación usando el botón a continuación',
  'client.import.instructions.copy': 'Copie y pegue los datos de su cliente en el archivo',
  'client.import.instructions.email': 'Envíenos un correo electrónico a info@plandok.com',
  'client.import.instructions.account': 'y lo importaremos a tu cuenta',
  'table.clients.name': 'Nombre',
  'table.clients.phone': 'Teléfono',
  'table.clients.mobile': 'Teléfono',
  'table.clients.email': 'Correo electrónico',
  'table.clients.gender': 'Género',
  'client.page.title': 'Perfil del cliente',
  'btn.more.options': 'Más opciones',
  'btn.showAll': 'Mostrar todo',
  'btn.collapse': 'Desagrupar',
  'btn.reschedule': 'Reprogramar',
  'title.edit.client': 'Editar Cliente',
  'menu.edit.client': 'Editar cliente',
  'menu.delete.client': 'Eliminar cliente',
  'title.mobilePhone': 'Teléfono móvil',
  'title.telephoneNumber': 'Número de teléfono',
  'title.email': 'Correo electrónico',
  'title.address': 'Dirección',
  'title.dateOfBirth': 'Fecha de nacimiento',
  'title.gender': 'Género',
  'client.statistics': 'Estadísticas',
  'client.totalSales': 'Ventas totales',
  'client.totalBookings': 'Reservas Totales',
  'client.outstanding': 'Pendientes',
  'client.allBooking': 'Todas las reservaciones',
  'client.completed': 'Terminadas',
  'client.cancelled': 'Canceladas',
  'client.noShow': 'No mostrar',
  'appointment.list.title': 'Reservas',
  'upcoming.list.title': 'Próximas',
  'past.list.title': 'Pasadas',
  'modal.confirmClientDelete.text.0': '¿Seguro que desea borrar este cliente permanentemente?',
  'modal.confirmClientDelete.text.1': '¿de la base de datos?',
  'input.date.label': 'Fecha específica',
  'input.date.placeholder': 'Seleccione fecha',
  'input.firstName.label': 'Nombre',
  'input.firstName.placeholder': 'e.j. John',
  'input.lastName.label': 'Apellido',
  'input.lastName.placeholder': 'e.j. Doe',
  'input.mobile.label': 'Número de teléfono móvil',
  'input.mobile.placeholder': 'Ingresar número',
  'input.email.label': 'Dirección de correo electrónico',
  'input.email.placeholder': 'e.j. johndoe@gmail.com',
  'input.title.label': 'Título',
  'input.title.placeholder': 'p.ej. Dentista',
  'input.notificationType.label': 'Enviar una notificación por',
  'tab.personalInfo': 'Información personal',
  'input.gender.label': 'Género',
  'input.referal.label': 'Fuente de referencia',
  'input.referal.placeholder': 'Seleccionar fuente',
  'input.birthDay.label': 'Fecha de nacimiento',
  'input.month.placeholder': 'Mes',
  'input.day.placeholder': 'Día',
  'input.year.placeholder': 'Año',
  'input.clientsNotes.label': 'Notas de clientes',
  'input.clientsNotes.placeholder': 'Introducir notas de clientes',
  'input.bookingDisplay.label': 'Mostrar en todas las reservas',
  'input.serviceDescription.label': 'Descripción del servicio',
  'input.serviceDescription.placeholder':
    'Añadir una breve descripción que será visible para los clientes en las reservas online',
  'input.isBookableOnline.label': 'Puede reservar online',
  'service.onlineBookings.title': 'Reservas online',
  'tab.address': 'Dirección',
  'input.address.label': 'Dirección',
  'input.address.placeholder': 'Introduzca su dirección',
  'input.city.label': 'Ciudad',
  'input.city.placeholder': 'Introduzca la ciudad',
  'input.zip.label': 'Zip / Código postal',
  'input.zip.placeholder': 'Introducir código postal',
  'btn.edit': 'Editar',
  'btn.cancel': 'Cancelar',
  'btn.save': 'Guardar',
  'btn.save.changes': 'Guardar',
  'tabs.staffWorking.title': 'Horas Laborales',
  'tabs.staffMembers.title': 'Miembros del personal',
  'tabs.userPermissions.title': 'Permisos de usuario',
  'tabs.closedDates.title': 'Fechas de cierre',
  'btn.new.staff': 'Nuevo integrante',
  'table.staff.name': 'Nombre',
  'table.staff.mobileNumber': 'Teléfono',
  'table.staff.email': 'Correo electrónico',
  'table.staff.userPermission': 'Permisos de usuario',
  'table.staff.dataRange': 'Rango de fechas',
  'table.staff.numberOfDays': 'Número de días',
  'table.staff.location': 'Localización de recursos de información sobre herramientas',
  'table.staff.description': 'Descripción',
  'table.appointmentsList.appointment': 'Cita',
  'table.appointmentsList.client': 'Cliente',
  'table.appointmentsList.service': 'Servicio',
  'table.appointmentsList.date': 'Fecha',
  'table.appointmentsList.time': 'Tiempo',
  'table.appointmentsList.duration': 'Duración',
  'table.appointmentsList.location': 'Ubicación',
  'table.appointmentsList.staff': 'Personal',
  'table.appointmentsList.price': 'Precio',
  'table.appointmentsList.status': 'Estado',
  'table.appointmentsCancellations.ref': 'Árbitro',
  'table.appointmentsCancellations.client': 'Cliente',
  'table.appointmentsCancellations.service': 'Servicio',
  'table.appointmentsCancellations.scheduledDate': 'Fecha programada',
  'table.appointmentsCancellations.cancelledDate': 'Fecha cancelada',
  'table.appointmentsCancellations.cancelledBy': 'Cancelado por',
  'table.appointmentsCancellations.reason': 'Razón',
  'table.appointmentsCancellations.price': 'Precio',
  'table.appointmentsCancellations.totalCount': 'Recuento total',
  'table.clients.appointments': 'Equipo',
  'table.clients.noShows': 'No se presenta',
  'table.clients.totalSales': 'Ventas totales',
  'table.clients.added': 'Agregado',
  'table.clients.daysAbsent': 'Días Ausentes',
  'table.clients.lastAppointment': 'Última cita',
  'table.clients.lastLocation': 'Última ubicación',
  'table.appointmentsByService.serviceName': 'Nombre del servicio',
  'table.appointmentsByService.appointments': 'Equipo',
  'table.appointmentsByService.totalValue': 'Valor total',
  'table.appointmentsByStaff.staffMember': 'Miembro del personal',
  'table.appointmentsByStaff.appointments': 'Equipo',
  'table.appointmentsByStaff.totalValue': 'Valor total',
  'table.pagination.showing.title': 'Demostración',
  'table.pagination.results.title': 'resultados',
  'clients.duplicate.title': 'Se encontraron clientes duplicados.',
  'clients.duplicate.description': 'Hay clientes similares que se pueden fusionar.',
  'clients.merge.btn.title': 'Unir',
  'clients.merge.modal.title': 'Fusionar clientes',
  'clients.merge.selectAll.title': 'Seleccionar todos los duplicados',
  'clients.merge.duplicatesNotFound.title': 'Duplikaate ei leitud',
  'clients.merge.duplicatesNotFound.description': 'Ühitamiseks ei ole kliente saadaval',
  'clients.mergeConfirm.modal.title': 'Confirmar fusión',
  'clients.mergeConfirm.modal.description':
    '¿Está seguro de que desea fusionar {0} duplicados? Esta acción es permanente y no se puede deshacer.',
  'clients.mergeConfirm.checkbox.title': 'Entiendo que la fusión de clientes no se puede deshacer',
  'clients.mergeConfirm.btn.title': 'Confirmar y fusionar',
  'clients.mergeConfirm.cancel.btn.title': 'Cancelar',
  'clients.byNumber.btn.title': 'Por teléfono {0}',
  'clients.byEmail.btn.title': 'Por correo electrónico {0}',
  'clients.byName.btn.title': 'Por nombre {0}',
  'title.newStaff': 'Nuevo integrante',
  'title.editStaff': 'Editar personal',
  'description.extraCharge': 'Al añadir nuevo personal, se añadirá un cargo extra a su suscripción mensual',
  'tabs.details': 'Detalles',
  'input.staffPicture.label': 'Imagen',
  'input.cropImage.title': 'Recortar imagen',
  'input.cropImage.btn.apply.title': 'Aplicar',
  'input.stafffirstName.label': 'Nombre',
  'input.stafffirstName.placeholder': 'e.j. John',
  'input.stafflastName.label': 'Apellido',
  'input.stafflastName.placeholder': 'e.j. Doe',
  'input.stafmobile.label': 'Teléfono',
  'input.stafemail.label': 'Correo electrónico',
  'input.stafemail.placeholder': 'e.j. johndoe@plandok.com',
  'input.permission.label': 'Permisos de usuario',
  'input.uploadProfile.label': 'Crear imagen de perfil',
  'input.changeProfile.label': 'Cambiar foto de perfil',
  'input.notes.label': 'Notas',
  'input.staffNotes.placeholder':
    'Añadir notas privadas visibles en la configuración del personal solamente (opcional)',
  'input.blockTime.placeholder': 'e.j. reunión de almuerzo',
  'tabs.locations': 'Ubicaciones',
  'tabs.integrations': 'Integraciones',
  'input.staffLocations.description': 'Asignar lugares donde este miembro del personal puede ser agendado.',
  'input.multiLocation.description': 'Asignar lugares donde este miembro del personal puede ser agendado.',
  'tabs.services': 'Servicios',
  'input.staffServices.description': 'Asignar los servicios que este miembro del personal puede realizar.',
  'input.multiServices.description': 'Asignar los servicios que este miembro del personal puede realizar.',
  'input.shiftStart.label': 'Inicio de turno',
  'input.shiftEnd.label': 'Fin de turno',
  'edit.staff.title.resendPassword': 'Reenvío de contraseña',
  'edit.staff.info.resendPassword':
    '¿El miembro del personal no recibió un correo electrónico con la contraseña? Haga clic en uno de los botones a continuación para reenviar el correo electrónico con instrucciones para configurar la contraseña.',
  'edit.staff.button.resendPassword': 'Reenviar correo electrónico de contraseña',
  'btn.add.shift': 'Añadir otro turno',
  'break.time': '{0} tiempo de descanso',
  'input.repeat.label': 'Repetir',
  'input.repeatWeekly.label': 'Semanal',
  'input.repeatDoNot.label': 'No repetir',
  'input.endRepeat.label': 'Repetir finales',
  'input.endRepeatOngoing.label': 'En marcha',
  'input.endRepeatSpecificDate.label': 'Fecha específica',
  'input.endRepeatDate.placeholder': 'Elige la fecha',
  'btn.deleteUpcomingShift': 'Eliminar turnos siguientes',
  'btn.deleteThisShift': 'Eliminar solamente este turno',
  'btn.updateUpcomingShift': 'Actualizar próximos turnos',
  'btn.updateThisShift': 'Actuailzar este turno solamente',
  'modal.repeatShift.title': 'Repetir turno',
  'form.error.uniqShifts': 'Los turnos deben ser únicos',
  'btn.add.new': 'Añadir nuevo',
  'btn.addNewService': 'Añadir nuevo servicio',
  'btn.newCategory': 'Nueva categoría',
  'btn.newServiceGroup': 'Nueva categoría',
  'btn.editCategory': 'Editar categoria',
  'btn.deleteCategory': 'Eliminar categoría',
  'btn.downloadImportFile': 'Descargar archivo de importación',
  'serviceGroup.delete.success': '¡Categoría eliminada exitosamente!',
  'service.new.title': 'Nuevo Servicio',
  'service.edit.title': 'Editar Servicio',
  'input.serviceName.label': 'Nombre del Servicio',
  'input.serviceName.placeholder': 'e.j. Masaje',
  'input.serviceCategory.label': 'Categoría de servicio',
  'input.serviceCategory.placeholder': 'Seleccionar categoría de servicio',
  'input.price.label': 'Precio',
  'input.price.placeholder': '0',
  'input.duration.label': 'Duración',
  'input.duration.placeholder': 'Duración',
  'input.staffSelect.description': 'Seleccionar el personal que realiza este servicio',
  'input.newCategoryName.label': 'Nombre de la categoría',
  'input.newCategoryDescription.label': 'Descripción de la categoría',
  'input.newCategoryDescription.placeholder': 'Describe esta categoría, se mostrará en Reservas online',
  'modal.newCategory.title': 'Nueva categoría',
  'modal.editCategory.title': 'Editar categoria',
  'input.chooseCategory.label': 'Escoja una categoría por color:',
  'client.messages.title': 'Mensajes de clientes',
  'tabs.messagesLog': 'mensajes de registro',
  'tabs.messagesLog.description': 'Lista de mensajes enviados a tus clientes',
  'tabs.messagesSettings': 'Ajustes',
  'tabs.messagesSettings.description':
    'Ajuste la configuración de sus mensajes para que se ajuste a las necesidades de su negocio',
  'table.header.timeSent': 'Enviados',
  'table.header.client': 'Cliente',
  'table.header.appointment': 'Cita',
  'table.header.destination': 'Dirección',
  'table.header.type': 'Tipo',
  'table.header.message': 'Mensaje',
  'table.header.status': 'Estado',
  'table.header.cost': 'Costo',
  'modal.message.title': 'Ver mensaje',
  'setup.page.title': 'Ajustes',
  'title.accountSettings': 'Configuraciones de la cuenta',
  'title.accountSetup': 'Configuración de la cuenta',
  'title.accountSetup.description': 'Gestiona opciones como tu nombre comercial y zona horaria',
  'resources.list.title': 'Recursos',
  'title.resources': 'Recursos',
  'title.resources.description': 'Gestiona los recursos agendados, tales como salas, sillas o equipo',
  'title.locations': 'Ubicaciones',
  'title.locations.description': 'Gestión de múltiples puntos de venta para tu negocio',
  'calendar.settings.title': 'Ajustes de calendario',
  'calendar.btn.selectFromCalendar': 'Seleccione en el calendario',
  'title.calendarSettings': 'Ajustes de calendario',
  'title.calendarSettings.description': 'Ajustar la combinación de colores y el diseño de su calendario',
  'title.clientSettings': 'Configuración de clientes',
  'title.onlineBookingSettings': 'Configuración de las reservas en línea',
  'title.salesSettings': 'Ajustes de ventas',
  'title.receiptSequencing': 'Secuenciación de recibos',
  'title.receiptSequencing.description':
    'Configure los detalles mostrados en los recibos de venta emitidos a sus clientes',
  'title.receiptTemplate': 'Plantilla de recibo',
  'title.taxes.description': 'Gestione los tipos impositivos que se aplican a los artículos vendidos en la caja',
  'title.paymentTypes': 'Tipos de pago',
  'title.paymentTypes.description': 'Configure tipos de pago manuales para su uso durante el proceso de pago',
  'title.discounts.description': 'Configure sus descuentos',
  'title.receiptPrefix': 'Recibo №. Prefijo',
  'title.receiptNumber': 'Siguiente número de recibo',
  'title.change': 'Cambiar',
  'title.saleReceiptTemplate': 'Plantilla de recibos de venta',
  'description.saleReceiptTemplate':
    'Personalice el contenido que aparece en los recibos de venta emitidos a sus clientes',
  'setting.automaticallyPrint': 'Imprima automáticamente los recibos al finalizar la venta',
  'setting.showMobile': 'Mostrar el móvil y correo electrónico del cliente en el recibo de venta',
  'setting.showAddress': 'Mostrar la dirección del cliente en el recibo de venta',
  'title.receiptTitle': 'Título del recibo',
  'title.referrals': 'Fuentes de referencia',
  'title.referrals.description':
    'Configuración fuentes personalizadas para rastrear como el cliente encontró tu empresa/negocio',
  'title.clientNotifications': 'Configuración de notificaciones del cliente',
  'title.clientNotifications.description': 'Administrar los mensajes que se envían a sus clientes acerca de sus citas',
  'cancelReason.list.title': 'Razones de cancelación',
  'title.cancellationReasons': 'Razones de cancelación',
  'title.cancellationReasons.description': 'Realizar un análisis de por qué los clientes no llegan a sus citas',
  'title.onlineBooking.description': 'Gestionar la disponibilidad y la configuración de las reservas en línea',
  'title.pointOfSale': 'Punto de venta',
  'discount.list.title': 'Descuentos',
  'title.discountTypes': 'Tipos de descuento',
  'title.discountTypes.description': 'Configurar los tipos de descuento manuales para usarlos al comprar.',
  'title.premiumFeature': 'Característica prémium',
  'company.details.title': 'Detalles de la compañía',
  'input.businessName.label': 'Nombre del Negocio',
  'input.businessName.placeholder': 'e.j. Eaglewings',
  'input.description.label': 'Descripción',
  'input.description.placeholder': 'Introducir descripción',
  'input.businessAddress.label': 'Dirección',
  'input.businessAddress.placeholder': 'Ingresar dirección',
  'input.website.label': 'Sitio web',
  'input.website.placeholder': 'Ingresar nombre del sitio web',
  'input.contactNumber.label': 'Número de contacto',
  'input.contactNumber.placeholder': 'Ingresar número',
  'input.businessTimeZone.label': 'Zona horaria',
  'input.businessTimeZone.placeholder': 'GMT +02.00',
  'input.businessCountry.label': 'País',
  'input.businessCountry.placeholder': 'Elige país',
  'input.timeFormat.label': 'Formato de tiempo',
  'input.timeFormat.placeholder': '24 horas',
  'table.header.resourceName': 'Recurso',
  'btn.new.resource': 'Nuevo recurso',
  'input.resourceName.label': 'Nombre del recurso',
  'input.resourceName.placeholder': 'Ingresar nombre',
  'input.resourceDescription.label': 'Descripción',
  'input.resourceDescription.placeholder': 'Rellentar texto',
  'input.resourceLocation.placeholder': 'Nombre del lugar',
  'resourcesTooltip.location': 'Localización de recursos de información sobre herramientas',
  'modal.newResource.title': 'Crear nuevo Recurso',
  'modal.editResource.title': 'Editar recurso',
  'table.header.locationName': 'Ubicación',
  'table.header.locatonAddress': 'Dirección',
  'table.header.location.Phone': 'Teléfono',
  'modal.newLocation.title': 'Nueva ubicacion',
  'modal.editLocation.title': 'Editar ubicación',
  'btn.new.location': 'Nueva ubicación',
  'btn.addExtraLocation': 'Añadir ubicación adicional',
  'input.locationName.label': 'Nombre de ubicación',
  'input.locationName.placeholder': 'Ingresa una ubicación',
  'input.profile.locationName.placeholder': 'P.ej. Peluquería Salón',
  'input.slug.label': 'Slug',
  'input.locationTips.label': 'Consejos de ubicación',
  'input.slug.explanation': 'Será visible en su ubicación URL',
  'input.contactEmail.label': 'Email de contacto',
  'input.locationAddress.label': 'Dirección',
  'input.locationAddress.placeholder': 'Introduzca dirección de la ubicación',
  'tooltip.contactEmail': 'Respuestas a las citas de los clientes notificaciones irán a este correo electrónico.',
  'input.appointmentColors.label': 'Color de la cita',
  'input.colorBy.service': 'Por grupo de servicio',
  'input.colorBy.employee': 'Por empleado',
  'input.colorBy.appointment': 'Por status de las reservaciones',
  'input.timeSlot.label': 'Intervalo de tiempo',
  'input.defaultViewType.label': 'Vista predeterminada',
  'input.calendarTimeRange.label': 'Intervalo horario del calendario',
  'input.calendarTimeRange.allTimes.option': 'Todas las horas (00:00 - 24:00)',
  'input.calendarTimeRange.onlyWorkingHours.option': 'Sólo horas laborables',
  'input.calendarTimeRange.customRange.option': 'Rango personalizado',
  'input.weekStart.label': 'Día de inicio de semana',
  'table.header.refSourcetitle': 'Fuente de referencia',
  'table.header.refSourceAddDate': 'Fecha Agregada',
  'btn.new.referral': 'Nueva Fuente de Referencia',
  'input.nameRefSource.label': 'Nombre Fuente de referencia',
  'input.nameRefSource.placeholder': 'e.j. anuncios de Facebook',
  'modal.newRefSource.title': 'Nueva Fuente de Referencia',
  'modal.editRefSource.title': 'Editar Fuente de Referencia',
  'input.sendBy.label': 'Enviado por',
  'input.emailSubject.label': 'Asunto del email',
  'input.emailTemplate.label': 'Plantilla de correo electrónico',
  'input.emailTemplate.placeholder': 'Introduzca plantilla de correo electrónico',
  'input.subject.label': 'Asunto del email',
  'input.subject.placeholder': 'Introduzca asunto del correo electrónico',
  'title.messagingSettings.label': 'Ajustes de mensajería',
  'title.messagingSettings.description':
    'La configuración de tipos de mensaje de aquí se enviarán automáticamente a los clientes. Todos los mensajes generados podrán ser mostrados en la página de mensajes (en el menú principal). Ajusta la configuración de cómo y cuando se envían mensajes y edita las plantillas para personalizar el texto. Utilice las siguientes etiquetas para incluir detalles de la cita dentro de los mensajes:',
  'client.first.name': 'CLIENT_FIRST_NAME',
  'client.last.name': 'CLIENT_LAST_NAME',
  'staff.first.name': 'STAFF_FIRST_NAME',
  'staff.last.name': 'STAFF_LAST_NAME',
  'booking.date.time': 'BOOKING_DATE_TIME',
  'booking.date': 'BOOKING_DATE',
  'booking.time': 'BOOKING_TIME',
  'service.name': 'SERVICE_NAME',
  'business.name': 'BUSINESS_NAME',
  'business.phone': 'BUSINESS_PHONE',
  'business.address': 'BUSINESS_ADDRESS',
  'business.email': 'BUSINESS_EMAIL',
  'location.name': 'LOCATION_NAME',
  'location.phone': 'LOCATION_PHONE',
  'location.address': 'LOCATION_ADDRESS',
  'location.email': 'LOCATION_EMAIL',
  'tabs.reminders.title': 'Recordatorios',
  'input.enableReminder.label': 'Habilitar mensajes de recordatorios',
  'input.enableReminder.description': 'Envía automáticamente a los clientes al aproximarse a su próxima cita',
  'input.reminder.title': 'Recordatorio {0}',
  'input.message.title': 'Mensaje {0}',
  'input.reminder.description': 'Canales y tiempo de envío',
  'input.reminder.channels': 'Seleccione los canales en los que se enviará este mensaje',
  'input.reminderBefore.label': 'Se enviarán recordatorios antes',
  'input.smsSender.label': 'SMS ID del remitente',
  'input.messageSender.label': 'ID del remitente del mensaje',
  'input.smsSender.placeholder': 'Introduzca SMS ID del remitente',
  'input.smsTemplate.label': 'Plantilla de mensaje de texto',
  'input.messageTemplate.label': 'Plantilla de mensaje',
  'input.smsTemplate.placeholder': 'Introduzca plantilla de mensaje de texto',
  'input.whatsAppSender.placeholder': 'Plandok',
  'reminder.whatsApp.notificate':
    'Actualmente, la plantilla de mensajes de WhatsApp no es editable debido a limitaciones. Sin embargo, estamos trabajando activamente para que sea totalmente editable.',
  'reminder.charactersCount': 'Los caracteres cuentan:',
  'reminder.pricing': 'Precios',
  'modal.pricing.description':
    'Cada país tiene una tarifa diferente. Ofrecemos la mejor tarifa posible de nuestros socios. Puede variar.',
  'modal.pricing.subDescription':
    'Los cargos se aplicarán una vez enviados los mensajes y se añadirán a la próxima factura.',
  'modal.pricing.counterDescription':
    'Un SMS de texto estándar está limitado a 160 caracteres y a 70 caracteres para mensajes con caracteres especiales.',
  'modal.pricing.learnMore': 'Más información',
  'modal.pricing.priceRates': 'Precios',
  'modal.pricing.messagePrice': 'Precio de 1 mensaje:',
  'modal.sendTestMessageByNumber.title': 'Envíe un mensaje de prueba a este número:',
  'modal.sendTestMessageByNumber.description': 'Se aplican los mismos cargos que en los mensajes normales.',
  'modal.sendTestMessageByEmail.title': 'Enviar mensaje de prueba a este correo electrónico:',
  'modal.sendTestMessageByEmail.placeholder': 'Por favor, introduzca la dirección de correo electrónico',
  'btn.send': 'Enviar',
  'reminder.sendTestMessage': 'Enviar mensaje de prueba',
  'button.reminder.addTag': 'Añadir etiqueta',
  'button.reminder.addAnotherReminder': 'AGREGAR OTRO RECORDATORIO',
  'button.message.addAnotherMessage': 'AÑADIR OTRO MENSAJE',
  'button.reminder.upgrade': 'Actualizar para acceder',
  'label.emailSettings': 'Configuración de Email',
  'label.SMSSettings': 'Configuración de SMS',
  'label.whatsAppSettings': 'Configuración de WhatsApp',
  'tooltip.sender.0': 'Será mostrado como nombre del remitente de mensaje de texto',
  'tooltip.sender.1': '11 caracteres como máximo',
  'tooltip.sender.2': 'Puede que no funcione en algunos países',
  'tooltip.sender.3': 'Letras latinas o número solamente',
  'tabs.confirmations.title': 'Confirmación',
  'btn.appBookings': 'Reservas en la aplicación',
  'btn.onlineBookings': 'Reservas en línea',
  'input.enableAppConfirmation.label': 'Activar las confirmaciones de reservas en la aplicación',
  'input.enableOnlineConfirmation.label': 'Activar confirmaciones de reservas en línea',
  'input.enableConfirmationApp.description': 'Envía automáticamente a los clientes cuando se les reserva una cita',
  'input.enableConfirmationOnline.description': 'Se envía automáticamente a los clientes cuando reservan una cita',
  'messageSetup.header': 'Configuración de mensajes',
  'channels.subHeader': 'Canales',
  'tabs.reschedules.title': 'Reprogramar',
  'input.enableReschedule.label': 'Habilitar mensajes de reprogramación',
  'input.enableReschedule.description':
    'Envíar automáticamente a los clientes cuando la fecha inicial de la reservación ha cambiado',
  'tabs.cancellations.title': 'Cancelación',
  'input.enableCancellation.label': 'Activar los mensajes de cancelación',
  'input.enableCancellations.description':
    'Envíar automáticamente a los clientes cuando su reservación ha sido cancelada',
  'tabs.thanks.title': 'Gracias',
  'input.enableThank.label': 'Habilitar mensajes de agradecimiento',
  'input.enableThankyou.description':
    'Mensaje es enviado al cliente cuando el status de la cita está marcada como completada',
  'tabs.lastVisit.title': 'Última visita',
  'input.enableLastVisit.label': 'Habilitar mensajes de última visita',
  'input.enableLastVisit.description':
    'La envía automáticamente a los clientes cuando no han visitado tu negocio por un número indicado de días',
  'table.cancelReason.name': 'Razones de cancelación',
  'table.cancelReason.addDate': 'Fecha Agregada',
  'btn.new.cancelReason': 'Nueva razón de cancelación',
  'modal.newCancelReason.title': 'Nueva razón de cancelación',
  'modal.editCancelReason.title': 'Editar razón de cancelación',
  'input.cancelReason.title': 'Nombre de razón de cancelación',
  'input.cancelReason.placeholder': 'e.g. Cliente no disponible',
  'table.discounts.name': 'Descuentos',
  'table.discounts.value': 'Valor',
  'table.discounts.addDate': 'Fecha de creación',
  'input.discountName.label': 'Nombre del descuento',
  'input.discountName.placeholder': 'e.j. Personas mayores',
  'input.discountValue.label': 'Valor descuento',
  'btn.new.discount': 'Nuevo descuento',
  'input.discountPercentage.label': '% Porcentaje',
  'input.discountSum.label': 'Cantidad',
  'modal.newDiscount.title': 'Nuevo Tipo de descuento',
  'modal.editDiscount.title': 'Editar Descuento',
  'modal.deleteAccount.title': 'Eliminar cuenta',
  'modal.deleteAccount.description':
    'Borrar tu cuenta es un proceso irreversible y todos tus datos serán eliminados para siempre.',
  'modal.deleteAccount.successMessage': 'Tu cuenta se ha eliminado correctamente.',
  'title.mySettings': 'Mi configuración',
  'title.personalDetails': 'Detalles personales',
  'title.personalDetails.description':
    'Configurar nombre y información de contacto, la dirección de e-mail introducida aquí se utiliza para el acceso de inicio de sesión',
  'btn.continueWithGoogle.title': 'Continuar con Google',
  'btn.continueWithFacebook.title': 'Continuar con Facebook',
  'notification.googleAccountUnlinked': 'Cuenta de Google desvinculada',
  'notification.googleAccountLinked': 'Cuenta de Google vinculada',
  'notification.facebookAccountUnlinked': 'Cuenta de Facebook desvinculada',
  'notification.facebookAccountLinked': 'Cuenta de Facebook vinculada',
  'btn.linkAccount': 'Vincular cuenta',
  'btn.unlinkAccount': 'Desvincular cuenta',
  'btn.deleteYourAccount': 'Eliminar su cuenta',
  'input.myName.label': 'Nombre',
  'input.myName.placeholder': 'e.j. John',
  'input.mylastName.label': 'Apellido',
  'input.mylastName.placeholder': 'e.j. Doe',
  'title.changePassword': 'Cambiar contraseña',
  'title.changePassword.description':
    'Para realizar una actualización, introduzca su contraseña actual seguido por su nueva contraseña. Si usted no recuerda su contraseña, puede cerrar la sesión y utilizar el enlace de restablecimiento de contraseña en la página Iniciar sesión.',
  'input.currentPassword.label': 'Contraseña actual',
  'label.currentPassword.placeholder': 'Introducir la contraseña actual',
  'input.newPassword.label': 'Nueva contraseña',
  'input.newPassword.placeholder': 'Ingrese nueva contraseña',
  'input.verifyPassword.label': 'Repita la contraseña',
  'input.verifyPassword.placeholder': 'Repita la nueva contraseña',
  'btn.confirmDelete': 'Sí, eliminar ahora',
  'btn.confirm': 'Confirmar',
  'btn.confirmBook': 'Confirmar y reservar',
  'btn.confirmPay': 'Confirmar y pagar',
  'btn.delete': 'Eliminar',
  'btn.close': 'Cerrar',
  'validation.required': 'Sección requerida',
  'validation.email.format': 'Formato de correo electrónico incorrecto',
  'validation.url.format': 'Formato de url incorrecto, debe empezar por https:// o http://',
  'validation.password.length': 'La contraseña debe tener al menos 7 caracteres',
  'validation.password.letters': 'La contraseña debe contener números y letras',
  'validation.startTime': 'La hora de inicio no puede ser posterior o igual a la hora de finalización',
  'validation.endTime': 'La hora de finalización no puede ser anterior ni igual a la hora de inicio',
  'tooltip.password.title': 'La contraseña debe:',
  'tooltip.password.characters': 'Tener al menos 7 caracteres',
  'tooltip.password.number': 'Contener un número',
  'tooltip.password.letter': 'Contener una letra',
  'tooltip.location': 'Lugar para mensaje de información',
  'footer.support.center': '¿Necesitas ayuda? Contáctanos',
  'not.found': 'No encontrado',
  'input.cancelReason.label': 'Razón de cancelacion',
  'input.location.label': 'Ubicación',
  'input.location.placeholder': 'Nombre del lugar',
  'input.name.label': 'Nombre',
  'title.recentlyAddedClients': 'Clientes (Añadidos recientemente)',
  'title.calendarView': 'Ver Calendario',
  'text.manageProfile': 'Administrar perfil de usuario',
  'modal.language.title': 'Elegir idioma',
  'modal.language.placeholder': 'Búsqueda idioma',
  'dropdown.access.medium': 'Medio',
  'dropdown.access.high': 'Alto',
  'dropdown.access.low': 'Bajo',
  'dropdown.access.basic': 'Básico',
  'dropdown.access.no': 'Sin acceso',
  'dropdown.access.owner': 'Propietario',
  'button.select.all': 'Seleccionar todo',
  'button.selected': 'Seleccionado',
  'dropdown.gender.unknown': 'Desconocido',
  'dropdown.gender.male': 'Masculino',
  'dropdown.gender.female': 'Femenino',
  'dropdown.notificationBy.none': 'No enviar',
  'dropdown.notificationBy.sms': 'SMS',
  'dropdown.notificationBy.email': 'Email',
  'dropdown.notificationBy.combined': 'SMS y correo electrónico',
  'notification.success.login': '¡Inicio de sesión exitoso!',
  'notification.success.register': '¡Registro exitoso!',
  'dropdown.time.minutes': 'minutos',
  'dropdown.repeat.no': 'No repetir',
  'dropdown.repeat.weekly': 'Semanal',
  'dropdown.endRepeat.ongoing': 'En marcha',
  'dropdown.endRepeat.weekly': 'Fecha específica',
  'dropdown.time.appointment.immediately': 'Inmediatamente',
  'dropdown.time.appointment.upTo1Hour': 'Hasta 1 hora',
  'dropdown.time.appointment.upTo2Hours': 'Hasta 2 horas',
  'dropdown.time.appointment.upTo3Hours': 'Hasta 3 horas',
  'dropdown.time.appointment.upTo4Hours': 'Hasta 4 horas',
  'dropdown.time.appointment.upTo5Hours': 'Hasta 5 horas',
  'dropdown.time.appointment.upTo6Hours': 'Hasta 6 horas',
  'dropdown.time.appointment.upTo7Hours': 'Hasta 7 horas',
  'dropdown.time.appointment.upTo8Hours': 'Hasta 8 horas',
  'dropdown.time.appointment.upTo9Hours': 'Hasta 9 horas',
  'dropdown.time.appointment.upTo10Hours': 'Hasta 10 horas',
  'dropdown.time.appointment.upTo11Hours': 'Hasta 11 horas',
  'dropdown.time.appointment.upTo12Hours': 'Hasta 12 horas',
  'dropdown.time.appointment.upTo13Hours': 'Hasta 13 horas',
  'dropdown.time.appointment.upTo14Hours': 'Hasta 14 horas',
  'dropdown.time.appointment.upTo15Hours': 'Hasta 15 horas',
  'dropdown.time.appointment.upTo16Hours': 'Hasta 16 horas',
  'dropdown.time.appointment.upTo17Hours': 'Hasta 17 horas',
  'dropdown.time.appointment.upTo18Hours': 'Hasta 18 horas',
  'dropdown.time.appointment.upTo19Hours': 'Hasta 19 horas',
  'dropdown.time.appointment.upTo20Hours': 'Hasta 20 horas',
  'dropdown.time.appointment.upTo21Hours': 'Hasta 21 horas',
  'dropdown.time.appointment.upTo22Hours': 'Hasta 22 horas',
  'dropdown.time.appointment.upTo23Hours': 'Hasta 23 horas',
  'dropdown.time.appointment.upTo24Hours': 'Hasta 1 día',
  'dropdown.time.appointment.upTo48Hours': 'Hasta 2 días',
  'dropdown.time.appointment.upTo3days': 'Hasta 3 días',
  'dropdown.time.appointment.upTo4days': 'Hasta 4 días',
  'dropdown.time.appointment.upTo5days': 'Hasta 5 días',
  'dropdown.time.appointment.upTo6days': 'Hasta 6 días',
  'dropdown.time.appointment.upTo1week': 'Hasta 1 semana',
  'dropdown.time.appointment.upTo2weeks': 'Hasta 2 semanas',
  'dropdown.time.appointment.upTo15Min': 'Hasta 15 minutos',
  'dropdown.time.appointment.upTo30Min': 'Hasta 30 minutos',
  'dropdown.time.appointment.upTo1Month': 'Hasta 1 mes',
  'dropdown.time.appointment.upTo2Months': 'Hasta 2 meses',
  'dropdown.time.appointment.upTo3Months': 'Hasta 3 meses',
  'dropdown.time.appointment.upTo4Months': 'Hasta 4 meses',
  'dropdown.time.appointment.upTo5Months': 'Hasta 5 meses',
  'dropdown.time.appointment.upTo6Months': 'Hasta 6 meses',
  'dropdown.time.appointment.interval5Min': '5 minutos',
  'dropdown.time.appointment.interval10Min': '10 minutos',
  'dropdown.time.appointment.interval15Min': '15 minutos',
  'dropdown.time.appointment.interval20Min': '20 minutos',
  'dropdown.time.appointment.interval30Min': '30 minutos',
  'dropdown.time.appointment.interval40Min': '40 minutos',
  'dropdown.time.appointment.interval45Min': '45 minutos',
  'dropdown.time.appointment.interval50Min': '50 minutos',
  'dropdown.time.appointment.interval60Min': '60 minutos',
  'dropdown.time.appointment.interval75Min': '75 minutos',
  'dropdown.time.appointment.interval90Min': '90 minutos',
  'dropdown.time.appointment.interval120Min': '120 minutos',
  'dropdown.time.appointment.interval150Min': '150 minutos',
  'dropdown.time.appointment.interval180Min': '180 minutos',
  'dropdown.time.appointment.interval240Min': '240 minutos',
  'dropdown.time.appointment.anyTime': 'En cualquier momento',
  'dropdown.time.appointment.disabled': 'Apagado',
  'dropdown.time.appointment.doesntRepeat': 'no se repite',
  'dropdown.time.appointment.daily': 'A diario',
  'dropdown.time.appointment.every2Days': 'Cada 2 dias',
  'dropdown.time.appointment.every3Days': 'Cada 3 dias',
  'dropdown.time.appointment.every4Days': 'Cada 4 dias',
  'dropdown.time.appointment.every5Days': 'Cada 5 dias',
  'dropdown.time.appointment.every6Days': 'Cada 6 días',
  'dropdown.time.appointment.every7Days': 'Cada 7 días',
  'dropdown.time.appointment.weekly': 'Semanalmente',
  'dropdown.time.appointment.every2weeks': 'Cada 2 semanas',
  'dropdown.time.appointment.every3weeks': 'Cada 3 semanas',
  'dropdown.time.appointment.every4weeks': 'Cada 4 semanas',
  'dropdown.time.appointment.every5weeks': 'Cada 5 semanas',
  'dropdown.time.appointment.every6weeks': 'Cada 6 semanas',
  'dropdown.time.appointment.every7weeks': 'Cada 7 semanas',
  'dropdown.time.appointment.every8weeks': 'Cada 8 semanas',
  'dropdown.time.appointment.every9weeks': 'Cada 9 semanas',
  'dropdown.time.appointment.every10weeks': 'Cada 10 semanas',
  'dropdown.time.appointment.monthly': 'Mensual',
  'dropdown.time.appointment.every2months': 'Cada 2 meses',
  'dropdown.time.appointment.every3months': 'Cada 3 meses',
  'dropdown.time.appointment.every4months': 'Cada 4 meses',
  'dropdown.time.appointment.every5months': 'Cada 5 meses',
  'dropdown.time.appointment.every6months': 'Cada 6 meses',
  'dropdown.time.appointment.every7months': 'Cada 7 meses',
  'dropdown.time.appointment.every8months': 'Cada 8 meses',
  'dropdown.time.appointment.every9months': 'Cada 9 meses',
  'dropdown.time.appointment.every10months': 'Cada 10 meses',
  'dropdown.time.appointment.every11months': 'Cada 11 meses',
  'dropdown.time.appointment.after2times': 'Después de 2 veces',
  'dropdown.time.appointment.after3times': 'Después de 3 veces',
  'dropdown.time.appointment.after4times': 'Después de 4 veces',
  'dropdown.time.appointment.after5times': 'Después de 5 veces',
  'dropdown.time.appointment.after6times': 'Después de 6 veces',
  'dropdown.time.appointment.after7times': 'Después de 7 veces',
  'dropdown.time.appointment.after8times': 'Después de 8 veces',
  'dropdown.time.appointment.after9times': 'Después de 9 veces',
  'dropdown.time.appointment.after10times': 'Después de 10 veces',
  'dropdown.time.appointment.after11times': 'Después de 11 veces',
  'dropdown.time.appointment.after12times': 'Después de 12 veces',
  'dropdown.time.appointment.after13times': 'Después de 13 veces',
  'dropdown.time.appointment.after14times': 'Después de 14 veces',
  'dropdown.time.appointment.after15times': 'Después de 15 veces',
  'dropdown.time.appointment.after20times': 'Después de 20 veces',
  'dropdown.time.appointment.after25times': 'Después de 25 veces',
  'dropdown.time.appointment.after30times': 'Después de 30 veces',
  'dropdown.time.appointment.specificDate': 'fecha especifica',
  'modal.createHours.title': 'Crear {0} horas',
  'modal.editHours.title': 'Editar {0} horas',
  'modal.deleteItem.confirm.0': '¿Seguro que desea permanentemente',
  'modal.deleteItem.confirm.1': 'Eliminar',
  'modal.deleteItem.confirm.2': 'de la base datos?',
  'modal.entity.category': 'Categoría',
  'modal.entity.referal': 'Fuente de referencia',
  'modal.entity.resource': 'Fuente',
  'modal.entity.location': 'Ubicación',
  'modal.deleteReminder.title': 'Eliminar el recordatorio',
  'modal.deleteMessage.title': 'Borrar Mensaje',
  'modal.deleteReminder.description': '¿Seguro que quieres eliminar este recordatorio ?',
  'modal.deleteMessage.description': '¿Está seguro de que desea eliminar este mensaje ?',
  'modal.deleteReminder.confirm': 'Sí, eliminar',
  'modal.deletePaymentMethod.title': 'Eliminar método de pago',
  'modal.deletePaymentMethod.description': 'Este método de pago se eliminará de forma permanente',
  'closedDate.create.modal.title': 'Añadir Fecha ',
  'closedDate.edit.modal.title': 'Editar Fecha de cierre',
  'staff.access.owner': 'Propietario',
  'staff.access.high': 'Alto',
  'staff.access.medium': 'Medio',
  'staff.access.low': 'Bajo',
  'staff.access.basic': 'Básico',
  'staff.access.no_access': 'Sin acceso',
  'staff.create.title': 'Añadir Fecha de Cierre',
  'staff.emptyState.title': 'No hay miembros del personal programados.',
  'staff.emptyState.description': 'Consulte la sección de miembros del personal para agregar horas de trabajo.\n',
  'staff.viewStaffMembers.button': 'Ver miembros del personal',
  'staff.picture.changePhoto': 'Cambiar foto',
  'staff.picture.removePhoto': 'Quitar foto',
  'input.clientSearch.placeholder': 'Buscar por nombre, e-mail o móvil',
  'input.blockedDate.label': 'Fecha',
  'appointment.notes.title': 'Notas de citas',
  'appointment.history.title': 'Historial de citas',
  'appointment.history.by.title': ' por {0}',
  'appointment.log.created.label': 'Creado por',
  'appointment.log.updated.label': 'Actualizado por',
  'appointment.log.rescheduled.label': 'reprogramada por',
  'appointment.log.cancelled.label': 'cancelado por',
  'appointment.log.changed.label': 'Estado cambiado por',
  'appointment.notificationLog.rescheduling': 'Notificación de reprogramación enviada el',
  'appointment.notificationLog.cancellation': 'Notificación de cancelación enviada el',
  'appointment.notificationLog.confirmation': 'Notificación de confirmación enviada el',
  'appointment.notificationLog.reminder': 'Notificación de recordatorio enviada el',
  'appointment.notificationLog.thankYou': 'Notificación de agradecimiento enviada el',
  'appointment.notificationLog.lastVisit': 'Notificación de última visita enviada el',
  'state.cancelReason.title': 'Configurar razones de cancelación',
  'state.cancelReason.description':
    'Las razones de cancelación ayudan a monitorear por qué los clientes no se presentaron a sus citas.',
  'state.cancelReason.button': 'Agregar razón de cancelación',
  'state.resources.title': 'Administrar sus recursos',
  'state.resources.description':
    'El negocio tiene más éxito cuando se tiene conocimiento de las herramientas disponibles. Realizar un seguimiento de habitaciones, muebles o cualquier otro electrodoméstico para agilizar el servicio y reducir obstáculos en el proceso.',
  'state.resources.button': 'Agregar recursos',
  'state.referralSource.title': 'Manejar las fuentes de referencia',
  'state.referralSource.description':
    'Una fuente de referencia indica el lugar de orígen de donde los clientes vienen. Realiza seguimiento de ellos y utiliza esta información para aumentar la visibilidad de tu negocio y la tasa de conversión.',
  'state.referralSource.button': 'Añadir fuente de referencia',
  'state.locations.title': 'Establecer ubicaciones',
  'state.locations.description':
    'Aquí se pueden administrar varios puntos de venta de tu negocio, establecer diferentes horarios de trabajo para tu personal por ubicación y tener calendarios personalizados para cada uno.',
  'state.staff.title': 'Administrar personal',
  'state.staff.description':
    'Crear perfiles para tus empleados para añadir y editar sus horarios, también para hacer el seguimiento de sus citas y horas de trabajo.',
  'state.messageLog.title': 'Realiza un seguimiento de los mensajes',
  'state.messageLog.description':
    'Los mensajes recuerdan a tus clientes de las próximas citas o los mantiene informados. Aquí, es posible realizar seguimiento de los mensajes y status de las citas. Para configurar mensajes automatizados, vaya a',
  'state.messageLog.link': 'Configuración de las notificaciones.',
  'state.clients.title': 'Administrar sus clientes',
  'state.clients.description':
    'El cuidado de sus clientes comienza con asegurarse de que sus perfiles contienen toda la información necesaria para contactarlos y tener una buena experiencia con la aplicación',
  'state.clients.button': 'agregar clientes',
  'state.services.title': 'Establecer grupos y servicios',
  'state.services.description':
    'Los grupos son esenciales, ya que permiten agrupar servicios similares que tu negocio ofrece. ¡Además, hacen un calendario mas ordenado y accesible!',
  'state.services.button': 'Agregar grupo de servicio',
  'state.calendar.title': 'Personal no programado',
  'state.calendar.description': 'Para establecer horas de trabajo, consulte la',
  'state.calendar.button': 'Sección del personal.',
  'state.closedDates.title': 'Fechas de cierre',
  'state.closedDates.description':
    'Enumerar las fechas de su negocio está cerrado para los días festivos, mantenimiento o cualquier otra razón. Los clientes no serán capaces de colocar las reservas en línea en estos días.',
  'state.closedDates.button': 'Añadir Fecha de Cierre',
  'title.billingPlan': 'Planes y facturación',
  'btn.upgrade': 'Mejorar',
  'header.usage': 'USO',
  'header.dropdown.subscription.plan': '{0} Plan',
  'header.dropdown.subscription.staff': '({0} Personal)',
  'header.dropdown.notifications.description': '{0} mensajes',
  'header.paymentDetails': 'Ver los detalles del pago',
  'title.billingPlan.description':
    'Administrar cuentas. Detalles de planes y facturación, visualizar transacciones y descarga de facturas',
  'plan.free.title': 'Inicio',
  'plan.individual.title': 'Individual (sólo 1 Personal)',
  'plan.business.title': 'Negocio',
  'btn.upgradeNow': 'Mejorar',
  'btn.getStarted': 'Comenzar',
  'btn.currentPlan': 'Plan actual',
  'plan.free.option.0': '1 Ubicación',
  'plan.free.option.1': 'Hasta 5 empleados',
  'plan.free.option.2': 'Citas Ilimitadas',
  'plan.free.option.3': 'Clientes ilimitados',
  'plan.free.option.4': 'Reservas en línea ilimitadas',
  'plan.free.option.5': 'Notificaciones por correo electrónico',
  'plan.premium.option.0': 'Ubicaciones ilimitadas',
  'plan.premium.option.1': 'Citas Ilimitadas',
  'plan.premium.option.2': 'Clientes ilimitados',
  'plan.premium.option.3': 'Reservas en línea ilimitadas',
  'plan.premium.option.4': 'Citas recurrentes',
  'plan.premium.option.5': 'Notificaciones por correo electrónico',
  'plan.premium.option.6': 'Notificaciones por SMS* ',
  'plan.premium.option.7': 'Notificaciones de Whatsapp*',
  'plan.premium.option.8': 'Análisis ilimitados',
  'plan.premium.option.9': 'Informes ilimitados',
  'plan.premium.option.10': 'Imprimir horarios',
  'plan.premium.option.11': 'Exportación de clientes',
  'plan.premium.option.12': 'Apoyo prioritario',
  'plan.premium.option.13':
    'Se eliminó la marca Plandok de los correos electrónicos (correos electrónicos gratuitos con tecnología de Plandok)',
  'plan.premium.costsInfo': '*Costos de SMS/WhatsApp no ​​incluidos en el plan, aplican cargos extra.',
  'plan.premium.activeUntilDate': 'Activo hasta {0}',
  'period.perMonth': 'por mes (sin IVA).',
  'plan.premium.description': 'El precio aumentará al añadir más miembros del personal.',
  'plan.premium.viewPricing': 'Ver precios',
  'plan.premium.numberStuff': 'Su número actual de personal: {0}',
  'premium.membership': 'El precio de la membresía premium puede variar según la cantidad de personal que tenga.',
  'premium.price.perMonth': 'Precio premium por mes',
  'pricing.calculated': 'El precio se calcula de la siguiente manera:',
  'pricing.calculator': 'Calculadora de precios',
  'pricing.numberStuff': 'Numero de empleado',
  'pricing.extra.member': '{0} por cada miembro adicional',
  'modal.title.pricing': 'Precios',
  'plan.foreverFree': 'Gratis para siempre',
  'plan.individual.0': 'Todo, desde Principiantes y más:',
  'plan.individual.1': 'Notificaciones personalizadas',
  'plan.individual.2': 'Quitar la marca Plandok',
  'plan.individual.option.3': 'Recordatorios SMS ilimitados',
  'plan.individual.4': 'Soporte técnico prioritario',
  'plan.business.0': 'Todo por individual, y además',
  'plan.business.1': 'Ubicaciones ilimitadas',
  'plan.business.2': 'Personal ilimitado',
  'block.billingInfo.title': 'Datos de facturación',
  'block.carInfo.title': 'Información de tarjeta de crédito',
  'input.cardNumber.label': 'Número de tarjeta',
  'input.cardNumber.placeholder': '1234 5678 3456 2456',
  'input.expireDate.label': 'Fecha de caducidad',
  'input.expireDate.placeholder': '05/21',
  'input.cvv.label': 'CVV',
  'input.cvv.placeholder': '123',
  'modal.confirmCancel.subscription': '¿Seguro que quieres cancelar la suscripción?',
  'modal.confirmCancel.subscription.description': 'Una vez cancelado la versión gratuita estará disponible.',
  'btn.cancelSubscription.confirm': 'Sí, cancelar mi suscripción',
  'loading.processingSubscribtion': 'Su pago está siendo procesado. Por favor espera...',
  'state.serviceGroup.description': 'No hay servicios en el grupo',
  'state.serviceGroup.btn': 'Agregar servicio',
  'appointment.status.new': 'Nueva cita',
  'appointment.status.confirmed': 'Confirmado',
  'appointment.status.notShow': 'Reservación sin asistencia',
  'appointment.status.completed': 'Completada',
  'appointment.status.cancelled': 'Cancelado',
  'billing.tab.plan': 'Tu plan',
  'billing.tab.costs': 'Costos estimados',
  'billing.tab.billing': 'Datos de facturación',
  'billing.tab.invoice': 'Facturas',
  'plan.free': 'Gratis',
  'plan.deactivated.title': 'Premium ha sido desactivado',
  'plan.active.title': 'Activar',
  'payment.step.title': 'Pagos',
  'payment.error.title': 'Actualización de pago',
  'payment.payNow.title': 'Pagar',
  'payment.dismiss.title': 'Despedir',
  'payment.tab.title.sub': 'Suscripción',
  'payment.tab.subTitle': 'Plan premium',
  'payment.per.members': 'por miembro',
  'payment.tab.title.notification': 'Notificaciones',
  'payment.card.details': 'Detalles de tarjeta',
  'payment.success.message': 'El pago se ha realizado correctamente',
  'payment.payWithCard.title': 'Pagar con tarjeta',
  'payment.cardNumber.title': 'Número de tarjeta',
  'payment.expirationDate.title': 'Fecha de caducidad',
  'payment.digits.title': '(3 dígitos)',
  'payment.amount': 'Monto del pago:',
  'payment.btn.pay': 'Pagar',
  'invoice.table.date': 'Fecha',
  'invoice.table.invoice': 'Factura',
  'invoice.table.download': 'Descargar',
  'invoice.table.status': 'Estado',
  'costs.billingPeriod.title': 'Costos estimados para este período de facturación',
  'costs.billingPeriod.description':
    'Este es un costo actual para su uso en este período de facturación. Un desglose de sus costos está disponible a continuación.',
  'costs.billingPeriod.additionalInfo':
    'Una vez que tus gastos de notificación alcancen los 50 €, se cargarán automáticamente. Pero no te preocupes si el importe es menor; se incluirá cómodamente en tu suscripción mensual.',
  'costs.summary.title': 'Resumen del mes hasta la fecha',
  'costs.summary.description': 'Estos cargos se tienen en cuenta en el saldo de su cuenta',
  'costs.summary.detailedCharges': 'Cargos detallados',
  'costs.summary.noData': 'Sin datos',
  'costs.summary.quantity': 'Cantidad',
  'costs.summary.subtotal': 'Total parcial',
  'costs.summary.taxes': 'Impuestos',
  'dropdown.time.hours': 'Horas',
  'message.planUpgrade': '¡Plan actualizado exitosamente!',
  'costs.subscription': 'Suscripción',
  'btn.cancelSubscription': 'Cancelar suscripción',
  'btn.no': 'No',
  'notification.success.update': 'Actualizado correctamente',
  'validation.password.match': 'Contraseña y confirmación de contraseña no coinciden',
  'blocked.time': 'Tiempo bloqueado',
  'message.successReset.password':
    '¡Hecho! El mensaje de restablecimiento de contraseña ha sido enviado, por favor revise su bandeja de entrada.',
  'title.newPassword': 'Ingrese nueva contraseña',
  'title.newPassword.description': 'Accede de forma segura a tu cuenta mediante la creación de una nueva contraseña',
  'input.newPasswordRepeat.label': 'Confirma tu nueva contraseña',
  'btn.change.password': 'Cambiar mi contraseña',
  'text.havingTrouble': '¿Tienes problemas?',
  'link.resetPassword.again': 'Restablecer su contraseña de nuevo',
  'message.successNew.password': 'Se ha cambiado correctamente la contraseña.',
  'title.repeatShifts': 'Turno Repetitivo',
  'text.confirmShift.update':
    'Ha editado un turno que se repite semanalmente. La eliminación de los próximos turnos sustituirán {0} horario {0} en curso.',
  'text.confirmShift.delete':
    'Estas eliminando un turno que se repite semanalmente. Al eliminar los próximos turnos sustituirá {0} horario {0} en curso.',
  'btn.deleteUpcoming.shifts': 'Eliminar próximos turnos',
  'btn.deleteThis.shift': 'Eliminar solo este turno',
  'btn.updateUpcoming.shifts': 'Actualizar próximos turnos',
  'btn.updateThis.shift': 'Actualizar solo este turno',
  'text.permisionTab':
    'Configuración de las secciones que son accesibles a cada nivel de permiso del usuario. Todo personal conectado puede acceder a la agenda y las cuentas de propietario tienen acceso total al sistema.',
  'title.permission.locations': 'Ubicaciones',
  'title.permission.booking': 'Reservaciones y Clientes',
  'title.permission.staff': 'Personal',
  'title.permission.setup': 'Configurar',
  'title.permission.basic': 'Básico',
  'title.permission.low': 'Bajo',
  'title.permission.med': 'Medio',
  'title.permission.high': 'Alto',
  'title.permission.owner': 'Propietario',
  'title.allLocations.permission': 'Acceso a todas las ubicaciones',
  'title.accessCalendar.permission': 'Acceso al calendario personal',
  'title.accessOtherCalendar.permission': 'Acceso a otros calendarios del personal',
  'title.canBook.permission': 'Se pueden reservar citas',
  'title.contactInfo.permission': 'Se puede ver información de contacto del cliente',
  'title.clients.permission': 'Clientes',
  'title.messages.permission': 'Mensajes',
  'title.services.permission': 'Servicios',
  'title.workingHours.permission': 'Horas Laborales',
  'title.staffMembers.permission': 'Miembros del personal',
  'title.permissionLevels.permission': 'Niveles de permiso',
  'title.accountSetup.permission': 'Configuraciones de la cuenta',
  'title.clientSettings.permission': 'Configuración del cliente',
  'title.onlineBookings.permission': 'Reservas en línea',
  'title.analytics.permission': 'Analítica',
  'title.marketing.permission': 'Marketing',
  'notification.type.reminder': 'Recordatorio',
  'notification.type.confirmation': 'Confirmación',
  'notification.type.online_booking_confirmation': 'Confirmación de reserva en línea',
  'notification.type.rescheduling': 'Reprogramar',
  'notification.type.cancellation': 'Cancelación',
  'notification.type.thank_you': 'Gracias',
  'notification.type.last_visit': 'Última visita',
  'notification.channel.sms': 'SMS',
  'notification.channel.email': 'Correo electrónico',
  'notification.channel.whatsapp': 'WhatsApp',
  'notification.status.sent': 'Enviado',
  'notification.status.pending': 'Pendiente',
  'notification.status.failed': 'Falló ',
  'dropdown.time.days': 'dias',
  'title.appointment': 'cita',
  'input.reminderBeforeLastVisit.label': 'Una notificación será enviada después del número de días indicado',
  'txt.with': 'con',
  'short.hours': 'hrs',
  'short.minutes': 'min',
  'input.registrationNumber.label': 'Numero de la compañia',
  'input.vatNumber.label': 'Numero fiscal de la compañía',
  'invoice.table.priceTaxIncl': 'Cantidad',
  'error.wrongSenderId':
    'ID de remitente debe incluir dígitos o caracteres, la longitud debe ser de más de 3 y menos de 11 símbolos',
  'sidebar.help': 'Ayuda',
  'modal.help.title': '¿Cómo podemos ayudarle?',
  'modal.help.liveChat.title': 'Chat en directo',
  'modal.help.liveChat.description': '¿Tiene alguna duda? Pregúntenos ahora mismo',
  'modal.help.suggestFeature.title': 'Sugerir una función',
  'modal.help.suggestFeature.description':
    'Comparta sus sugerencias, vea las de los demás y vote por las funciones que desee',
  'modal.help.viewUpdates.title': 'Ver actualizaciones',
  'modal.help.viewUpdates.description': 'Vea nuestras últimas actualizaciones y mejoras',
  'warning.title.paidFeature': 'Caracteristicas de pago',
  'warning.smsFeatureMissing':
    'Para poder acceder a los ajustes de SMS su plan debe ser actualizado. Para actualizar la visita',
  'warning.link.billingAndPricing': 'Plan y facturación',
  'warning.newPage.0': 'se ha actualizado!',
  'warning.newPage.1': 'Si utilizó la versión antigua de Plandok, la contraseña no funcionará, por favor',
  'warning.newPage.2': 'restablezca la contraseña',
  'warning.newPage.3': 'disfruta el nuevo sistema. Para más información, contáctanos ',
  'warning.newPage.4': 'o',
  'title.blockTime.select': 'Seleccione hora para la Cita',
  'calendar.allResources': 'Todos los recursos',
  'appointment.edit.title': 'Editar cita',
  'appointment.create.title': 'Cita creada con éxito.',
  'appointment.update.title': 'Cita actualizada con éxito.',
  'select.notify.title': '{0} no funciona en el intervalo seleccionado, pero aún puedes reservar citas.',
  'booking.notify.title': 'El tiempo seleccionado está reservado, pero puede crear una cita.',
  'textArea.characters.left': 'carácteres restantes',
  'textArea.characters.exceeded': 'el límite está excedido por',
  'validation.characters.exceeded': 'límite de carácteres está excedido',
  'staff.menu.members': 'Miembros del Personal',
  'staff.menu.dates': 'Fecha cerrada',
  'staff.menu.working': 'Horas laborales ',
  'staff.menu.permissions': 'Permisos de Usuario',
  'staff.menu.members.description': 'Añadir o eliminar miembros del equipo y controlar su nivel de acceso de usuario',
  'staff.menu.dates.description': 'Programar próximas cerradas del negocio o feriados públicos',
  'staff.menu.working.description': 'Administrar disponibilidad de calendario al ajustar los horarios del staff',
  'staff.menu.permissions.description':
    'Configurar permiso de acceso para las características específicas e información',
  'message.successUpdateLocation': '¡Listo! Perfil de ubicación actualizado correctamente.',
  'ob.title': 'Reservación en Línea',
  'ob.overview.tab': 'Generalidades',
  'ob.massMessages.tab': 'Mensajes masivos',
  'ob.campaigns.tab': 'Campañas',
  'ob.profile.tab': 'Perfil en línea',
  'ob.links.tab': 'Enlaces',
  'ob.settings.tab': 'Ajustes',
  'ob.payments.tab': 'Pagos',
  'ob.status.online': 'En línea',
  'ob.status.offline': 'Fuera de línea',
  'ob.status.title': 'Estado',
  'ob.profile.step': '{0} de 4',
  'ob.profile.step.title': 'Perfil y ubicación',
  'ob.profile.step.description':
    'Presenta tu negocio y presenta los servicios que ofreces a tus clientes. La parte "Acerca de" es una de las primeras secciones que tus clientes mirarán y, si esa información no es suficiente, asegúrate de dejar un número de teléfono donde los clientes puedan contactarte para obtener más información. Introduce la dirección donde prestas tus servicios, para que tus clientes puedan estar informados y ver la dirección.',
  'ob.overview.placheholder.title': 'Permite a tus Clientes que Hagan Reservaciones en Línea!',
  'ob.overview.placheholder.description':
    'Cree un perfil de reservas en línea y permite que tus clientes reserven citas en línea. Simplemente inserta un enlace a tu página web o redes sociales y listo.',
  'ob.overview.start.now': 'Comienza Ahora',
  'ob.links.direct.title': 'Enlace Directo',
  'ob.links.facebook.title': 'Enlace de Facebook ',
  'ob.links.instagram.title': 'Enlace de Instagram ',
  'ob.links.direct.description':
    'Enlace URL Enlace a tu perfil de reservación en línea, puedes usarlo en tu sitio web o cuentas de redes sociales. Si no estás seguro de cómo usar el enlace, contáctanos.',
  'ob.links.facebook.description':
    'Comparte este enlace en Facebook para atraer a más clientes y para que te encuentren más fácilmente y reserven una cita.',
  'ob.links.instagram.description':
    '¡Hazlo más fácil para ti y enlaza tu reserva online directamente!\r\nPuedes utilizarla en tu página web y en cualquier otro lugar donde te guste. Si no estás seguro de cómo usar el enlace, no te preocupes, ¡nosotros te ayudamos!',
  'ob.analytics.tab.description':
    'Añada Google Analytics y el píxel de Facebook a su página de reservas en línea para analizar el tráfico y el comportamiento de los usuarios.',
  'ob.analytics.google.title': 'Google Analytics',
  'ob.analytics.facebook.title': 'Píxel de Facebook',
  'ob.analytics.google.description':
    'Con Google Analytics, puede rastrear y analizar el tráfico del sitio web y el comportamiento de los usuarios para obtener información sobre cómo mejorar su sitio web.',
  'ob.analytics.active': 'Activo',
  'ob.analytics.inactive': 'Inactivo',
  'ob.analytics.setUp.btn': 'Configurar',
  'ob.analytics.google.description.1':
    'Google Analytics es una potente herramienta que puede ayudarle a controlar el tráfico del sitio web y el comportamiento de los usuarios. Con Google Analytics, puede realizar un seguimiento del número de visitantes de su sitio web, de dónde vienen, qué páginas visitan y cuánto tiempo permanecen en su sitio.',
  'ob.analytics.google.description.2':
    'Además, Google Analytics le permite establecer objetivos y realizar un seguimiento de las conversaciones, lo que le permite comprender mejor el rendimiento de su sitio web y cómo puede mejorarlo.',
  'ob.analytics.google.trackingId': 'ID de seguimiento',
  'ob.analytics.google.tooltip':
    'Para encontrar su ID de seguimiento, primero debe acceder a su cuenta de Google Analytics, seleccionar el sitio web o la aplicación de la que desea realizar un seguimiento y, a continuación, ir a la pestaña "Admin". A continuación, haga clic en "Código de seguimiento". Su ID de seguimiento aparecerá en el formato "UA-XXXX-Y".',
  'ob.analytics.google.account': '¿Aún no tiene una cuenta de Google Analytics?',
  'ob.analytics.click': 'Haga clic en',
  'ob.analytics.here': 'aquí',
  'ob.analytics.learn': 'para saber cómo crear una.',
  'ob.analytics.facebook.description.1':
    'El píxel de Facebook es una herramienta que te permite realizar un seguimiento del comportamiento de los usuarios en tu sitio web, incluidas las conversiones, las páginas vistas y otros eventos.',
  'ob.analytics.facebook.description.2':
    'El píxel de Facebook puede proporcionarle información valiosa sobre su audiencia, como datos demográficos, intereses, tipo de dispositivo, páginas vistas y acciones realizadas, lo que puede ayudarle a comprender mejor y optimizar la experiencia del usuario en su sitio web.',
  'ob.analytics.facebook.pixelId': 'ID del píxel',
  'ob.analytics.facebook.tooltip':
    'Para encontrar tu ID de píxel de Facebook, sigue estos pasos: Accede a tu cuenta de Facebook Ads Manager. Haz clic en el botón "Menú" situado en la esquina superior izquierda de la pantalla. En la sección "Administrador de eventos", selecciona "Píxeles". Aquí verás una lista de todos los píxeles asociados a tu cuenta. Haga clic en el nombre del píxel para el que necesita el ID. Tu ID de píxel aparecerá en la esquina superior izquierda de la pantalla, junto al nombre del píxel.',
  'ob.analytics.facebook.account': '¿Aún no tienes una página de empresa en Facebook?',
  'ob.analytics.google.account.mobile.1': '¿Aún no tienes una cuenta de Google Analytics\n',
  'ob.analytics.google.account.mobile.2': 'de Google Analytics? Haz clic en',
  'ob.analytics.google.account.mobile.3': 'para saber cómo',
  'ob.analytics.google.account.mobile.4': 'crear una.',
  'ob.analytics.facebook.account.mobile.1': '¿Aún no tienes una página',
  'ob.analytics.facebook.account.mobile.2': 'de Facebook? Haz clic en',
  'ob.analytics.facebook.account.mobile.3': 'para saber cómo',
  'ob.analytics.facebook.account.mobile.4': 'crear una.',
  'ob.analytics.successfullySaved.message': 'Guardado correctamente',
  'ob.settings.booking.title': 'Disponibilidad de reservación en línea',
  'ob.settings.booking.description':
    'Establece la hora en que las citas más tempranas y más tardes pueden programarse. Administra los intervalos de tiempo de las citas y ofrece información importante a los clientes, además de la opción de elegir a miembros del personal para su servicio.\n',
  'ob.settings.cancellation.title': 'Cancelación y Reprogramación ',
  'ob.settings.cancellation.description': 'Establece el tiempo que los clientes pueden cancelar o reprogramar su cita.',
  'ob.settings.locationsUrl.title': 'URL de Ubicaciones',
  'ob.settings.locationsUrl.description':
    'Configura y comparte una URL única para todas las ubicaciones reservables en línea. Esto es útil para las empresas con varias ubicaciones solamente.',
  'ob.settings.businessName.explanation': 'Tu URL: book.plandok.com/business-name/Location2556',
  'ob.settings.notifications.title': 'Correos electrónicos de notificación',
  'ob.settings.notifications.description':
    'Especifica el destinatario de las notificaciones por correo electrónico relacionadas con las nuevas citas o los cambios que se le hayan realizado.',
  'ob.settings.required.title': 'Campos obligatorios y opcionales',
  'ob.settings.required.description':
    'Seleccione qué campos el cliente deberá proporcionar al realizar reservas en línea.',
  'ob.settings.requiredField.title': 'Requerido',
  'ob.settings.optionalField.title': 'Opcional',
  'ob.settings.requiredField.notification.title': 'Ha habido un problema con la configuración',
  'ob.settings.requiredField.notification.description':
    'Lo sentimos, pero un campo debe ser obligatorio, excepto "notas".',
  'ob.settings.nameField.title': 'Nombre',
  'ob.settings.lastNameField.title': 'Apellido',
  'ob.settings.phoneField.title': 'Número de teléfono',
  'ob.settings.emailField.title': 'Correo electrónico',
  'ob.settings.notes.label': 'Texto para las notas',
  'ob.settings.notes.placeholder': 'Por ejemplo: Escriba sus notas',
  'ob.bookingLimit.title': 'Límite de reservas en línea',
  'ob.bookingLimit.description': 'Especifique el número máximo de reservas de servicios que desea establecer.',
  'ob.bookingLimit.label': 'Limitar las reservas de servicios',
  'ob.bookingLimit.unlimited.title': 'Ilimitado',
  'ob.bookingLimit.1service.title': 'máx. 1 Servicio',
  'ob.bookingLimit.2services.title': 'máx. 2 Servicios',
  'ob.bookingLimit.3services.title': 'máx. 3 Servicios',
  'ob.menu.edit.profile': 'Editar Perfil',
  'ob.menu.turnOff.profile': 'Desactivar Perfil',
  'ob.profile.status': 'Estado',
  'ob.overview.tab.description': 'Haz crecer tu negocio al permitirles a tus clientes que reserven citas en línea.',
  'ob.profile.tab.description':
    'Administra perfiles en línea de tus localidades. Ajusta las imágenes, las descripciones, las direcciones y mucho más.',
  'ob.links.tab.description': 'Aquí encontrarás un enlace de reservas en línea, que puedes compartir con tus clientes.',
  'ob.settings.tab.description':
    'Ajusta aquí la configuración de las reservas en línea, para que tus clientes puedan reservar sólo cuando te convenga.',
  'ob.payments.tab.description':
    'Seleccione los métodos de pago que sus clientes podrán utilizar al pagar los servicios.',
  'ob.payments.page.description':
    'Aquí puede seleccionar los métodos de pago que sus clientes podrán utilizar para pagar los servicios.',
  'ob.payOnSite.title': 'Pago in situ',
  'ob.payOnSite.description': 'Sus clientes podrán pagar in situ en efectivo.',
  'ob.payWithCard.title': 'Pago con tarjeta',
  'ob.payWithCard.description': 'Sus clientes podrán pagar con tarjeta de crédito a través de la aplicación Stripe.',
  'ob.payWithCard.info.1': 'Usted está utilizando el plan gratuito Plandok por lo que tendrá que pagar',
  'ob.payWithCard.info.2': 'comisión de Plandok y comisión de Stripe utilizando este método de pago.',
  'ob.payWithCard.info.3': ' tener',
  'ob.payWithCard.info.4': 'comisiones de Plandok.',
  'ob.payWithCard.info.5': 'El plan Premium no exime de pagar las comisiones de Stripe. ',
  'ob.payWithCard.info.6': 'Compruebe los precios de Stripe aquí',
  'ob.stripeConnected.info': '(Stripe conectado)',
  'ob.requiresSetup.info': '(Requiere configurar su cuenta de Stripe)',
  'ob.connectToStripe.btn': 'Conectarse a Stripe',
  'ob.connectToStripe.successMessage.title': 'Conectado con éxito a Stripe',
  'ob.waitingAuthorization.title': 'Esperando su autorización en Stripe',
  'ob.stripeDashboard.title': 'Todos los registros de pagos y reembolsos están disponibles en ',
  'ob.stripeDashboard.url': 'Panel de Stripe',
  'ob.yourStripeAccount.title': 'Su cuenta de Stripe',
  'ob.visitStripe.option': 'Visitar Stripe',
  'ob.changeAccount.option': 'Cambiar cuenta de Stripe',
  'input.earliestAppointment.label': 'Citas más anteriores',
  'input.earliestAppointment.placeholder': 'Justo antes de la cita',
  'input.latestAppointment.label': 'Citas más recientes',
  'input.latestAppointment.placeholder': 'No más de 6 meses por adelantado',
  'input.timeSlots.label': 'Intervalos de tiempo',
  'input.timeSlots.placeholder': '15 minutos',
  'checkbox.allowCustomers': 'Todos los clientes que eligen miembros del staff para sus citas',
  'input.importantInfo.label': 'Información importante',
  'input.importantInfo.placeholder': 'Información que se mostrará al cliente mientras confirma su cita',
  'input.changeAppointment.label': 'Cambios a las citas pueden hacerse',
  'input.changeAppointment.placeholder': 'en cualquier momento',
  'checkbox.sendToBooked': 'Enviar al miembro del personal reservado',
  'checkbox.sendSpecificEmail': 'Enviar a una dirección de correo electrónico específica',
  'input.specificEmail.label': 'Dirección de correo electrónico específica',
  'input.specificEmail.placeholder': 'Introduce el correo electrónico',
  'input.locationsUrl.label': 'Ubicaciones URL',
  'input.locationsUrl.placeholder': 'E.j. Johns-Barber-Shop',
  'ob.btn.save': 'Guarar',
  'ob.btn.cancel': 'Cancelar ',
  'ob.btn.showLink': 'Mostrar enlace',
  'ob.btn.active': 'Activo',
  'ob.btn.inactive': 'Inactivo',
  'ob.linkModal.title': 'Enlace directo para reservaciones',
  'ob.linkModal.description':
    'Aquí está el enlace donde tus  servicios de reservaciónn en línea pueden ser alcanzados directamente!',
  'ob.linkModal.afterDescription':
    'Este enlace dirige a los usuarios directamente a tus servicios. Con un solo clic, los clientes pueden llegar a los perfiles de tu empresa y hacer reservaciones de citas.Este enlace es fácil de usar y se adapta a todos los dispositivos, ya sea un ordenador, un teléfono o una tablet.',
  'ob.fbLinkModal.title': 'Página de Facebook para Reservar Ahora',
  'ob.fbLinkModal.description':
    'Configura un botón de Reservar Ahora para tu página de negocios de Facebook para recibir fácilmente nuevas reservaciones directamente desde Facebook.\n',
  'ob.fbLinkModal.step1': 'Inicia sesión en tu página de empresas de Facebook',
  'ob.fbLinkModal.step2': 'Da clic en "Agregar un botón" ubicado cerca de la foto de portada',
  'ob.fbLinkModal.step3': 'Selecciona la opción "Registrarse" e introduce el siguiente enlace de reservación',
  'ob.linkModalCopySuccess.title': 'Link copied!',
  'ob.btn.copyLink': 'Copiar enlace',
  'title.appointmentSlot.select': 'Seleccione hora para la Cita',
  'btn.back': 'Regresar a',
  'btn.continue': 'Continuar',
  'ob.hours.open': 'Abrir',
  'ob.hours.close': 'Cerrado',
  'ob.hours.step.title': 'Horas de trabajo',
  'ob.hours.step.description': 'Haga saber a tus clientes cuándo abre y cierra tu negocio.',
  'ob.staffHours.step.title': 'Horas de trabajo del personal',
  'ob.staffHours.step.description':
    'Un paso importante, agregue las horas de trabajo para que el sistema sepa cuándo los clientes pueden reservar en línea.',
  'ob.location.step.title': 'Información de la ubicación',
  'ob.photo.step.title': 'Fotos',
  'ob.photo.step.description': 'Hazle saber a tus clientes cómo se ve tu local comercial.',
  'input.file.dnd.photos': 'Arrastra y suelta fotos',
  'btn.publish': 'Encender',
  'text.next': 'Siguiente',
  'input.phone.number.label': 'Número de teléfono',
  'input.state.label': 'Estado',
  'input.state.placeholder': 'Introduce un estado (municipalidad)',
  'input.addressDetails.label': 'Detalles de dirección',
  'input.addressDetails.placeholder':
    'Introduce los detalles de la dirección, como el código de la puerta, otras entradas, información sobre el estacionamiento, etc.',
  'placeholder.no.photos': 'Sin fotos',
  'btn.options': 'Opciones',
  'btn.list.profile': 'Permitir',
  'btn.preview': 'Previa',
  'btn.next': 'Siguiente',
  'market.ob.pleaseWait.title': 'Por favor, espere ...',
  'market.ob.location.step.title': 'Seleccionar ubicación',
  'market.ob.services.step.title': 'Selecciona los servicios',
  'market.ob.employee.step.title': 'Seleccionar fecha y personal',
  'market.ob.timeSlot.step.title': 'Valige kuupäev ja kellaaeg',
  'market.ob.personalInfo.step.title': 'Pago',
  'market.ob.appointmentInfo.step.title': 'Información de la cita',
  'market.ob.informationAboutYou.title': 'Información sobre usted',
  'market.ob.paymentMethod.title': 'Forma de pago',
  'market.ob.securePayment.title': 'Pago seguro',
  'market.ob.payWithCard.title': 'Pago con tarjeta',
  'market.ob.payOnSite.title': 'Pago in situ',
  'market.ob.payOnSite.firstDescription': 'Deberá pagar en el salón después del procedimiento.',
  'market.ob.payOnSite.secondDescription':
    'Una pequeña solicitud de nosotros: las empresas a menudo son pequeñas empresas independientes, por lo que si no puede llegar a su cita programada, reprogramarlo o cancelarla.',
  'market.ob.validThru.title': 'Válido hasta (Mes/año)',
  'market.ob.information.title': 'Información',
  'market.ob.additionalInformation.title': 'Información adicional',
  'market.ob.reschedulingPolicy.title': 'Política de cancelación/reprogramación',
  'market.ob.reschedulingPolicy.description': 'Los cambios en las citas se pueden hacer {0} antes de la cita.',
  'market.ob.fieldsAreRequired.title': '* Campos obligatorios',
  'market.ob.step.title': 'Paso {0} de 3',
  'market.ob.confirm.step.title': 'Ver y confirmar',
  'market.ob.noServices.placeholder': 'Todavía no ha seleccionado ningún servicio',
  'market.ob.input.firstName.label': '{0} Nombre',
  'market.ob.input.firstName.placeholder': 'e.g. John',
  'market.ob.input.lastName.label': '{0} Apellido',
  'market.ob.input.lastName.placeholder': 'e.g. Doe',
  'market.ob.input.phone.label': '{0} Número de móvil',
  'market.ob.input.phone.placeholder': '670 70 822',
  'market.ob.input.email.label': '{0} Dirección de correo electrónico',
  'market.ob.input.email.placeholder': 'john.doe@gmail.com',
  'market.ob.input.description.label': 'Notas',
  'market.ob.staff.assignedSpecialist.label': 'con el staff',
  'market.ob.staff.noPreference.label': 'Sin preferencias',
  'market.ob.timeSlots.fullyBooked.title': 'Lo sentimos, no tenemos horarios disponibles para este día.',
  'market.ob.timeSlots.choseAnotherDay.title': 'Por favor, elija otro día.',
  'market.ob.timeSlots.closedSlot.title': 'La hora más cercana disponible es',
  'market.ob.timeSlots.selectClosest.title': 'Selecciona la fecha más próxima',
  'market.ob.timeSlots.note.title': 'Información importante',
  'market.ob.service.duration.title': 'minutos',
  'market.location.workingHours.title': 'Horas laborales',
  'market.location.monday.title': 'Lunes',
  'market.location.tuesday.title': 'Martes',
  'market.location.wednesday.title': 'Miércoles',
  'market.location.thursday.title': 'Jueves',
  'market.location.friday.title': 'Viernes',
  'market.location.saturday.title': 'Sábado',
  'market.location.sunday.title': 'Domingo',
  'market.location.meetOurTeam.title': 'Conoce a Nuestro Equipo',
  'market.location.title': 'Contacto',
  'market.location.services.title': 'Servicios',
  'market.location.about.title': 'Acerca de',
  'market.location.aboutUs.title': 'Quiénes somos',
  'market.confirmation.appointmentCanceled.title': 'Esta cita ha sido cancelada',
  'market.confirmation.appointmentConfirmed.title': 'Nombramiento confirmado',
  'market.confirmation.appointmentConfirmed.description': 'Su cita está confirmada, aquí están los detalles:',
  'market.confirmation.appointmentDate.title': 'Fecha de la cita',
  'market.confirmation.scheduledAppointment.title': 'La cancelación no es posible en línea.',
  'market.confirmation.scheduledAppointment.description':
    'Si no puede acudir a la cita programada, le rogamos que la cambie o la anule.',
  'market.modal.cancelAppointment.title': '¿Está seguro de que desea anular esta cita ?',
  'market.modal.cancelledAppointment.title': 'Cita anulada',
  'market.language.changer.title': 'Idioma (Language)',
  'market.btn.bookNow': 'Reservar',
  'market.servicesAvailable.title': 'servicios',
  'market.poweredByPlandok.title': 'Plandok',
  'market.poweredByPlandok.description': '¿Quieres un sitio web similar ? Visite',
  'market.btn.readMore': 'Leer más',
  'market.btn.showLess': 'Mostrar menos',
  'market.btn.reviews': 'Comentarios',
  'market.btn.viewMore': 'Ver más',
  'market.btn.cancelAppointment': 'Anular cita',
  'market.btn.cancel': 'Sí, anular',
  'market.wh.closed.title': 'Cerrado',
  'market.ob.success.message': '¡Hecho! La reservación ha sido creada con éxito',
  'market.ob.cancelAppointment.success.message': 'Cita cancelada exitosamente',
  'market.ob.cancelAppointment.error.message': 'Falló la cancelación de la cita',
  'market.ob.cancelAppointment.confirm.title': '¿Está seguro de que quieres cancelar la cita?',
  'market.location.getDirections.title': 'Obtener direcciones',
  'market.notFound.title': 'Página no encontrada',
  'market.notFound.btn.goBack': 'Volver atrás',
  'market.ob.quantitativeLimit.title': 'Límite cuantitativo',
  'market.ob.quantitativeLimit.description':
    'Lo sentimos, el propietario ha impuesto un límite en el número de servicios de reserva, lo que permite una selección de sólo {0} servici{0}',
  'market.ob.quantitativeLimit.three': 'tres',
  'market.ob.quantitativeLimit.two': 'dos',
  'market.ob.quantitativeLimit.one': 'uno',
  'market.ob.quantitativeLimit.serviceSingular': 'o',
  'market.ob.quantitativeLimit.servicesPlural': 'os',
  'validation.characters.minLength': 'Este valor es demasiado corto. Debería tener 3 caracteres o más.',
  'not.found.btn.label': 'Vuelve a la portada',
  'not.found.subTitle.label': 'Lo sentimos, la página que visitó no existe.',
  'analytics.title': 'Analítica',
  'analytics.dashboard.tab': 'Tablero',
  'analytics.reports.tab': 'Informes',
  'analytics.premium.title': 'Prima',
  'analytics.totalAppointments.title': 'Citas totales',
  'analytics.cancellationReasons.title': 'Top cancelar razones',
  'analytics.occupancy.title': 'Ocupación',
  'analytics.topServices.title': 'Top servicios',
  'analytics.clients.title': 'Clientela',
  'analytics.completed.title': 'Terminado',
  'analytics.notCompleted.title': 'Sin completar',
  'analytics.canceled.title': 'Cancelado',
  'analytics.noShowAppointments.title': 'Si no se presenta citas',
  'analytics.reasonName.title': 'nombre de la razón',
  'analytics.reasonCount.title': 'Contar',
  'analytics.lastPeriod.title': 'Último periodo',
  'analytics.workingHours.title': 'Horas Laborales',
  'analytics.bookedHours.title': 'Horas Reservados',
  'analytics.blockedHours.title': 'Horas bloqueados',
  'analytics.unbookedHours.title': 'Horas no reservadas',
  'analytics.service.title': 'Servicios',
  'analytics.thisMonth.title': 'Este periodo',
  'analytics.lastMonth.title': 'Último periodo',
  'analytics.totalClients.title': 'El total de clientes',
  'analytics.averageVisits.title': 'Visitas promedio por cliente',
  'analytics.newClients.title': 'Los nuevos clientes',
  'analytics.returningClients.title': 'volviendo clientes',
  'analytics.returnRate.title': 'volviendo clientes',
  'analytics.clientsByGender.title': 'Clientes por género',
  'analytics.clientsBySource.title': 'Clientes por Fuente de referencia',
  'analytics.clientsByAge.title': 'Clientes por edad',
  'analytics.timeRange.custom': 'Personalizado',
  'analytics.timeRange.today': 'Hoy',
  'analytics.timeRange.yesterday': 'Ayer',
  'analytics.timeRange.last1day': 'Última 1 día',
  'analytics.timeRange.last7days': 'Los últimos 7 días',
  'analytics.timeRange.last30days': 'Últimos 30 días',
  'analytics.timeRange.last90days': 'últimos 90 días',
  'analytics.timeRange.last3months': 'Últimos 3 meses',
  'analytics.timeRange.lastMonth': 'El mes pasado',
  'analytics.timeRange.last6Months': 'Últimos 6 meses',
  'analytics.timeRange.lastYear': 'Último año 1',
  'analytics.timeRange.last2year': 'Última 2 años',
  'analytics.timeRange.thisWeek': 'Esta semana',
  'analytics.timeRange.thisMonth': 'este mes',
  'analytics.timeRange.thisYear': 'este año',
  'analytics.timeRange.tomorrow': 'Mañana',
  'analytics.timeRange.next7days': 'Próximos 7 días',
  'analytics.timeRange.next30days': 'Próximos 30 días',
  'analytics.menu.dashboard.description': 'Explorar datos de su empresa en los gráficos y estadísticas',
  'analytics.menu.reports.description': 'Explorar datos de su empresa en los informes',
  'analytics.timeRange.allTime': 'Todo el tiempo',
  'analytics.timeRange.label': 'Intervalo de tiempo',
  'analytics.services.lastPeriod.label': 'Último periodo',
  'analytics.totalAppointments.appointment.label': 'Reservas',
  'analytics.totalAppointments.onlineBook.label': 'Reservas online',
  'analytics.gender.male': 'De hombres',
  'analytics.gender.female': 'De mujeres',
  'analytics.gender.unknown': 'Ignoto',
  'analytics.noData': 'No hay datos en el período seleccionado',
  'analytics.premium.info.title': 'Actualizar a la versión premium para ver la analítica durante más de 7 días.',
  'analytics.premium.upgradeButton.title': 'ACTIVAR',
  'analytics.premium.skipButton.title': 'Saltar',
  'reports.appointments.title': 'Equipo',
  'reports.appointments.description':
    'Vea los ingresos proyectados de las próximas citas, realice un seguimiento de las tasas de cancelación y los motivos.',
  'reports.appointmentsList.title': 'lista de citas',
  'reports.appointmentsList.description': 'Muestra todas las citas con información relacionada.',
  'reports.appointmentsSummary.title': 'Resumen de citas',
  'reports.appointmentsSummary.description': 'Muestra citas por personal y servicio.',
  'reports.appointmentsCancellations.title': 'Cancelaciones de citas',
  'reports.appointmentCancellations.title': 'Cancelaciones de citas',
  'reports.appointmentsCancellations.description':
    'Muestra todos los motivos de las citas canceladas y la cantidad por el período seleccionado.',
  'reports.summary.title': 'Resumen',
  'reports.clients.title': 'Clientela',
  'reports.clients.description':
    'Obtenga información sobre cómo los clientes interactúan con su empresa y quiénes son sus mayores gastadores.',
  'reports.clientsList.title': 'Clientela',
  'reports.clientsList.description': 'Listado de Clientes con sus datos',
  'reports.exportCsv.title': 'Exportar CSV',
  'reports.appointmentsByStaff.title': 'Nombramientos por parte del personal',
  'reports.appointmentsByService.title': 'Citas por Servicio',
  'reports.clearFilters.button.title': 'Limpiar filtros',
  'reports.filterStaff.placeholder': 'Todo el personal',
  'reports.filterLocations.placeholder': 'Todas las ubicaciones',
  'reports.filters.title': 'Filtros',
  'reports.locations.mobile.title': 'Ubicaciones:',
  'reports.staff.mobile.title': 'Personal:',
  'reports.filters.apply.title': 'Aplicar',
  'reports.search.placeholder': 'Buscar por referencia o cliente',
  'reports.subscription.description': '{0} plan ({0} sólo personal)',
  'reports.notifications.description': '{0} mensajes ({0})',
  'serviceGroup.addCategory.description':
    'Las categorías son esenciales, ya que te permiten agrupar servicios similares.',
  'serviceGroup.addCategory.title': 'Agregar categoría',
  'app.install.title': 'Instalar Plandok',
  'app.install.description.title':
    'La instalación casi no utiliza almacenamiento y proporciona una manera rápida de regresar a esta aplicación.',
  'app.install.button.title': 'Instalar',
  'app.skip.button.title': 'Saltar',
  'app.actionUndone.button.title': 'Acción anulada',
  'app.appointmentCancelled.button.title': 'Cita suprimida',
  'app.undo.button.title': 'Deshacer',
  'app.showAllTimes.button.title': 'Mostrar todos los horarios',
  'app.install.success.title': '¡La aplicación se instaló correctamente!',
  'rp.upgradeToPremiumLink.title': 'Actualizar a Premium',
  'rp.upgradeToPremium.title': 'para usar esta característica',
  'rp.repeatAppointment.title': 'Repetir',
  'rp.input.frequency.title': 'Frecuencia',
  'rp.input.endsAfter.title': 'Final',
  'rp.input.selectDate.title': 'Seleccionar fecha',
  'rp.upgrade.title': 'Actualiza tu plan',
  'rp.upgrade.description.title':
    'La versión premium de Plandok no está activada. Actualice su plan para acceder completamente a todas las funciones proporcionadas por Plandok.',
  'rp.upgrade.button.title': 'Actualizar a premium',
  'rp.repeating.title': 'repitiendo',
  'rp.repeats.title': 'Se repite',
  'rp.repeatsUntil.title': 'hasta',
  'rp.repeatingTimes.title': '({0} veces)',
  'rp.cancel.upcoming.title': 'Eliminar todas las próximas citas repetidas',
  'rp.update.upcoming.title': 'Actualizar todas las próximas citas repetidas',
  'rp.edit.appointment.title': 'Actualizar cita',
  'rp.edit.appointment.description.title': 'Estás editando una cita repetida. Seleccione qué citas deben actualizarse:',
  'rp.edit.thisAppointment.title': 'Actualizar solo esta cita',
  'rp.edit.allAppointments.title': 'Actualizar todas las próximas citas',
  'rp.edit.saveAppointment.title': 'Ahorrar',
  'modal.cancelSubscriptionError.title': 'Error en la cancelación de la suscripción',
  'modal.cancelSubscriptionError.text.1': 'El plan gratuito sólo está disponible con un máximo de 5',
  'modal.cancelSubscriptionError.text.2': 'empleados',
  'modal.cancelSubscriptionError.text.3': 'y 1',
  'modal.cancelSubscriptionError.text.4':
    'Para utilizar la versión gratuita, elimine las ubicaciones / el personal para cumplir con los requisitos.',
  'modal.latestPaymentFailed.title': 'Último pago fallido',
  'modal.latestPaymentFailed.text.1':
    'Para tener acceso a todas las funciones, por favor, pague o utilice la versión gratuita para eliminar',
  'modal.latestPaymentFailed.text.2':
    'para cumplir con los requisitos. El plan gratuito sólo está disponible con un máximo de 5 empleados y 1 ubicación.',
  'modal.extraCostsForNewMember.title': 'Costes adicionales para los nuevos miembros',
  'modal.extraCostsForNewMember.description':
    'Al aumentar el número de miembros del personal habrá costes adicionales en su suscripción mensual.',
  'modal.premiumUpgradeRequired.title': 'Se requiere una actualización Premium',
  'modal.premiumUpgradeRequired.description': 'Para añadir {0} adicional su plan debe ser actualizado a Premium',
  'modal.premiumUpgradeRequired.location': 'una ubicación',
  'modal.premiumUpgradeRequired.staffMembers': 'miembros del personal',
  'modal.changeAccount.title': '¿Cambiar de cuenta Stripe?',
  'modal.changeAccount.description':
    'No podrás aceptar pagos mientras cambias de cuenta. Tardarás aproximadamente un minuto en hacer el cambio.',
  'btn.agree': 'Aceptar',
  'btn.decline': 'Rechazar',
  'paymentMethod.paymentMethod.title': 'Forma de pago',
  'paymentMethod.currentPaymentMethod.title': 'Forma de pago actual',
  'paymentMethod.expirationDate': 'Fecha de caducidad {0}',
  'paymentMethod.otherPaymentMethods.title': 'Otras formas de pago',
  'paymentMethod.addAnotherPaymentMethod.btn': 'Añadir otra forma de pago',
  'paymentMethod.addPaymentMethod.title': 'Añadir forma de pago',
  'paymentMethod.noPaymentMethodAdded.title': 'No se ha añadido ningún método de pago',
  'paymentMethod.noPaymentMethodAdded.description': 'La forma de pago se añadirá al seleccionar el plan Premium',
  'paymentMethod.goToPlanPage.btn': 'Ir a la página del plan',
  'paymentMethod.add.btn': 'Añadir',
  'paymentMethod.makeDefault.btn': 'Por defecto',
  'paymentMethod.cardHasBeenAdded.message': 'Se ha añadido la tarjeta',
  'paymentMethod.cardHasBeenAssign.message': 'Tarjeta asignada',
  'page.marketing.overview.messages.description':
    'Envíe mensajes de marketing personalizados a sus clientes por SMS o correo electrónico con unos simples clics',
  'page.marketing.overview.btn.sendMessage': 'Envío de mensajes',
  'page.marketing.overview.campaigns.title': 'Campañas de autoenvío',
  'page.marketing.overview.campaigns.description':
    'Cree campañas personalizadas basadas en eventos como los cumpleaños de los clientes y otros. \nEnvíe a través de múltiples canales para entregar campañas por correo electrónico y mensajes de texto',
  'page.marketing.overview.btn.newCampaign': 'Nueva campaña',
  'page.marketing.massMessages.title': 'Configurar un nuevo mensaje',
  'page.marketing.massMessages.messageLog': 'Registro de mensajes',
  'page.marketing.massMessages.sendOn': 'Enviar en',
  'page.marketing.massMessages.sendTo': 'Enviar a',
  'page.marketing.massMessages.channel': 'Canal',
  'page.marketing.campaigns.description':
    'Envíe mensajes de marketing automatizados a sus clientes a través de SMS o correo electrónico con unos simples clics',
  'page.marketing.campaigns.birthdaySpecial.title': 'Especial de cumpleaños',
  'page.marketing.campaigns.winBackClients.title': 'Recuperar clientes',
  'page.marketing.campaigns.welcomeNewClients.title': 'Dar la bienvenida a nuevos clientes',
  'page.marketing.campaigns.rewardRegularClients.title': 'Recompensar a los clientes habituales',
  'page.marketing.campaigns.birthdaySpecial.description':
    'Sorprenda a los clientes en su día especial, esta campaña se envía automáticamente a los clientes cerca de su cumpleaños',
  'page.marketing.campaigns.winBackClients.description':
    'Diríjase a los clientes desvinculados para que vuelvan con una oferta especial. Esta campaña se envía a los clientes que no han regresado después de un determinado período de tiempo',
  'page.marketing.campaigns.welcomeNewClients.description':
    'Convierta a los recién llegados en asiduos animando a los clientes que han reservado por primera vez a que vuelvan a hacerlo con un descuento especial. Esta campaña se envía automáticamente a los clientes un día después de su primera venta',
  'page.marketing.campaigns.rewardRegularClients.description':
    'Sorprenda a los mejores clientes con una oferta especial, una forma segura de conseguir que sus mejores clientes se comprometan aún más. Esta campaña se envía automáticamente a los clientes en función de la actividad de las citas recientes',
  'page.marketing.campaigns.btn.active': 'Activo',
  'page.marketing.campaigns.btn.inactive': 'Inactivo',
  'page.marketing.campaigns.btn.startCampaign': 'Iniciar campaña',
  'page.marketing.campaigns.btn.editCampaign': 'Editar campaña',
  'page.marketing.campaigns.btn.resetCampaign': 'Restablecer campaña',
  'modal.resetCampaign.description': '¿Está seguro de que desea restablecer esta campaña ?',
  'modal.resetCampaign.btn.yesReset': 'Sí, restablecer',
  'page.marketing.smsCreate.step.1': 'Configuración de SMS. Paso 1 de 4',
  'page.marketing.smsCreate.step.2': 'Configuración de SMS. Paso 2 de 4',
  'page.marketing.smsCreate.step.3': 'Configuración de SMS. Paso 3 de 4',
  'page.marketing.smsCreate.step.4': 'Configuración de SMS. Paso 4 de 4',
  'page.marketing.emailCreate.step.1': 'Configuración del correo electrónico. Paso 1 de 4',
  'page.marketing.emailCreate.step.2': 'Configuración del correo electrónico. Paso 2 de 4',
  'page.marketing.emailCreate.step.3': 'Configuración del correo electrónico. Paso 3 de 4',
  'page.marketing.emailCreate.step.4': 'Configuración del correo electrónico. Paso 4 de 4',
  'page.marketing.campaignCreate.step.1': 'Configuración de la campaña. Paso 1 de 3',
  'page.marketing.campaignCreate.step.2': 'Configuración de la campaña. Paso 2 de 3',
  'page.marketing.campaignCreate.step.3': 'Configuración de la campaña. Paso 3 de 3',
  'page.marketing.smsCreate.step.1.title': 'Mensaje e identificación del remitente',
  'page.marketing.smsCreate.step.1.defaultTitleForSms': 'Escriba el ID del remitente del SMS',
  'page.marketing.smsCreate.step.1.defaultMessageForSms': 'Escriba su mensaje',
  'page.marketing.emailCreate.step.1.defaultTitleForEmail': 'Escriba el título del mensaje',
  'page.marketing.emailCreate.step.1.defaultMessageForSms': 'Escriba su mensaje',
  'page.marketing.emailCreate.step.1.title': 'Configuración del correo electrónico',
  'page.marketing.emailCreate.step.1.messageTitle': 'Título del mensaje',
  'page.marketing.emailCreate.step.1.replyToEmail': 'Responder al correo electrónico',
  'page.marketing.emailCreate.step.1.clientReplies': 'Las respuestas del cliente se enviarán a este correo electrónico',
  'page.marketing.emailCreate.step.1.photo.title': 'Foto',
  'page.marketing.emailCreate.step.1.enablePhoto': 'Habilitar foto',
  'page.marketing.emailCreate.step.1.changePhoto': 'Cambiar foto',
  'page.marketing.emailCreate.step.1.button.title': 'Botón',
  'page.marketing.emailCreate.step.1.enableButton': 'Habilitar botón',
  'page.marketing.emailCreate.step.1.buttonName': 'Nombre del botón',
  'page.marketing.emailCreate.step.1.buttonURL': 'URL del botón',
  'page.marketing.emailCreate.step.1.socialMedia.title': 'Medios sociales',
  'page.marketing.emailCreate.step.1.enableSocialMedia': 'Habilitar los enlaces a las redes sociales',
  'page.marketing.emailCreate.step.1.facebookPage': 'Página de Facebook',
  'page.marketing.emailCreate.step.1.instagramPage': 'Página de Instagram',
  'page.marketing.emailCreate.step.1.yourPage': 'Tu página',
  'page.marketing.smsCreate.yourMessage': 'Tu mensaje',
  'page.marketing.btn.sendMeTestMessage': 'Envíame un mensaje de prueba',
  'page.marketing.smsCreate.step.1.freeTests': 'Quedan {0} pruebas gratuitas para hoy',
  'page.marketing.smsCreate.messagePreview': 'Vista previa del mensaje',
  'page.marketing.smsCreate.btn.clientSelection': 'Selección de clientes',
  'page.marketing.audience.title': 'Audiencia',
  'page.marketing.smsCreate.step.2.description': 'Elija qué clientes recibirán su mensaje',
  'page.marketing.smsCreate.step.2.allClient.title': 'Todos los clientes',
  'page.marketing.smsCreate.step.2.allClient.description': 'Envíe el mensaje a todos sus clientes',
  'page.marketing.smsCreate.step.2.clientGroups.title': 'Grupos de clientes',
  'page.marketing.smsCreate.step.2.clientGroups.description': 'Envíe el mensaje a los grupos de clientes seleccionados',
  'page.marketing.smsCreate.step.2.totalClients': 'Total de clientes',
  'page.marketing.smsCreate.step.2.totalPrice': 'Precio total',
  'page.marketing.smsCreate.step.3.description': 'Elige la hora a la que quieres que se envíe tu mensaje',
  'page.marketing.smsCreate.step.3.btn.selectDateTime': 'Seleccione fecha y hora personalizada',
  'page.marketing.smsCreate.step.4.title': 'Pago del mensaje masivo',
  'page.marketing.smsCreate.step.4.description': 'Escriba los datos de su tarjeta de crédito y pague',
  'page.marketing.smsCreate.step.4.paymentAmount': 'Importe del pago:',
  'btn.scheduleSend': 'Programar el envío',
  'btn.previous': 'Anteriormente',
  'btn.payment': 'Pago',
  'btn.payAndFinish': 'Pagar y terminar',
  'btn.backToDashboard': 'Volver al panel de control',
  'btn.saveAndClose': 'Guardar y cerrar',
  'modal.editClientSelection.title': 'Editar la selección de clientes',
  'modal.newClient.description': 'Clientes añadidos en los últimos:',
  'modal.recentClient.title': 'Cliente reciente',
  'modal.recentClient.description': 'Clientes visitados en la última:',
  'modal.clientByAge.title': 'Clientes por edad',
  'modal.clientsByGender.title': 'Clientes por sexo',
  'modal.paymentConfirmation.title': '¡Pago realizado con éxito !',
  'modal.paymentConfirmation.messageSend': 'Su mensaje será enviado',
  'modal.previewMessage.title': 'Vista previa del mensaje',
  'modal.previewSms.tab': 'Vista previa del SMS',
  'modal.previewEmail.tab': 'Vista previa del correo electrónico',
  'modal.editServiceSelection.title': 'Editar selección de servicios',
  'modal.editServiceSelection.allServices': 'Todos los servicios',
  'modal.campaignActivated.title': '¡Campaña activada !',
  'modal.campaignActivated.description': 'Puede pausar o editar esta campaña en cualquier momento',
  'btn.all': 'Todo {0}',
  'btn.included': 'Incluido {0}',
  'btn.excluded': 'Excluidos {0}',
  'checkbox.allClients': 'Todos los clientes {0}',
  'scheduleSend.time': 'tiempo',
  'scheduleSend.timeSetTo': 'Hora fijada:',
  'scheduleSend.setTime': 'Hora fijada',
  'campaignSetup.step.1.header.title': 'Configuración de la campaña',
  'campaignSetup.step.2.header.title': 'Editar mensaje',
  'campaignSetup.step.3.header.title': 'Revisar',
  'campaignSetup.channel.description': 'Elija los canales por los que se enviará la campaña',
  'campaignSetup.smsSetup.tab': 'Configuración de SMS',
  'campaignSetup.emailSetup.tab': 'Configuración del correo electrónico',
  'campaignSetup.almostDone.title': '¡Casi listo !',
  'campaignSetup.almostDone.description':
    'Su campaña inteligente está lista para funcionar, una vez activada enviará automáticamente mensajes a los clientes de forma continua. Puede modificar o pausar fácilmente esta campaña en cualquier momento',
  'campaignSetup.approximatePrice.header': 'Precio aproximado',
  'campaignSetup.approximatePrice.description': 'Para los próximos 30 días',
  'campaignSetup.approximatePrice.approx': 'Aproximadamente',
  'campaign.birthdaySpecial.description': 'Esta campaña se envía automáticamente a los clientes cerca de su cumpleaños',
  'campaign.birthdaySpecial.sendToClient': 'Enviar al cliente:',
  'campaign.birthdaySpecial.selectOption.1': 'El día del cumpleaños',
  'campaign.birthdaySpecial.selectOption.2': 'Tres días antes del cumpleaños',
  'campaign.birthdaySpecial.selectOption.3': 'Una semana antes del cumpleaños',
  'campaign.birthdaySpecial.selectOption.4': 'Dos semanas antes del cumpleaños',
  'campaign.winBackClients.description':
    'Esta campaña se envía a los clientes que no regresaron después de un determinado período de tiempo',
  'campaign.winBackClients.clientsWhoDidntReturn': 'Clientes que no volvieron en el último',
  'campaign.winBackClients.appliedTo': 'Aplicado a',
  'campaign.winBackClients.all': ' todos ',
  'campaign.winBackClients.services': 'los servicios',
  'campaign.winBackClients.editSelection': 'Editar selección',
  'campaign.welcomeNewClients.description':
    'Convierta a los recién llegados en asiduos animando a los primeros clientes a volver a reservar con un descuento especial',
  'campaign.welcomeNewClients.smartCampaignNewClients':
    'Esta campaña inteligente se envía automáticamente a los nuevos clientes un día después de su primera venta',
  'campaign.rewardRegularClients.description':
    'Esta campaña se envía automáticamente a los clientes en función de la actividad de las citas recientes',
  'campaign.rewardRegularClients.clientsWithAtLeast': 'Clientes con al menos',
  'campaign.rewardRegularClients.appointmentsInThe': 'citas en el',
  'campaign.rewardRegularClients.last': 'último',
  'campaign.rewardRegularClients.appointments': 'citas',
  'campaign.rewardRegularClients.inTheLast': 'en el último',
  'campaign.rewardRegularClients.months': 'meses',
  'subscriptionPaymentFailed.title':
    'Actualice su método de pago hasta {0}, de lo contrario, todas las características premium se suspenderán, gracias.',
  'subscriptionCancelled.title':
    'Nos disculpamos pero tu Premium ha sido desactivado por falta de pago. Si desea continuar usando las funciones premium, active el Plan Premium, gracias.',
  'notificationPaymentFailed.title':
    'Su pago de notificaciones ha fallado. Complete el pago hasta el {0}; de lo contrario, las notificaciones se desactivarán, gracias.',
  'notificationCancelled.title':
    'Te pedimos disculpas pero tus notificaciones han sido desactivadas por falta de pago. Si desea continuar enviando notificaciones por favor realice el pago, gracias.',
  'btn.hideOptions': 'Ocultar opciones',
  'btn.showOptions': 'Mostrar opciones',
  'notification.googleDisconnected': 'Google Calendar desconectado',
  'notification.googleConnected': 'Google Calendar conectado',
  'notification.appleDisconnected': 'Calendario de Apple desconectado',
  'notification.appleConnected': 'Calendario de Apple conectado',
  'notification.microsoft365Disconnected': 'Calendario Microsoft365 desconectado',
  'notification.microsoft365Connected': 'Microsoft365 Calendar conectado',
  'notification.linkCopied': 'Enlace copiado',
  'calendarSync.title': 'Sincronización de calendarios',
  'calendarSync.description':
    'Sincronice de una sola forma las citas de Plandok con su calendario favorito para organizarlas sin esfuerzo y no perderse nunca ningún evento importante.',
  'googleCalendar.title': 'Calendario de Google',
  'appleCalendar.title': 'Calendario Apple',
  'microsoft365Calendar.title': 'Calendario Microsoft365',
  'linkYourCalendar.title': 'Vincule sus calendarios',
  'connect.btn': 'Conectar',
  'disconnect.rtn': 'Desconectar',
  'connected.label': 'Conectado',
  'modal.workingHours.standartMethod.btn': 'Método estándar',
  'modal.workingHours.customMethod.btn': 'Método personalizado',
  'modal.workingHours.workingDates.input': 'Fechas de trabajo',
  'modal.workingHours.selectDates.placeholder': 'Seleccionar fechas',
  'noInternetConnection.notification.title': 'Sin conexión a Internet',
  'app.update.title': 'Nueva versión disponible',
  'app.update.description.title':
    'Hemos añadido nuevas funciones y corregido algunos errores para que su experiencia con Plandok sea lo más fluida posible.',
  'app.update.button.title': 'Actualizar',
  'app.notNow.button.title': 'Ahora no',
  'app.updateAvailable.button.title': 'Nueva versión disponible',
  'upgradePlan.success.message': 'La suscripción se ha actualizado correctamente',
  'upgradePlan.error.message':
    'Error de actualización de la suscripción, póngase en contacto con el servicio de asistencia si se realiza el cargo en su tarjeta y no se actualiza la suscripción',
  'sidebar.inventory': 'Inventario',
  'products.tab': 'Productos',
  'suppliers.tab': 'Proveedores',
  'stockOrders.tab': 'Pedidos de existencias',
  'stockTracking.tab': 'Seguimiento de existencias',
  'input.productsSearch.placeholder': 'Búsqueda por nombre de producto o código de barras',
  'product.create.btn.title': 'Añadir un nuevo producto',
  'table.products.product': 'Producto',
  'table.products.barcode': 'Código de barras',
  'table.products.brand': 'Marca',
  'table.products.sale': 'Venta',
  'table.products.saleOpt': 'Opc. de venta',
  'saleOptions.tooltip.title': 'Opciones de venta',
  'saleOptions.tooltip.description': 'Puede elegir activar/desactivar la venta durante el pago.',
  'seeMore.title': 'ver más',
  'option.products.downloadCSV': 'Descargar CSV',
  'option.products.downloadExcel': 'Descargar Excel',
  'option.products.manageBrands': 'Gestionar marcas',
  'option.products.manageCategories': 'Gestionar categorías',
  'option.products.manageStock': 'Gestionar Stock',
  'option.products.importProducts': 'Importar Productos',
  'modal.filter.categories.title': 'Categorías',
  'modal.filter.brands.title': 'Marcas',
  'modal.filter.stock.title': 'Stock',
  'modal.filter.allCategories.selectItem': 'Todas las categorías',
  'modal.filter.allBrands.selectItem': 'Todas las marcas',
  'modal.filter.allSuppliers.selectItem': 'Todos los proveedores',
  'modal.filter.allProducts.selectItem': 'Todos los productos',
  'input.suppliersSearch.placeholder': 'Buscar por nombre de proveedor',
  'supplier.create.btn.title': 'Añadir nuevo proveedor',
  'table.suppliers.supplierName': 'Nombre del proveedor',
  'table.suppliers.phoneNumber': 'Número de teléfono',
  'table.suppliers.physicalAddress': 'Dirección física',
  'validation.notification.reminderTime': 'No se pueden enviar notificaciones con la misma hora de recordatorio.',
  'validation.paymentType.switches': 'Se debe permitir al menos un método de pago.',
  'plan.premium.option.14':
    '0% de comisión de Plandok para citas reservadas a través de Reservas en línea con tarjeta de crédito.',
  'title.dragAndExtend.appointment.permission': 'Puede arrastrar y extender citas',
  'button.lastVisit.addAnotherLastvisit': 'AÑADIR OTRA ÚLTIMA VISITA',
  'notifications.template.badge.validate': 'Validar',
  'notifications.template.badge.refused': 'Rechazado',
  'paid.by.card': 'Pagado con tarjeta',
  'costs.manual.paid.link': 'O puedes pagar tus notificaciones manualmente con tu tarjeta, pinchando en este enlace',
  'modal.remove.account.title': 'Eliminar cuenta de Stripe',
  'modal.remove.account.description': 'Su cuenta será eliminada y no podrá aceptar pagos en línea. ',
  'ob.remove.account.option': 'Eliminar cuenta de Stripe',
  'btn.remove.account': 'Eliminar',
  'calendar.tooltip.selected': 'Seleccionado',
  'calendar.tooltip.no.preference': 'Sin preferencia',
  'ob.gtag.manager.title': 'Administrador de etiquetas de Google',
  'ob.analytics.gtag.description.1':
    'El administrador de etiquetas de Google le permite medir el ROI de su publicidad, así como realizar un seguimiento de sus sitios y aplicaciones Flash, de vídeo y de redes sociales.',
  'ob.gtag.manager.description.1':
    'Google Tag Manager es un sistema de administración de etiquetas gratuito que le permite administrar e implementar etiquetas de marketing (fragmentos de código o píxeles de seguimiento) en su sitio web o aplicación móvil sin tener que modificar el código directamente.',
  'ob.gtag.manager.description.2':
    'En esencia, GTM agiliza el proceso de gestión de etiquetas de marketing, facilitando el seguimiento del rendimiento del sitio web, la medición de campañas de marketing y la optimización de las experiencias de los usuarios.',
  'text.gender.male': 'Masculino',
  'text.gender.female': 'Femenino',
  'text.gender.unknown': 'Desconocido',
};

export default labels;
