import { useEffect, useState } from 'react';
import { message } from 'antd';
import * as api from 'api';

export default function useGetCurrentPlan() {
  const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(false);
  const [planType, setPlanType] = useState('free');
  const [employeeCount, setEmployeeCount] = useState(1);

  useEffect(() => {
    setIsSubscriptionLoading(true);

    (async () => {
      try {
        const { data } = await api.fetchSubscriptionInfo();

        setPlanType(data?.type);
        setEmployeeCount(data?.employeeCount);
      } catch (error) {
        message.error(`Error: ${error}. Failed to get tariff plan.`);
      } finally {
        setIsSubscriptionLoading(false);
      }
    })();
  }, []);

  return {
    employeeCount,
    isSubscriptionLoading,
    planType,
  };
}
