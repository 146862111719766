import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { getIsMobile, useHistory, useModal } from '@plandok/core';
import { RoutePath } from 'constants/routes';
import { BookingAnalyticCard } from './components/BookingAnalyticCard';
import getAnalyticsServices from './helpers';
import facebook from './icons/facebook.svg';
import google from './icons/google.svg';
import gtag from './icons/gtag.svg';
import * as SC from './styles';

export type AnalyticsServices = {
  gtagTrackerId: string | null;
  googleTrackerId: string | null;
  facebookTrackerId: string | null;
};

const analyticRoute: any = {
  GOOGLE_TAG: RoutePath.GTAG_MANAGER,
  FACEBOOK_PIXEL: RoutePath.FACEBOOK_PIXEL,
  GOOGLE_ANALYTICS: RoutePath.GOOGLE_ANALYTICS,
};

export default function BookingAnalytics() {
  const [analyticsServices, setAnalyticsServices] = useState<AnalyticsServices>();
  const [isLoading, setIsLoading] = useState(false);
  const [, { showModal }] = useModal();
  const isMobile = getIsMobile();
  const history = useHistory();

  useEffect(() => {
    getAnalyticsServices(setIsLoading, setAnalyticsServices);
  }, []);

  const viewAnalytics = (type: string) => () =>
    isMobile
      ? history.push(analyticRoute[type], analyticsServices)
      : showModal({
          type: `VIEW_${type}_MODAL`,
          modalProps: { analyticsServices, onSuccess: () => getAnalyticsServices(setIsLoading, setAnalyticsServices) },
        });

  const viewGoogleAnalytics = viewAnalytics('GOOGLE_ANALYTICS');
  const viewFacebookPixel = viewAnalytics('FACEBOOK_PIXEL');
  const viewGoogleTag = viewAnalytics('GOOGLE_TAG');

  return (
    <Spin spinning={isLoading}>
      <SC.Container>
        <BookingAnalyticCard
          title="ob.analytics.google.title"
          description="ob.analytics.google.description"
          icon={google}
          link={analyticsServices?.googleTrackerId}
          onClick={viewGoogleAnalytics}
        />
        <BookingAnalyticCard
          title="ob.analytics.facebook.title"
          description="ob.analytics.facebook.description.1"
          icon={facebook}
          link={analyticsServices?.facebookTrackerId}
          onClick={viewFacebookPixel}
        />
        <BookingAnalyticCard
          title="ob.gtag.manager.title"
          description="ob.analytics.gtag.description.1"
          icon={gtag}
          link={analyticsServices?.gtagTrackerId}
          onClick={viewGoogleTag}
        />
      </SC.Container>
    </Spin>
  );
}
