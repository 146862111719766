import styled, { css } from 'styled-components';
import theme from './theme';

type CalendarBodyProps = {
  isFirefox: boolean;
};

type WorkingTimeProps = {
  top: number;
  height: number;
};

type NowLineProps = {
  length: number;
  top: number;
  leftOffset: number;
};

type HeaderColumnProps = {
  isMobile: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  position: relative;
  box-sizing: border-box;
  font-size: 14px;
  font-family: Work Sans, sans-serif;
  * {
    box-sizing: border-box;
  }
`;

export const Content = styled.div`
  flex: 1 1 auto;
  align-items: stretch;
  display: flex;
  overflow: auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
`;

export const TimeColumn = styled.div`
  background: darkslategray;
  min-width: ${theme.timeColumnWidth}px;
  height: ${(p) => p.theme.columnHeight}px;
  position: sticky;
  left: 0;
  z-index: ${theme.zIndexTimeColumn};
`;

// scroll-snap-type and scroll-padding-left not supported by firefox
// https://gitlab.com/plandok/plandok-app/-/issues/460
export const CalendarBody = styled.div<CalendarBodyProps>`
  flex: 1 1;
  display: flex;
  flex-direction: row;
  ${({ isFirefox }) => (!isFirefox ? 'scroll-snap-type: x mandatory;' : '')};
  ${({ isFirefox }) => (!isFirefox ? `scroll-padding-left: ${theme.timeColumnWidth}px;` : '')};
  overflow: auto;

  &::-webkit-scrollbar-track {
    background: #f8f9fc;
  }

  &::-webkit-scrollbar {
    border-radius: 10px;
    height: ${theme.scrollbarWidth}px;
    width: ${theme.scrollbarWidth}px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d8d8d1;
    border-radius: 10px;
    &:hover {
      background: #babab4;
    }
  }
`;

export const TimeCell = styled.div`
  height: ${(p) => p.theme.hourHeight}px;
  min-height: ${(p) => p.theme.hourHeight}px;
  width: 100%;
  position: relative;
  font-size: 14px;
  color: #425989;
  text-align: center;
  font-weight: 500;
  border-right: 1px solid #dde2ec;
  background: #f8f9fc;
  user-select: none;
`;

export const CalendarCell = styled.div`
  height: ${(p) => p.theme.hourHeight}px;
  min-height: ${(p) => p.theme.hourHeight}px;
  border-right: 2px solid #d1d5e0;
  border-bottom: 2px solid #c2c8d8;
  width: 100%;
  position: relative;
  background: transparent;
  display: flex;
  flex-direction: column;
`;

export const CalendarCellTime = styled.div`
  flex: 1 1;
  background: transparent;
  border-bottom: 1px solid #d1d5e0;
  user-select: none;

  display: flex;
  align-items: center;
  padding-left: 5px;

  span {
    color: rgb(19, 49, 109);
    display: none;
  }
  &:hover {
    background: #eaecf4;
    span {
      display: inline;
    }
  }
`;

export const WorkingTime = styled.div<WorkingTimeProps>`
  position: absolute;
  background: white;
  top: ${({ top }) => top}px;
  height: ${({ height }) => height}px;
  min-width: ${(p) => p.theme.columnWidth}px;
  max-width: ${(p) => p.theme.columnWidth}px;
`;

export const CalendarColumn = styled.div`
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: ${(p) => p.theme.columnWidth}px;
  max-width: ${(p) => p.theme.columnWidth}px;
  width: ${(p) => p.theme.columnWidth}px;
  height: ${(p) => p.theme.columnHeight}px;
  background: repeating-linear-gradient(-52deg, #eaecf4, #eaecf4 10px, #d9dbe5 10px, #d9dbe5 20px);

  &[data-column-active] {
    background: #13316c0d;
    ${WorkingTime} {
      background: #13316c0d;
    }
  }
`;

export const ColumnHeader = styled.div<HeaderColumnProps>`
  background: #f8f9fc;
  position: sticky;
  left: 0;
  top: 0;
  padding-top: ${({ isMobile }) => (isMobile ? '10px' : '0')};
  min-height: ${({ isMobile }) => (isMobile ? '60px' : '50px')};
  z-index: ${theme.zIndexColumnHeader};
  border-bottom: 1px solid #dde2ec;
`;

export const TimeCellHeader = styled.div`
  background: #f8f9fc;
  left: 0;
  top: 0;
  z-index: ${theme.zIndexTimeCellHeader};
  min-height: ${theme.calendarHeaderHeight}px;
`;

export const NowLine = styled.div<NowLineProps>`
  position: absolute;
  background: #ff5e5e;
  height: 2px;
  top: ${({ top }) => top}px;
  width: ${(p) => p.theme.columnWidth * p.length}px;
  left: ${(p) => p.leftOffset * p.theme.columnWidth + theme.timeColumnWidth}px;
  z-index: ${theme.zIndexNowLine};
`;

export const NowTime = styled.div`
  position: absolute;
  top: -8px;
  left: -46px;
  background: white;
  border: 1px solid #ff5e5e;
  color: #ff5e5e;
  padding: 0 4px;
  width: 47px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  user-select: none;
`;

export const NowIndicator = styled.div`
  background: #ff5e5e;
  width: 10px;
  height: 10px;
  left: -10px;
  top: -4px;
  border-radius: 10px;
  position: absolute;
`;

export const ResizeToggle = styled.button`
  opacity: 0;
  position: absolute;
  margin: 0 auto;
  bottom: -6px;
  background: white;
  border-radius: 5px;
  height: 11px;
  width: 35px;
  left: 0;
  right: 0;
  flex-direction: column;
  border: none;
  justify-content: space-between;
  text-align: center;
  padding: 0 10px;
  box-shadow: 0 3px 6px rgba(173, 182, 204, 0.35);
  outline: none !important;
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};

  &:hover,
  &:active,
  &:focus {
    opacity: 1 !important;
    cursor: grabbing !important;
  }
  div {
    margin: auto;
    border-top: 1px solid #5fb0ff;
    border-bottom: 1px solid #5fb0ff;
    width: 13px;
    min-height: 5px;
  }
`;

export const CardContent = styled.div<any>`
  flex: 1 1;
  background: ${({ bgColor = 'royalblue' }) => bgColor};
  border: 1px solid #eef2f7;
  color: black;
  max-width: 100%;
  border-radius: 2px;
  padding: 4px 4px 4px 8px;
  transition: all 0.3s ease-in;
  display: flex;
  overflow: hidden;
`;

export const MobileCardOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: transparent;
`;

export const Card = styled.div<any>`
  position: absolute;
  min-width: ${theme.cardMinWidth}px;
  padding-right: 3px;
  z-index: ${({ isDragging }) => (isDragging ? 1000 : theme.zIndexCard)};
  user-select: none;
  transition: margin-left 0.3s;
  margin-left: ${({ columnDelta }) => columnDelta * 100}%;
  display: flex;
  max-width: ${(p) => p.style?.maxWidth};
  opacity: 1;
  color: black;

  &:hover {
    z-index: ${theme.zIndexCard + 1};
    ${ResizeToggle} {
      opacity: 1;
    }
  }
  ${({ isDragging }) =>
    isDragging &&
    css`
      left: 0 !important;
      width: 100% !important;
      z-index: ${theme.zIndexCardDrag} !important;
      ${CardContent} {
        transition: none;
      }
    `}
  ${({ isResizing }) =>
    isResizing &&
    css`
      ${CardContent} {
        transition: none;
      }
    `}
`;

export const TimeCardContent = styled.div`
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  max-height: 40px;
  line-height: 1.2;
  font-size: 12px;
  position: relative;
  width: 100%;

  &:hover {
    ${ResizeToggle} {
      opacity: 1;
    }
  }
`;

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 20px;
  width: 230px;
  font-size: 14px;
`;

export const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
